export const Action_Pedidos_Types ={
    GESTOR_PEDIDOS:"GESTOR_PEDIDOS",
    LOJA_STATUS:"LOJA_STATUS",
    GESTOR_EXECUTANDO:"GESTOR_EXECUTANDO"
}

export const save_pedidos= (pedidos) => ({
    type: Action_Pedidos_Types.GESTOR_PEDIDOS,
    pedidos
})

export const save_status= (status) => ({
    type: Action_Pedidos_Types.LOJA_STATUS,
    status
})

export const save_executando= (executando) => ({
    type: Action_Pedidos_Types.GESTOR_EXECUTANDO,
    executando
})
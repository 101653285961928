export const Action_Admin_Types ={
    SAVE_ADMIN:"SAVE_ADMIN",
    SAVE_CATEGORIA:"SAVE_CATEGORIA",
    SAVE_PRODUTO:"SAVE_PRODUTO"
}

export const save_admin = (admin) => ({
    type: Action_Admin_Types.SAVE_ADMIN,
    admin
})

export const save_categoria = (categoria) => ({
    type: Action_Admin_Types.SAVE_CATEGORIA,
    categoria
})

export const save_produto = (produto) => ({
    type: Action_Admin_Types.SAVE_PRODUTO,
    produto
})
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../admin.css'
import { api } from '../../services/api.service'
import { setNotification } from '../../services/dal.service'
import {
    Table, Button, Card, Space, Drawer, Avatar,
    Input
} from 'antd'
import ilustracion from '../../img/ilustracoes/undraw_fans_gr54.png'
import trofeu from '../../img/award.png'
import { isBrowser } from "react-device-detect";
import Cliente from '../../components/cliente'

const { Column } = Table

export const Clientes = (props) => {

    const { token } = props
    const [data, setData] = useState([])
    const [cliente, setCliente] = useState({})
    const [loading, setLoading] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [nome, setNome] = useState('')

    useEffect(() => {
        load()
    }, [])

    async function load() {
        try {
            setLoading(true)
            var response = await api.get('/api/v2.0/clientes', {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                // console.log(response.data)
                setData(response.data)
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            props.history.push('/login')
        }
        setLoading(false)
    }



    return isBrowser ? renderDesktop() : renderMobile()

    function renderMobile() {
        return <div style={{ backgroundColor: 'white', flex: 1 }}>
            {
                renderContextArea()
            }
        </div>
    }

    function renderDesktop() {
        return <div className="app-window">
            {renderContextArea()}
        </div>
    }







    function renderContextArea() {
        return <React.Fragment>
            <div className="contentarea">
                <Card title={null} style={{ marginBottom: 15 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img style={{ marginRight: 40 }} src={ilustracion} alt="img" height={50} width={60} />
                        <h6>Clientes atendidos na plataforma de delivery.</h6>

                    </div>
                </Card>
                <Input placeholder='Nome do cliente' style={{ marginBottom: 5 }}
                value={nome}
                onChange={(e)=> setNome(e.target.value)} />
                <Table
                    style={{ fontSize: '0.9em' }}
                   // dataSource={data}
                   dataSource={data.filter((item) =>
                    item?.displayName?.toLowerCase().includes(nome.toLowerCase()))}
                    size="small"
                    loading={loading}
                    rowKey={"usuid"}
                    pagination={{ pageSize: 50 }}
                    footer={() =>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Total de clientes: <b>{data.length}</b>
                        </div>
                    }>
                    <Column title="Nome" dataIndex="displayName" key="displayName"

                        sorter={
                            (a, b) => a.displayName - b.displayName

                        }
                        render={(text) => {
                            return text
                        }}


                    />

                    <Column title="Telefone" dataIndex="phoneNumber" key="phoneNumber"
                        render={(text) => {
                            return text
                        }}
                    />

                    {
                        isBrowser && <Column title="E-mail" dataIndex="email" key="email"
                            width={100}
                            render={(text) => {
                                return <div> {text}</div>
                            }}
                        />
                    }



                    <Column title="Pontos" dataIndex="pontos" key="pontos"
                        sorter={
                            (a, b) => a.pontos - b.pontos

                        }
                        width={100}
                        render={(text, record) => {
                            return <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                                    <img style={{ marginRight: 5 }}
                                        src={trofeu} alt="img" height={20} width={20} /> {text}
                                </div>
                                {
                                    !isBrowser && <Space size="middle" onClick={() => {

                                    }}>
                                        <Button type="link" onClick={() => {
                                            setCliente(record)
                                            setIsModalVisible(true)
                                            //   loadCliente(record.uid)
                                        }}>Detalhes</Button>
                                    </Space>
                                }
                            </div>
                        }}
                    />

                    {
                        isBrowser && <Column title="" dataIndex="edit" width={50}
                            render={(text, record, index2) => {
                                return <Space size="middle" onClick={() => {

                                }}>
                                    <Button type="link" onClick={() => {
                                        setCliente(record)
                                        setIsModalVisible(true)
                                    }}>Detalhes</Button>
                                </Space>
                            }}
                        />
                    }
                </Table>

            </div>

            <Drawer title={<div style={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar src={cliente?.photoURL} style={{ marginRight: 4 }} />
                {" "} {cliente?.displayName}
            </div>}
                destroyOnClose={true}
                placement="right" onClose={() => { setIsModalVisible(false) }}
                width={600}
                open={isModalVisible}>
                <Cliente
                    uid={cliente?.uid}
                    setIsModalVisible={setIsModalVisible} />
            </Drawer>



        </React.Fragment>
    }

}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Clientes)

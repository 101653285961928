import { Action_Admin_Types } from '../actions/admin.action'

const initialState = {
    admin: null,
    categoria: undefined,
    produto:undefined
}

const adminReducer = (state = initialState, action) => {

    switch (action.type) {

        case Action_Admin_Types.SAVE_ADMIN:
            return {
                ...state,
                admin: action.admin
            }

        case Action_Admin_Types.SAVE_CATEGORIA:
            return {
                ...state,
                categoria: action.categoria
            }

            case Action_Admin_Types.SAVE_PRODUTO:
            return {
                ...state,
                produto: action.produto
            }

        default:
            return state;
    }
}


export default adminReducer;
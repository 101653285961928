import axios from 'axios';

const baseUrl = 'https://menuengfood.com.br/';
//const baseUrl = 'http://localhost/';
export const api = axios.create({baseURL:baseUrl});


export const apisite = axios.create({baseURL:"https://programaengfood.com.br/"});


const baseUrlViaCep = 'https://viacep.com.br/ws/';
export const apiviacep = axios.create({baseURL:baseUrlViaCep});
//export default viacep;


//const baseUrlWCF = 'http://localhost:8888/servico/';
//export const apiWCF = axios.create({
//    baseURL:baseUrlWCF,
//    withCredentials: true,
//    headers: {
//       post: { 
//            'Content-Type': 'application/json',
//            "Accept":"*/*",
//        },
//      
//   },});
    


//PAGAR.ME export const ENCRYPT_KEY =   "ek_test_kSsao2ZIDvQwezLlAAJOYda7RGKJqY"; 
export const ENCRYPT_KEY =   "ek_live_CLweNIoV6kVsfsmxjZ5VucBXi1e0Iz";

//MERCADOPAGO 
export const MP_PUBLIC_KEY = "APP_USR-71546c57-14e9-47b7-8e5d-17c2399fcf08"//"APP_USR-71546c57-14e9-47b7-8e5d-17c2399fcf08" 
export const MP_ID = "1988184707646324" //"23856490837356"
export const CLIENT_SECRET = "ee4pD2YPWGm50W1TSV7EEuFkeeQAfNP8" //"ee4pD2YPWGm50W1TSV7EEuFkeeQAfNP8"
export const ACCESS_TOKEN_MP = "APP_USR-1988184707646324-052511-e932892c774a00b5cb11ec674da24cf9-1482300" //"APP_USR-23856490837356-052715-d5a1c98bafc13e4f3f5ac7dd3644b9cd-1482300"


export const BASEURL = baseUrl

//GOOGLE API
export const GOOGLE_KEY = "AIzaSyDaCMlM289_X30c4PfJR8DPyYXf9og3B80"




import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BASEURL, MP_ID, ACCESS_TOKEN_MP, CLIENT_SECRET } from '../../services/api.service'
import { api } from '../../services/api.service'
import { setNotification } from '../../services/dal.service'
import mercadopagoImg from '../../img/mercadopago.png'
import { Image } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { OAuth, MercadoPagoConfig } from 'mercadopago'

export const AuthorizationCode = (props) => {

    const { token } = props
    const navigate = useNavigate()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const path = urlParams.get('state');
       // console.log('code  e path:', code, path)
        if (code) {
            gerarCredencial(code, path)
        } else {
            //  props.history.push('/formas-de-pagamento')
            setNotification('Eu ao gerar credenciais!', 'error')
        }
        //  console.log(myParam)
    }, [])


    async function gerarCredencial(code, path) {
        try {
            console.log('CODE: ', code)

            var conteudo = {
                client_id: "1988184707646324",
                client_secret: "ee4pD2YPWGm50W1TSV7EEuFkeeQAfNP8",
                code: code,
                grant_type: "authorization_code",
                redirect_uri: "https://portal.menuengfood.com.br/authorization-code", //"https://22fc-45-182-46-131.ngrok-free.app/authorization-code",
               // refresh_token: code,
                test_token: false
            }

            /*
            var url2 = "https://api.mercadopago.com/oauth/token"
            const response = await axios.post(url2, conteudo)
            */

            const response = await api.post("/api/v2.0/formas-de-pagamento/obterAccessToken-mp", {}, {
                headers: {
                    'x-access-token': token,
                },
                params:{
                    code:code
                }
            })
            console.log(response.data)

           
            console.log("response.data", response.data.data)
            await api.post("/api/v2.0/formas-de-pagamento/credential-mp",
                response.data.data, {
                headers: {
                    'x-access-token': token,
                }
            })

            setNotification('As credenciais foram salva com sucesso', 'success')
            navigate('/formas-de-pagamento')

        } catch (error) {
            console.log('error: ', error.message)
            setNotification(error.message, 'error')
        }
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, textAlign: 'center' }}>
                <Image height={150} width={150}
                    preview={false}
                    alt="mercadopago" src={mercadopagoImg} />
                <i><LoadingOutlined /> Processando...</i>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationCode)




import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'

import * as ActionToken from '../store/actions/token.action';
import { isBrowser } from "react-device-detect";
import { useRecoilState, useRecoilValue } from 'recoil'
import { pedidosState, statusState } from '../store/recoils/data'
import { api } from '../services/api.service'
import toqueMP3 from '../media/ring1.wav'
import { setNotification } from '../services/dal.service';
import { useAudio } from "react-awesome-audio";
import useSound from 'use-sound';
import * as ActionPedidos from '../store/actions/pedidos.action'



export const PedidosService = (props) => {

    const { token, savePedidos, pedidos, executando } = props
    const [status, setStatus] = useRecoilState(statusState)
    const [playbackRate, setPlaybackRate] = useState(0.75);
    const [play, { stop, pause }] = useSound(toqueMP3, { playbackRate });


    const loadData = useCallback(async function () {
        const result = await polling();
        // console.log("old value: ", pedidos); // "value" is always 0 (initial)
        savePedidos(result);
    }, [pedidos]);


    useEffect(() => {
        const timerId = setInterval(loadData, 10000);
        return () => clearInterval(timerId);
    }, [loadData]);



    async function polling() {

        if (executando)
            return

        var peds = JSON.parse(JSON.stringify(pedidos));
        try {
            var valida = (!window.location.href.includes('localhost:3000/login') &&
                !window.location.href.includes('menuengfood.com.br/login') &&
                !window.location.href.includes('programaengfood.com/login'))
            //console.log('valida', valida)
            if (valida && token) {

                const rota1 = '/api/v2.0/pedidos/loja/orders'
                const rota2 = '/api/v2.0/pedidos/loja/orders/initial'
                var url = parseInt(peds.length) === 0 ? rota2 : rota1
                //  console.log('pedidos.length: ', pedidos.length, url)

                var response = await api.get(url, {
                    headers: {
                        'x-access-token': token,
                    }
                })
                //  console.log(response.data)
                if (response.data?.open)
                    setStatus(response.data.open)
                else
                    setStatus(false)



                if (response.data.orders.length > 0) {
                    var orders = response.data.orders;
                    var i = 0
                    for (i = 0; i < orders.length; i++) {

                        var index = peds.findIndex(value => orders[i].pedid === value.pedid)

                        if (index >= 0)
                            peds[index] = orders[i];
                        else
                            peds.push(orders[i])

                        if (orders[i].renotifica !== 0) {
                            await api.put('/api/v2.0/pedido/renotifica', orders[i], {
                                headers: {
                                    'x-access-token': token,
                                }
                            })
                        }
                    }
                    var auxs = peds.sort((a, b) => {
                        if (a.pedid > b.pedid)
                            return -1;
                        if (a.pedid < b.pedid)
                            return 1;
                        return 0;
                    })

                    peds = auxs

                } else {
                    //  savePedidos([...peds])
                }

            } else {
                console.log('window.location.href', props.location)
            }

        } catch (error) {
            console.log('error', error.message)
        }
        toqueFone()
        return peds
    }



    function toqueFone() {
        var item = pedidos.filter(value => value.status === 0)

        if (item.length > 0) {
            setPlaybackRate(0.5);
            console.log('Tem pedidos pendentes')
            play()
            setNotification('Existem pedidos a serem confirmados', 'info')
        } else {
            stop()
            //  play()
        }
    }

    return <div></div>
}

const mapStateToProps = (state) => ({
    tokenFull: state.tokenReducer.tokenFull,
    token: state.tokenReducer.token,
    //  status: state.pedidosReducer.status,
    pedidos: state.pedidosReducer.pedidos,
    executando: state.pedidosReducer.executando,
})

const mapDispatchToProps = dispatch => ({
    saveToken: (token) => dispatch(ActionToken.save_token(token)),
    savePedidos: (pedidos) => dispatch(ActionPedidos.save_pedidos(pedidos)),
    saveExecutando: (executando) => dispatch(ActionPedidos.save_executando(executando)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PedidosService)
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './disponibilidade.css'
import * as AdminAction from '../../../store/actions/admin.action'
//import 'antd/dist/antd.css';
import { Radio, Checkbox, List, Switch, Space } from 'antd';
import { isMobile } from 'react-device-detect';


export const Disponobilidade = (props) => {

    const { token, produto, setProduto } = props

    return (
        <div>
            <List bordered size='small' style={{ marginTop: 15 }}>
                <List.Item
                    actions={[<Switch
                        style={{ marginLeft: '20px', width: 70 }}
                        checkedChildren="Sim"
                        unCheckedChildren="Não"
                        checked={produto.Disponivel === 1}
                        onChange={(e) => {
                            setProduto({ ...produto, Disponivel: produto.Disponivel === 0 ? 1 : 0 })
                        }} />]}>
                    <List.Item.Meta
                        title={"Disponibilidade"}
                        description="Seu produto deve ser exibido em seu cardápio e estará apto para pedidos."
                    />
                </List.Item>

                {
                    (produto.Disponivel === 1 && produto.ispattern === 1) &&
                    <List.Item>
                        <List.Item.Meta
                            title={"Disponível por dia(s)"}
                            description={
                                <Space direction='vertical' size={'small'}>
                                    <div>
                                        Caso queira disponibilizar apenas em alguns dias selecione os dias desejados.
                                    </div>
                                    <Space size={'small'} direction={isMobile ? 'vertical' : 'horizontal'}>
                                        <Checkbox
                                            style={{ marginLeft: 7 }}
                                            checked={produto.indispdom === 0 ? true : false}
                                            onChange={(e) => { setProduto({ ...produto, indispdom: e.target.checked ? 0 : 1 }) }}>
                                            Domingo
                                        </Checkbox>
                                        <Checkbox
                                            checked={produto.indispseg === 0 ? true : false}
                                            onChange={(e) => { setProduto({ ...produto, indispseg: e.target.checked ? 0 : 1 }) }}>
                                            Segunda
                                        </Checkbox>
                                        <Checkbox
                                            checked={produto.indispter === 0 ? true : false}
                                            onChange={(e) => { setProduto({ ...produto, indispter: e.target.checked ? 0 : 1 }) }}>
                                            Terça
                                        </Checkbox>
                                        <Checkbox
                                            checked={produto.indispqua === 0 ? true : false}
                                            onChange={(e) => { setProduto({ ...produto, indispqua: e.target.checked ? 0 : 1 }) }}>
                                            Quarta
                                        </Checkbox>
                                        <Checkbox
                                            checked={produto.indispqui === 0 ? true : false}
                                            onChange={(e) => { setProduto({ ...produto, indispqui: e.target.checked ? 0 : 1 }) }}>
                                            Quinta
                                        </Checkbox>
                                        <Checkbox
                                            checked={produto.indispsex === 0 ? true : false}
                                            onChange={(e) => { setProduto({ ...produto, indispsex: e.target.checked ? 0 : 1 }) }}>
                                            Sexta
                                        </Checkbox>
                                        <Checkbox
                                            checked={produto.indispsab === 0 ? true : false}
                                            onChange={(e) => { setProduto({ ...produto, indispsab: e.target.checked ? 0 : 1 }) }}>
                                            Sábado
                                        </Checkbox>
                                    </Space>
                                </Space>
                            }
                        />
                    </List.Item>
                }

            </List>

            {
                /*
            <div>
                            Defina em que momento os clientes poderão comprar esse item.
                        </div>
                        <br />
                        <Radio.Group onChange={(e) => setProduto({ ...produto, Disponivel: e.target.value ? 1 : 0 })}
                            value={produto.Disponivel}>
                            <Radio
                                value={1}>
                                <b> Disponível </b> - Seu produto será exibido em seu cardápio e estará apto para pedidos.
                            </Radio >
                            <br />
                            <Radio
                                style={{ marginTop: 10 }}
                                value={0}>
                                <b> Indisponível </b> - Esse item não será exibido em seu cardápio.
                            </Radio >
                        </Radio.Group>
                */
            }




        </div >
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    produto: state.adminReducer.produto
})

const mapDispatchToProps = dispatch => ({
    setProduto: (produto) => dispatch(AdminAction.save_produto(produto))
})

export default connect(mapStateToProps, mapDispatchToProps)(Disponobilidade)

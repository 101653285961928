import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import MERCADOPAGO_PNG from '../../img/mercadopago.png'
import { Image, Space } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { MP_ID, CLIENT_SECRET } from '../../services/api.service'
import { api } from '../../services/api.service'
import { setNotification } from '../../services/dal.service'
import { useNavigate } from 'react-router-dom'

export const AutorizationMercadoPago = (props) => {

    const { token } = props
    const navigate = useNavigate()

    useEffect(() => {
        const urlParans = new URLSearchParams(window.location.search)
        var code = urlParans.get('code')
        if (code) {
            gerarCredencial(code)
        }
    }, [])


    async function gerarCredencial(code) {
        try {
            var result = await api.post('/api/v2.0/formas-de-pagamento/mp_create_credencial',
                {
                }
                , {
                    headers: {
                        'x-access-token': token,
                    },
                    params: {
                        client_secret: CLIENT_SECRET,
                        client_id: MP_ID,
                        grant_type: 'authorization_code',
                        code: code,
                        redirect_uri: 'https://programaengfood.com.br/authorization/index',                       
                    }
                })         

        } catch (error) {            
            setNotification(error.message, 'error')           
        }
        navigate('/formas-de-pagamento')
    }



    return (
        <div style={{
            height: '80%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Space direction='vertical'>
                <Image src={MERCADOPAGO_PNG} preview={false} />
                <Space direction='horizontal' style={{
                    color: 'royalblue',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    letterSpacing: .03333,
                    fontFamily: 'monospace',
                    fontWeight: 600,
                    fontSize: '1.1em'
                }}>
                    <LoadingOutlined style={{ fontSize: '1.5em' }} />
                    Aguarde, processando...
                </Space>

            </Space>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AutorizationMercadoPago)
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../admin.css'
import { api } from '../../services/api.service'
import { setNotification, getPermissoes, GERENTE } from '../../services/dal.service'
import { Table, Select, TimePicker, Tag, Button, Card, App, Space } from 'antd'
import ilustracao from '../../img/ilustracoes/undraw_time_management_30iu.png'
//import moment from 'moment';
import moment from 'dayjs';
import { isBrowser, isDesktop } from "react-device-detect";

const { Option } = Select
const { RangePicker } = TimePicker;
const { Column } = Table

export const Schedule = (props) => {

    const { token, tokenFull } = props
    const [schedule, setSchedule] = useState([])
    const [loading, setLoading] = useState(true)
    const permissions = getPermissoes(tokenFull)
    const { modal } = App.useApp()

    useEffect(() => {
        load()
    }, [])

    async function load() {
        try {
            setLoading(true)
            var response = await api.get('/api/v2.0/schedule', {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                //   console.log(response.data)
                setSchedule(response.data)
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            props.history.push('/login')
        }
        setLoading(false)
    }



    async function save() {
        try {


            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }

            if (schedule.length !== 7) {
                setNotification('Parece que os dados não foram carregados corretamente! Atualize a página.', 'error')
                return
            }

            setLoading(true)
            var response = await api.put('/api/v2.0/schedule', schedule, {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                setNotification('Horário de funcionamento alterado com sucesso!', 'success')
                load()
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }


    return isBrowser ? renderDesktop() : renderMobile()

    function renderDesktop() {
        return <div className="app-window">
            {renderContentArea()}
        </div>
    }

    function renderContentArea() {
        return <div className="contentarea">
            <Card title={null} style={{ marginBottom: 15 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img style={{ marginRight: 40 }} src={ilustracao} alt="img" height={70} width={90} />
                    <h4>Horários de atendimento para recebimentos de pedidos.</h4>
                </div>
            </Card>

            <Table dataSource={schedule} pagination={false} size="small" loading={loading}
                rowKey={'id'}
                footer={() => <div style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div></div>
                    <div><Button type="primary" danger style={{ marginRight: 15 }}
                        onClick={() => load()}>Cancelar</Button>
                        <Button type="primary"
                            disabled={schedule?.length !== 7 || !permissions?.includes(GERENTE)}
                            onClick={() => save()}>Salvar</Button>
                    </div>
                </div>}>
                {isDesktop && <Column title="" dataIndex="DiaSemana" key="DiaSemana"
                    render={(text, record) => {
                        return <Tag color="blue" style={{ minWidth: 70, textAlign: 'center' }}> {text} </Tag>
                    }} />
                }
                <Column title="Turnos" dataIndex="turnos" key="turnos"
                    render={(text, record, index) => {

                        if (isDesktop) {
                            return <Select defaultValue="1" value={text}
                                disabled={!permissions?.includes(GERENTE)}
                                onChange={(value) => {
                                    var newdata = Object.values(schedule)
                                    newdata[index].turnos = value
                                    setSchedule(newdata)
                                }}>
                                <Option value={0}>Fechado</Option>
                                <Option value={1}>1 Turno</Option>
                                <Option value={2}>2 Turnos</Option>
                            </Select>
                        } else {
                            return <Space direction='vertical'>
                                <Tag color="blue" style={{ minWidth: 90, textAlign: 'center' }}>
                                    {record.DiaSemana} </Tag>
                                <Select defaultValue="1" value={text}
                                    disabled={!permissions?.includes(GERENTE)}
                                    onChange={(value) => {
                                        var newdata = Object.values(schedule)
                                        newdata[index].turnos = value
                                        setSchedule(newdata)
                                    }}>
                                    <Option value={0}>Fechado</Option>
                                    <Option value={1}>1 Turno</Option>
                                    <Option value={2}>2 Turnos</Option>
                                </Select>
                            </Space>
                        }
                    }} />

                {
                    isDesktop && <Column title="1º Turno" dataIndex="dia" key="dia"
                        render={(text, record, index) => {
                            return <RangePicker format={'HH:mm'} allowClear={false}
                                disabled={record.turnos < 1 || !permissions?.includes(GERENTE)}
                                value={[moment(record.hora1, 'HH:mm'),
                                moment(record.hora2, 'HH:mm')]}
                                onChange={(value) => {
                                    console.log(schedule)
                                    var newdata = Object.values(schedule)
                                    newdata[index].hora1 = value[0].format('HH:mm')
                                    newdata[index].hora2 = value[1].format('HH:mm')
                                    setSchedule(newdata)
                                }} />
                        }} />
                }
                {
                    isDesktop && <Column title="2º Turno" dataIndex="dia" key="dia"
                        render={(text, record, index) => {
                            return <RangePicker format={'HH:mm'} allowClear={false}
                                disabled={record.turnos !== 2 || !permissions?.includes(GERENTE)}
                                placeholder="Inicia" value={[moment(record.hora3, 'HH:mm'),
                                moment(record.hora4, 'HH:mm')]}
                                onChange={(value) => {
                                    var newdata = Object.values(schedule)
                                    newdata[index].hora3 = value[0].format('HH:mm')
                                    newdata[index].hora4 = value[1].format('HH:mm')
                                    setSchedule(newdata)
                                }} />


                        }} />
                }

                {
                    !isDesktop && <Column title="Turnos" dataIndex="dia" key="dia"
                        render={(text, record, index) => {
                            return <div>
                                <div>
                                    <RangePicker format={'HH:mm'} allowClear={false}
                                        disabled={record.turnos < 1 || !permissions?.includes(GERENTE)}
                                        value={[moment(record.hora1, 'HH:mm'), moment(record.hora2, 'HH:mm')]}
                                        onChange={(value) => {
                                            var newdata = Object.values(schedule)
                                            newdata[index].hora1 = value[0].format('HH:mm')
                                            newdata[index].hora2 = value[1].format('HH:mm')
                                            setSchedule(newdata)
                                        }} />
                                </div>
                                <div style={{ marginTop: 3 }}>
                                    <RangePicker format={'HH:mm'} allowClear={false}
                                        disabled={record.turnos !== 2 || !permissions?.includes(GERENTE)}
                                        placeholder="Inicia" value={[moment(record.hora3, 'HH:mm'),
                                        moment(record.hora4, 'HH:mm')]}
                                        onChange={(value) => {
                                            var newdata = Object.values(schedule)
                                            newdata[index].hora3 = value[0].format('HH:mm')
                                            newdata[index].hora4 = value[1].format('HH:mm')
                                            setSchedule(newdata)
                                        }} />
                                </div>

                            </div>

                        }} />
                }


            </Table>



        </div>
    }


    function renderMobile() {
        return <div style={{ backgroundColor: 'white' }}>
            {
                renderContentArea()
            }
        </div>
    }
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)

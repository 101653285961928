import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { apisite } from '../../services/api.service'
import { Link, Typography } from '@material-ui/core';
import * as ActionToken from '../../store/actions/token.action'
import { Button,  Input, Space, App } from 'antd'
import '../login/login.css'
import { UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {Copyright} from '../../components/copyright';




export const Forgot = (props) => {

    const navigate = useNavigate()
    const { notification } = App.useApp()
    const { token, saveToken, saveTokenFull } = props

    const [email, setEmail] = React.useState('')
    const [senha, setSenha] = React.useState('')
    const [loading, setLoading] = React.useState(false)


    useEffect(() => {
        if (token) {
            // props.history.push('/admin')
        }
    }, [])


    async function forgot() {
        try {
            setLoading(true)
            await apisite.post('api/v1.0/user/recuperar-senha', { token: email, email: email, isMenuengfood: true })
            notification.success({
                message: "Atenção",
                description: 'Foi enviado um e-mail para '.concat(email, ' para recuperação da senha')
            })
            navigate('/login')
            setLoading(false)
        } catch (error) {
            console.log("error")
            setLoading(false)
            if (error.message === 'Request failed with status code 401') {
                notification.error({
                    message: "Atenção",
                    description: "Token e/ou senha inválido!"
                })
            } else {
                notification.error({
                    message: "Atenção",
                    description: error.message
                })
            }
        }
    }


    return <div className='plano-de-fundo'>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            justifyContent: 'center',
            width: 433,
            alignItems: 'center'
        }}>
            <strong style={{ fontSize: '1.7em', color: 'white' }}>Portal Menuengfood</strong>
            <div style={{ fontSize: '0.95em', color: 'whitesmoke' }}>Enviamos a senha para seu e-mail cadastrado.</div>


            <Input style={{ marginTop: 35 }} size='large' placeholder='Informe seu Token(e-mail)'
                prefix={<UserOutlined />}
                value={email}
                onChange={(e) => setEmail(e.target.value)} />

            <Button
                style={{ marginTop: 35 }}
                block
                size='large'
                loading={loading}
                type="primary"
                onClick={() => {
                    forgot()
                }}>Recuperar a senha</Button>

            <Space direction='horizontal'>

                <Button type='link'
                    style={{ marginTop: 20, color: 'whitesmoke' }}                   
                    variant="body2"
                    onClick={() => {
                        navigate("/login");
                    }}>
                    Voltar
                </Button>
            </Space>
            <Copyright />
        </div>
    </div>


}


const mapStateToProps = (state) => ({
    token: state.tokenReducer.token
})

const mapDispatchToProps = dispatch => ({
    saveToken: (token) => dispatch(ActionToken.save_token(token)),
    saveTokenFull: (token) => dispatch(ActionToken.save_token_full(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(Forgot)
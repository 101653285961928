import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { apisite, GOOGLE_KEY, api } from '../../services/api.service'
import { Link, Typography } from '@material-ui/core';
import * as ActionToken from '../../store/actions/token.action'
import {
  Button, Input, Space, App, message, Steps, theme, Card, Form, Checkbox, Col, Row,
  Layout, Radio, Select
} from 'antd'
import '../login/login.css'
import { UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import Copyright from '../../components/copyright';
import { isMobile } from 'react-device-detect';
import TelefoneBrasileiroInput from 'react-telefone-brasileiro'
import { validateEmail } from '../../services/dal.service'
import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios'

const { Sider, Content } = Layout
const { Option } = Select

const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;


export const Register = (props) => {

  //const { token, tokenFull } = props
  const navigate = useNavigate()
  const { token,tokenFull, saveToken, saveTokenFull } = props
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false)
  const inputRef = useRef();
  const [enderecoTemp, setEnderecoTemp] = useState(undefined)
  const [current, setCurrent] = useState(0);
  const [cidades, setCidades] = useState([])
  const [patherro, setPatherro] = useState('')
  const [data, setData] = useState({
    pedidosDia: 0
  })

  const plainOptions = ['Menos de 10', 'Mais de 10'];
  const options = [
    {
      label: 'Menos de 10',
      value: 0
    },
    {
      label: 'Mais de 10',
      value: 1,
    },
  ];

  const [libraries] = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_KEY,
    libraries: libraries,
  });


  useEffect(() => {
    //  setDisable(valida1)
  }, [data])




  async function salvar() {
    try {
      setLoading(true)

      var _data = {...data}

      _data.email = data.Email
      _data.token = data.Email
      
      _data.informacoes = enderecoTemp
      _data.informacoes.Bairro = enderecoTemp.bairro
      _data.informacoes.Cidade = enderecoTemp.localidade
      _data.informacoes.endereco = enderecoTemp.logradouro
      _data.informacoes.Bairro = enderecoTemp.bairro
      _data.informacoes.telefone = _data.Telefone
      _data.informacoes.Fantasia = _data.Fantasia
      _data.informacoes.Endereco = enderecoTemp.logradouro
      _data.informacoes.Estado = enderecoTemp.uf
      _data.informacoes.Telefone = _data.Telefone
      _data.informacoes.exibirEndereco = 1
      _data.informacoes.formasPagamento = 1
      _data.informacoes.minimo = 10
      _data.informacoes.retirarLocal = 1
      _data.informacoes.tempo1 = '30-45'
      _data.informacoes.estadoname = getEstadoName(enderecoTemp.uf)

      _data.menuengfood = {
        path : _data.path,
        cor : "#000000",
        url : "https://menuengfood.com.br/logos/sua-logo-aqui.png" 
      }
     
      await api.post('/api/v2.0/restaurante/register', _data)
      const response = await api.post('api/v2.0/usuarios/login', { token: _data.Email, pwd: _data.senha });
      saveTokenFull(response.data)
      saveToken(response.data.token)
      navigate('/')
      
    } catch (error) {
      message.error(error.message)
      console.log(error.message)
    }
    setLoading(false)
  }


  async function validarPath() {
    const validar = validateUrl('https://menuengfood.com.br/app/'.concat(data.path))

    if (validar !== true) {
      message.error("Por favor, verique o link do seu estabelecimento, ele parece não ter válido!")
      return
    }

    //setCurrent(1) /api/v2.0/restaurante/path
    try {
      setLoading(true)
      const resposta = await api.post('/api/v2.0/restaurante/path', data)
      if (resposta.data.status === 'ok') {
        setPatherro('')
        setCurrent(1)
      } else {
        message.error(resposta.data.msg)
        setPatherro(resposta.data.msg)
      }
    } catch (error) {
      message.error(error.message)
    }
    setLoading(false)
  }


  async function buscar() {
    try {
      setLoading(true)
      var aux = enderecoTemp?.logradouro.concat(enderecoTemp?.numero === 's/n' ? ' ' : enderecoTemp?.numero,
        ", ", enderecoTemp?.bairro, ",", enderecoTemp?.localidade, '-', enderecoTemp?.uf)
      var url = "https://maps.googleapis.com/maps/api/geocode/json?address=".concat(aux, "&key=", GOOGLE_KEY)
      const response = await axios.get(url);
      const data = response.data
      console.log(data)
      switch (data.status) {
        case "OK":
          var ed = { ...enderecoTemp }
          ed.latitude = data.results[0].geometry.location.lat
          ed.longitude = data.results[0].geometry.location.lng
          setEnderecoTemp(ed)
          setCurrent(2)
          break;
        case "ZERO_RESULTS": message.warning("Não foram localizados endereços para os dados informados!"); break;
        case "OVER_QUERY_LIMIT": message.warning("Desculpe, o serviço não está conseguindo localizar os dados!"); break;
        case "REQUEST_DENIED": message.warning("A solicitação foi negada pelo serviço do GOOGLE, verifique se os dados estão corretos e tente novamente!"); break;
        case "INVALID_REQUEST": message.warning("Erro ao efetuar solicitação, verifique se os dados do endereço estão corretos!"); break;
        case "UNKNOWN_ERROR": message.warning("Solicitação não foi processada devido a um erro de servidor. Tente novamente"); break;
        case "ERROR": message.error("Solicitação expirou ou houve um problema de contato com os servidores do Google, tente novamente em instantes!"); break;
        default: message.error("Erro desconhecido, tente novamente em instantes! ");
      }
    } catch (error) {
      console.log()
      message.error(error.message)
    }
    setLoading(false)
  }



  async function getCidades(value) {
    try {
      var temp = { ...enderecoTemp }
      temp.uf = value
      setEnderecoTemp(temp)
      const res = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'.concat(value, '/municipios'))
      if (res.status === 200) {
        //console.log(res.data)
        setCidades(res.data)
      } else {
        console.log('Erro ao carregar cidades para o estado selecionado! ')
        setCidades([])
      }
    } catch (error) {
      //  message.error(error.message)
      setCidades([])
    }
  }

  const handlePlaceChanged = () => {
    var end = {}
    const [place] = inputRef.current.getPlaces();

    if (place) {
      place.address_components.forEach(element => {
        if (element.types.find(e => 'route' === e)) { end.logradouro = element.long_name }
        else if (element.types.find(e => 'sublocality_level_1' === e || 'sublocality' === e)) { end.bairro = element.long_name }
        else if (element.types.find(e => 'administrative_area_level_2' === e)) { end.localidade = element.long_name }
        else if (element.types.find(e => 'administrative_area_level_1' === e)) {
          end.uf = element.short_name
        }
        else if (element.types.find(e => 'country' === e)) { end.pais = element.long_name }
        else if (element.types.find(e => 'postal_code' === e)) { end.cep = element.long_name }
        else if (element.types.find(e => 'street_number' === e)) { end.numero = element.long_name }
      });
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      end.longitude = lng
      end.latitude = lat
      setEnderecoTemp(end)
    } else {
      setEnderecoTemp(undefined)
    }
  }

  function validateUrl(value) {
    try {
      new URL(value)
    } catch (eroror) {
      console.log('error')
    }
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  function valida() {

    if (current === 0) {
      if (!data?.Responsavel?.trim())
        return true
      if (data?.Telefone?.length < 14)
        return true
      if (!validateEmail(data?.Email))
        return true
      if (!data?.Fantasia?.trim())
        return true
      /*
      if (data?.path?.includes(' ')){
        setPatherro('Não pode conter espaço')
        return true
      }else{
        setPatherro('')
      }
      */

    } else if (current === 1) {
      if (!enderecoTemp?.uf)
        return true
      if (!enderecoTemp?.localidade)
        return true
      if (!enderecoTemp?.bairro)
        return true
      if (!enderecoTemp?.logradouro)
        return true
      if (!enderecoTemp?.numero)
        return true
      if (!enderecoTemp?.referencia)
        return true
    } else if (current === 2) {
      if (!data?.senha)
        return true
      if (data?.senha?.includes(' ') || data?.senha2?.includes(' '))
        return true
      if (data?.senha.trim().length < 4)
        return true
      if (data?.senha.trim() !== data?.senha2)
        return true
    }

    return false
  }


  const headerStep = () => {
    return <Steps
      direction="vertical"
      size="small"
      current={current}
      items={[
        {
          title: <span style={{ fontSize: '1.2em' }}>Conta</span>
        },
        {
          title: <span style={{ fontSize: '1.2em' }}>Localização</span>
        },
        {
          title: <span style={{ fontSize: '1.2em' }}>Credenciais</span>
        },

      ]}
    />
  }



  function renderEtapa3() {
    return <Space direction='vertical'>

      <div style={{ marginTop: 50 }}>
        <strong style={{ fontSize: '1.5em' }}>
          Defina sua senha
        </strong>
      </div>
      <span style={{ color: 'darkgrey' }}>
        Para manter sua conta segura, defina uma senha de acesso ao nosso sistema com pelo menos 4 caracteres.
      </span>

      <div>
        <b>Senha*</b>
        <Input.Password
          value={data?.senha}
          onChange={e => setData({ ...data, senha: e.target.value.trim() })} />
      </div>

      <div>
        <b>Confirmar senha*</b>
        <Input.Password
          value={data?.senha2}
          onChange={e => setData({ ...data, senha2: e.target.value.trim() })} />
      </div>



    </Space>
  }


  function renderEtapa2() {
    return <div style={{width:'100%'}}>
       <Space direction='vertical' >
      <div style={{ marginTop: 50 }}>
        <strong style={{ fontSize: '1.5em' }}>
          Localização do Estabelecimento
        </strong>
      </div>
      <span style={{ color: 'darkgrey' }}>
        Precisamos saber a localização para exibir a clientes próximos a você.
      </span>

      <div>
        <b>Digite o endereço*</b>
        {isLoaded && <StandaloneSearchBox
          style={{ width: '100%', flexGrow: 2 }}
          onLoad={ref => inputRef.current = ref}
          debouce={600}
          onPlacesChanged={handlePlaceChanged}>
          <input
            style={{ width: isMobile ? '100%' : '100%', padding: 5 }}
            type="text"
            className={"input-searching"}
            placeholder="Informe o endereço"
            onChange={(e) => {
              //  setEnderecoTemp(undefined)
            }}
          />
        </StandaloneSearchBox>
        }
      </div>


      {enderecoTemp && <Row gutter={[8, 8]} style={{ marginTop: 10 }}>
        <Col span={24}>
          <Select
            placeholder="Estado"
            value={enderecoTemp?.uf}
            style={{ width: '100%' }}
            onChange={(value) => {
              getCidades(value)
            }}
          >
            <Option value="AC">Acre</Option>
            <Option value="AL" >Alagoas</Option>
            <Option value="AM" >Amazonas</Option>
            <Option value="AP" >Amapa</Option>
            <Option value="BA" >Bahia</Option>
            <Option value="CE" >Ceará</Option>
            <Option value="DF" >Distrito Federal</Option>
            <Option value="ES" >Espirito Santo</Option>
            <Option value="GO" >Goias</Option>
            <Option value="MA" >Maranhão</Option>
            <Option value="MG" >Minhas Gerais</Option>
            <Option value="MS" >Mato Grosso do Sul</Option>
            <Option value="MT" >Mato Grosso</Option>
            <Option value="PA" >Pará</Option>
            <Option value="PB" >Paraíba</Option>
            <Option value="PE" >Pernambuco</Option>
            <Option value="PI" >Piauí</Option>
            <Option value="PR" >Paraná</Option>
            <Option value="RJ" >Rio de Janeiro</Option>
            <Option value="RN" >Rio Grande do Norte</Option>
            <Option value="RS" >Rio Grande do Sul</Option>
            <Option value="RO" >Roraima</Option>
            <Option value="RR" >Rondônia</Option>
            <Option value="SC" >Santa Catarina</Option>
            <Option value="SP" >São Paulo</Option>
            <Option value="SE" >Sergipe</Option>
            <Option value="TO" >Tocantins</Option>
          </Select>
        </Col>
        <Col span={24}>
          {
            <Select
              placeholder="Cidade"
              value={enderecoTemp?.localidade}
              style={{ width: '100%' }}
              onChange={(value) => {
                //  getBairros(value)
              }}>
              {
                cidades.map((item, index) => {
                  return <Option key={item.id} value={index}>{item.nome}</Option>
                })
              }
            </Select>
          }

        </Col>
        <Col span={24}>
          <Input placeholder="Bairro*" value={enderecoTemp?.bairro}
            onChange={(e) => setEnderecoTemp({ ...enderecoTemp, bairro: e.target.value })} />

        </Col>
        <Col span={12}><Input placeholder="Rua*" value={enderecoTemp?.logradouro}
          onChange={(e) => setEnderecoTemp({ ...enderecoTemp, logradouro: e.target.value })} /></Col>
        <Col span={12}>
          <Input placeholder="Número*"
            disabled={enderecoTemp?.numero === 's/n'}
            value={enderecoTemp?.numero}
            onChange={(e) => setEnderecoTemp({ ...enderecoTemp, numero: e.target.value })} /></Col>
        <Col span={12}><Input placeholder="Complemento" value={enderecoTemp?.complemento}
          onChange={(e) => setEnderecoTemp({ ...enderecoTemp, complemento: e.target.value })} /></Col>
        <Col span={12}><Input placeholder="Referência*" value={enderecoTemp?.referencia}
          onChange={(e) => setEnderecoTemp({ ...enderecoTemp, referencia: e.target.value })} /></Col>

        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox checked={enderecoTemp?.numero === 's/n'}
            onChange={(e) => {
              console.log(e)
              if (e.target.checked)
                setEnderecoTemp({ ...enderecoTemp, numero: 's/n' })
              else
                setEnderecoTemp({ ...enderecoTemp, numero: '' })
            }}>
            Endereço sem número
          </Checkbox>
        </Col>

      </Row>
      }
    </Space>
    </div>
  }



  function getEstadoName(uf) {
    let nome = "Desconhecido"
    switch (uf) {
      case "AC": nome = "Acre"; break;
      case "AL": nome = "Alagoas"; break;
      case "AM": nome = "Amazonas"; break;
      case "AP": nome = "Amapa"; break;
      case "BA": nome = "Bahia"; break;
      case "CE": nome = "Ceará"; break;
      case "DF": nome = "Distrito Federal"; break;
      case "ES": nome = "Espirito Santo"; break;
      case "GO": nome = "Goias"; break;
      case "MA": nome = "Maranhão"; break;
      case "MG": nome = "Minhas Gerais"; break;
      case "MS": nome = "Mato Grosso do Sul"; break;
      case "MT": nome = "Mato Grosso"; break;
      case "PA": nome = "Pará"; break;
      case "PB": nome = "Paraíba"; break;
      case "PE": nome = "Pernambuco"; break;
      case "PI": nome = "Piauí"; break;
      case "PR": nome = "Paraná"; break;
      case "RJ": nome = "Rio de Janeiro"; break;
      case "RN": nome = "Rio Grande do Norte"; break;
      case "RS": nome = "Rio Grande do Sul"; break;
      case "RO": nome = "Roraima"; break;
      case "RR": nome = "Rondônia"; break;
      case "SC": nome = "Santa Catarina"; break;
      case "SP": nome = "São Paulo"; break;
      case "SE": nome = "Sergipe"; break;
      case "TO": nome = "Tocantins"; break;
    }

    return nome;
  }




  function renderEtapa1() {
    return <Space direction='vertical'>
      <div style={{ marginTop: 50 }}>
        <strong style={{ fontSize: '1.5em' }}>
          Precisamos saber um pouco sobre você
        </strong>
      </div>
      <span style={{ color: 'darkgrey' }}>
        Seus dados são totalmente criptografados. Utilizaremos eles para nos
        comunicarmos sobre o seu cardápio.
      </span>

      <div>
        <b>Seu nome*</b>
        <Input
          value={data.Responsavel}
          onChange={e => setData({ ...data, Responsavel: e.target.value })} />
      </div>

      <div>
        <b>Telefone*</b>
        <TelefoneBrasileiroInput
          className={"telefone-brasileiro-input"}
          temDDD={true}
          separaDDD={true}
          value={data?.Telefone}
          defaultValue={data?.Telefone}
          onChange={e => {
            setData({ ...data, Telefone: e.target.value })
          }} />
      </div>

      <div>
        <b>E-mail*</b>
        <Input
          value={data.Email}
          onChange={e => setData({ ...data, Email: e.target.value })} />
      </div>

      <div style={{ marginTop: 30 }}>
        <strong style={{ fontSize: '1.5em' }}>
          Nos conte sobre o seu delivery
        </strong>
      </div>

      <b>Quantos pedidos você recebe por dia?</b>
      <div>
        <Radio.Group options={options} defaultValue={1}
          value={data?.pedidosDia}
          onChange={(value) => setData({ ...data, pedidosDia: value.target.value })}
          optionType="button"
          buttonStyle="solid" />
      </div>

      <b>Como podemos chamar seu estabelecimento?</b>
      <span style={{ color: 'darkgrey' }}>
        Esse normalmente é o nome que você utiliza nas suas redes sociais e cardápio.
      </span>
      <div>
        <b>Nome do estabelecimento*</b>
        <Input
          value={data.Fantasia}
          onChange={e => setData({ ...data, Fantasia: e.target.value })} />
      </div>

      <div>
        <span style={{ color: 'darkgrey' }}>
          Esse será o seu endereço do seu cardápio na internet, é a URL que você vai compartilhar nas sua redes sociais com seus clientes.
        </span>
        <div>
          <b>Link do seu Cardápio*</b>
        </div>

        <Input
          addonBefore="https://menuengfood.com.br/app/"
          value={data.path}
          placeholder={data?.Fantasia?.replace(' ', '-').toLowerCase()}
          onChange={e => setData({
            ...data, path: e.target.value.toLowerCase().trim()
              .normalize('NFD').replace(/[\u0300-\u036f]/g, "")
              .replace(/[^a-zA-Z0-9-\s]/g, "")
          })} />

        <div style={{ fontSize: '0.8em', color: 'red' }}>{patherro}</div>
        <div style={{ fontSize: '0.9em' }}>
          O link não pode conter:
        </div>
        <div style={{ fontSize: '0.9em', color: 'darkgray' }}>
          - Caracteres acentuados ou especiais. Exemplo:%@éãà
        </div>
        <div style={{ fontSize: '0.9em', color: 'darkgray' }}>
          - Espaços estre nomes
        </div>

      </div>


    </Space>
  }


  return <div style={{ height: '100vh' }}>
    <Layout style={{
      backgroundColor: 'transparent',
      height: '100vh'
    }}>
      {!isMobile && <Sider
        width={300}
        style={{
          display: 'flex',
          backgroundColor: 'whitesmoke',
          height: '100%',
          width: 400,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <div style={{
          display: 'flex',
          height: '100%',
          alignItems: 'normal',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
          <img alt='logo'
            src={require('../../img/logomenu.png')}
            height={70}
            width={70}
            style={{ marginBottom: 20 }} />
          {headerStep()}
        </div>
      </Sider>
      }
      <Layout style={{ backgroundColor: 'transparent' }}>
        <Content style={{ height: '100%', overflow: 'auto' }}>
          <div style={{ marginLeft: '15%', marginRight: '15%' }}>

            {isMobile && <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
              <img alt='logo'
                src={require('../../img/logomenu.png')}
                height={70}
                width={70}
                style={{ marginBottom: 5 }} />
              <span style={{ color: 'darkgray', marginLeft: 5 }}>passo {current + 1} de 3</span>
            </div>}

            {current === 0 && renderEtapa1()}
            {current === 1 && renderEtapa2()}
            {current === 2 && renderEtapa3()}

            <Space direction='horizontal'
              style={{ marginTop: 40, marginBottom: isMobile ? 10 : 50 }}>
              {
                current > 0 && <Button
                  style={{ minWidth: 120 }}
                  type='default'
                  size='large'
                  onClick={() => setCurrent(current - 1)}>
                  Voltar
                </Button>
              }

              <Button size='large' type='primary'
                loading={loading}
                style={{ minWidth: 120 }}
                onClick={() => {
                  if (current === 0)
                    validarPath()
                  else if (current === 1) {
                    buscar()
                  } else if (current === 2) {
                    setLoading(true)
                    salvar()
                  }
                }}
                disabled={valida()}>{current === 2 ? 'Salvar' : 'Próximo'}</Button>
            </Space>
          </div>
        </Content>
      </Layout>
    </Layout>
  </div>

}

const mapStateToProps = (state) => ({
  token: state.tokenReducer.token,
  tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = dispatch => ({
  saveToken: (token) => dispatch(ActionToken.save_token(token)),
  saveTokenFull: (token) => dispatch(ActionToken.save_token_full(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)

import { Link, Typography } from '@material-ui/core';


export const Copyright=()=>{
    return (
        <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            style={{ marginTop: 35, color: 'whitesmoke', fontSize: '0.8em' }}>
            {'Copyright © '}
            <Link color="inherit" href="https://menuengfood.com.br/">
                Menuengfood
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
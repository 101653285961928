import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Space, Menu, Avatar, Popover, QRCode, Drawer, Button, App, Badge, Alert, Tag } from 'antd'
import {
    MenuOutlined, QrcodeOutlined,
    FormOutlined, HomeOutlined, AlertFilled, LineChartOutlined, ContainerOutlined,
    ClockCircleOutlined, DollarOutlined, GiftOutlined, UserOutlined, TagsOutlined,
    TeamOutlined, CreditCardOutlined, NotificationOutlined, PushpinOutlined
} from '@ant-design/icons'
import './layoutPortal.css'
import { useNavigate, Outlet, useParams, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import * as ActionToken from '../../store/actions/token.action'
import { api } from '../../services/api.service'
import { removerAcento } from '../../services/util'
import { setNotification, getOrders } from '../../services/dal.service'
import { useRecoilState } from 'recoil'
import { pedidosState, canSoundState } from '../../store/recoils/data'
import useSound from 'use-sound';
import boopSfx from '../../media/ring1.mp3';

const { Header, Content, Sider } = Layout;
const TIME_INTERVAL = 20 * 1000

export const LayoutPortal = (props) => {


    const location = useLocation()
    const { notification } = App.useApp()
    const navigate = useNavigate()
    const { token, tokenFull, saveToken, saveTokenFull } = props
    const [leftVisible, setLeftVisible] = useState(!isMobile)
    const [loading, setLoading] = useState(false)
    const [config, setConfig] = useState({})
    const [open, setOpen] = useState(false)
    const [data, setData] = useRecoilState(pedidosState)
    const [lojaAberta, setLojaAberta] = useState(undefined)
    const [canSound, setCanSound] = useRecoilState(canSoundState)
    const [play] = useSound(boopSfx,
        { volume: 0.8 });
    const [newCountPedidos, setNewCountPedidos] = useState(0)

    useEffect(() => {
        load()
    }, [])

    useEffect(() => {
        loadPedidos()
        const interval = setInterval(() => {
            loadPedidos()
        }, TIME_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    async function load() {
        try {
            setLoading(true)
            var response = await api.get('/api/v2.0/config', {
                headers: {
                    'x-access-token': token,
                },
            })
            setConfig(response.data)

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            navigate('/login')
        }
        setLoading(false)
    }



    async function loadPedidos() {
        try {
            const response = await getOrders(token)
            const ret = response.orders
            //  console.log(ret)
            if (ret) {
                if (Array.isArray(ret)) {
                    setData(ret)
                    const itens = ret.filter(value => value.status === 0)
                    if (itens) {
                        if (itens.length > 0) {

                            let desc = "Existem ".concat(itens.length, ' pedidos aguardando confirmação.')
                            if (itens.length === 1)
                                desc = "Existe ".concat(itens.length, ' pedido aguardando confirmação.')

                            setNewCountPedidos(itens.length)
                            notification.info({
                                message: <b>Novos pedidos!</b>,
                                description: <div>
                                    <div> {desc} </div>
                                </div>,

                            });

                            play()
                        } else {
                            setNewCountPedidos(0)
                        }
                    }
                }
                setLojaAberta(response.open)
            } else {
                setLojaAberta(undefined)
            }


        } catch (error) {
            setNotification(error.message, 'error')
        }
    }



    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }



    const items = [
        getItem('Principal', 'key-home', <HomeOutlined style={{ color: 'royalblue' }} />, null),
        getItem(<Badge size='small' count={newCountPedidos}> Pedidos-PDV </Badge>, 'key-pdv', <FormOutlined style={{ color: 'royalblue' }} />, null),
        getItem('Produtos', 'key-produtos', <ContainerOutlined style={{ color: 'royalblue' }} />, null),
        getItem('Horários', 'key-horarios', <ClockCircleOutlined style={{ color: 'royalblue' }} />, null),
        getItem('Taxas de Entrega', 'key-taxas', <DollarOutlined style={{ color: 'royalblue' }} />, null),
        getItem('Fidelidade', 'key-fidelidade', <GiftOutlined style={{ color: 'royalblue' }} />, null),
        getItem('Formas de Pagamento', 'key-pagamentos', <CreditCardOutlined style={{ color: 'royalblue' }} />, null),
        getItem('Campanhas', 'key-campanhas', <NotificationOutlined style={{ color: 'royalblue' }} />, null),
        getItem('Cupons', 'key-cupons', <TagsOutlined style={{ color: 'royalblue' }} />, null),
        getItem('Localização', 'key-definiçoes', <PushpinOutlined style={{ color: 'royalblue' }} />),
        getItem("Clientes", 'key-clientes', <TeamOutlined style={{ color: 'royalblue' }} />, null),
        getItem("Equipe", 'key-equipe', <UserOutlined style={{ color: 'royalblue' }} />, null),
        getItem("Estatistica", 'key-estatistica', <LineChartOutlined style={{ color: 'royalblue' }} />, null),
        {
            type: 'divider',
        },

    ];


    const itemsContext = [
        {
            key: "item-plano",
            label: "Planos"
        },
        {
            key: "item-logout",
            label: "Encerrar sessão"
        }
    ]
    const contentContext = (
        <div>
            {
                <Menu
                    onClick={(value) => {
                        setOpen(false)
                        switch (value.key) {

                            case 'item-plano':
                                navigate('/plano')
                                break;

                            case 'item-logout':
                                saveTokenFull(undefined)
                                saveToken(undefined)
                                navigate('/login')
                                break;

                            default:
                                break;
                        }
                    }}
                    mode="inline"
                    items={itemsContext}
                />
            }
        </div>
    );


    function navigateURL(key) {
        switch (key) {
            case "key-home": navigate("/")
                break;
            case "key-pdv": navigate("/orders")
                break;
            case "key-gestor": navigate("/gestor")
                break;
            case "key-horarios": navigate("/horarios")
                break;
            case "key-fidelidade": navigate("/programa-de-fidelidade")
                break;
            case "key-cupons": navigate("/cupons")
                break;
            case "key-definiçoes": navigate("/localizacao")
                break;
            case "key-pagamentos": navigate("/formas-de-pagamento")
                break;
            case "key-campanhas": navigate("/campanhas")
                break;
            case "key-produtos": navigate("/cardapio")
                break;
            case "key-taxas": navigate("/taxas-de-entrega")
                break;
            case "key-clientes": navigate("/clientes")
                break;
            case "key-equipe": navigate("/minha-equipe")
                break;
            case "key-estatistica": navigate("/estatistica")
                break
            default:
                break;
        }

        if (isMobile)
            setLeftVisible(false)
    }



    function getStatusLoja() {
        if (lojaAberta === true) {
            return <Space direction='horizontal'>
                <Avatar size={15} style={{ backgroundColor: 'green' }}>

                </Avatar>
                Loja aberta
            </Space>
        } else if (lojaAberta === false) {
            return <Space direction='horizontal'>
                <Avatar size={15} style={{ backgroundColor: 'red' }}>

                </Avatar>
                Loja fechada
            </Space>
        } else {
            return <Space direction='horizontal'>
                <Avatar size={15} style={{ backgroundColor: 'orange' }}>

                </Avatar>
                Carregando...
            </Space>
        }
    }

    function renderSider() {
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 5

            }}>
                <Tag style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 15,
                    paddingBottom: 3
                }}>
                    {getStatusLoja()}
                </Tag>
            </div>

            <Menu
                onClick={(value) => { navigateURL(value.key) }}
                defaultSelectedKeys={['sub1']}
                defaultOpenKeys={['sub1']}
                mode="inline"
                items={items}
            />

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
                maxWidth: 200
            }}>
                <Space size="small" direction='horizontal' style={{ cursor: 'pointer' }}
                    onClick={() => {
                        // setLeftVisible(false)
                        navigate('/myapp')
                        if (isMobile)
                            setLeftVisible(false)
                    }}>
                    <QrcodeOutlined style={{ color: 'royalblue' }} />
                    Meu Link e QRCode
                </Space>




                <QRCode size={100}
                    value={removerAcento("https://menuengfood.com.br/"
                        .concat(config?.Estado, '/', config?.Cidade?.toLowerCase(), "/", config?.path))}
                    style={{ marginBottom: 20, marginTop: 20 }}
                />


            </div>


        </div>
    }



    return <Layout style={{ height: '100vh' }} >
        <Header className='plano-de-fundo2' style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        }}>
            <Space direction='horizontal' size={'small'} style={{ marginLeft: -35 }}>
                <MenuOutlined style={{ fontSize: 18, color: 'white', cursor: 'pointer' }}
                    onClick={() => setLeftVisible(!leftVisible)} />
                {isMobile ? <Avatar src={tokenFull?.logo ? tokenFull?.logo : 'A'} size={'default'} />
                    :
                    <strong level={2} style={{ color: '#fff', fontSize: '1.5em' }}>
                        Menuengfood
                    </strong>
                }
            </Space>


            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: -30 }}>
                {
                    tokenFull?.license === "free" && <Button danger type='primary'
                        onClick={() => navigate('/formas-de-pagamento')}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <AlertFilled style={{ color: 'gold' }} />
                            <span style={{ color: 'white', marginLeft: 10 }}>Ativar pagamento online</span>
                        </div>
                    </Button>
                }

                {
                    <Popover placement="bottomRight" content={contentContext} trigger="click"
                        open={open}
                        onOpenChange={() => setOpen(!open)}>
                        <div style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: 0
                        }}>
                            {!isMobile && <Avatar src={tokenFull?.logo ? tokenFull?.logo : 'A'} size={'default'} />}
                            <div style={{
                                display: 'flex', flexDirection: 'column',
                                height: 60, padding: 0, margin: 0, marginTop: -20,
                                marginLeft: 5
                            }}>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {!isMobile && <div style={{ height: 20, margin: 0, padding: 0, fontSize: '0.75em', color: 'white' }}>
                                        {!isMobile && tokenFull?.fantasia}
                                    </div>}
                                    <div style={{ height: 20, margin: 0, padding: 0, fontSize: '0.7em', color: 'white' }}>
                                        {tokenFull?.email}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Popover>
                }
            </div>

        </Header>
        <Layout hasSider>
            {(leftVisible && !isMobile) && <Sider theme='light' width={230}
                style={{
                    overflow: 'auto',
                    left: 0,
                    top: 0,
                    bottom: 0
                }}>
                {renderSider()}
            </Sider>
            }

            {(isMobile) && <Drawer
                placement='left'
                open={leftVisible}
                destroyOnClose={true}
                onClose={() => setLeftVisible(false)}
                width={'85%'}
                style={{
                    overflow: 'auto',
                    left: 0,
                    top: 0,
                    bottom: 0
                }}>
                {renderSider()}
            </Drawer>
            }

            <Content style={{
                overflow: 'auto',
                left: 0,
                top: 0,
                bottom: 0,
                padding: 0

            }}>

                {canSound === undefined && <Alert
                    style={{ margin: 3 }}
                    type='warning'
                    showIcon
                    description={"Permitir que esse app emita alerta sonoro ao chegar novos pedidos?"}
                    action={
                        <Space direction='horizontal' size={'small'}>
                            <Button size="small" type="primary"
                                onClick={() => {
                                    setCanSound(true)
                                    play()
                                }}>
                                Sim
                            </Button>
                            <Button size="small" type="primary" danger
                                onClick={() => {
                                    setCanSound(false)
                                }}>
                                Não
                            </Button>
                        </Space>
                    }
                />
                }


                <Outlet />
            </Content>

        </Layout>



    </Layout >
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = dispatch => ({
    saveToken: (token) => dispatch(ActionToken.save_token(token)),
    saveTokenFull: (token) => dispatch(ActionToken.save_token_full(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPortal)



import { combineReducers } from 'redux'


import userReducer from './user.reducer'
import adminReducer from './admin.reducer'
import tokenReducer from './token.reducer'
import pedidosReducer from './pedidos.reducer'

const rootReducer = combineReducers({
    userReducer,   
    adminReducer,
    tokenReducer,
    pedidosReducer
})

export default rootReducer;
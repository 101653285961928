import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../admin.css'
import { api } from '../../services/api.service'
import { setNotification, getPermissoes, GERENTE } from '../../services/dal.service'
import { Button, Card, Col, Row, Switch, Input, InputNumber, App } from 'antd'
import ilustracao from '../../img/ilustracoes/undraw_winners_ao2o.png'
import { isBrowser, isMobile } from "react-device-detect";
import { useNavigate } from 'react-router-dom'


export const Fidelidade = (props) => {

    const navigate = useNavigate()
    const { token, tokenFull } = props
    const [config, setConfig] = useState({})
    const [loading, setLoading] = useState(true)
    const permissions = getPermissoes(tokenFull)
    const { modal } = App.useApp()

    useEffect(() => {
        load()
    }, [])

    async function load() {
        try {

            var response = await api.get('/api/v2.0/fidelidade', {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                setConfig(response.data)
                setLoading(false)
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            navigate('/login')
        }

    }

    async function update() {
        try {
            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }
            var response = await api.put('/api/v2.0/fidelidade', config, {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                setNotification('Programa de fidelidade atualizado!', 'success')
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }

    }

    return isBrowser ? renderDeskTop() : renderMobile()



    function renderDeskTop() {
        return <div className="app-window">
            {
                renderContextArea()
            }
        </div>
    }

    function renderMobile() {
        return <div style={{ backgroundColor: 'white' }}>

            {
                renderContextArea()
            }
        </div>
    }


    function renderContextArea() {
        return <div className="contentarea">
            <Card title={null} style={{ marginBottom: 15 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img style={{ marginRight: 40 }} src={ilustracao} alt="img" height={70} width={90} />
                    <div>
                        <h3>Programa de Fidelidade.</h3>
                        <p>Fidelize seus clientes com programa de pontuação e aumente suas vendas.</p>
                    </div>

                </div>
            </Card>

            <Card title={"Regulamento"} style={{ marginBottom: 15 }}>

                <p>
                    Ao habilitar o programa de fidelidade sempre que o seu cliente efetuar um pedido o total de produtos será convertido para pontos para seu programa de fidelidade.
                </p>
                <p>
                    Quando o usuário atingir a pontuação de <strong>{config.pontos}</strong> pontos, ele receberá <strong>{config.desconto} </strong>
                    reais em desconto no próximo pedido a ser realizado.
                </p>
                <p>
                    <b>Convite a amigos</b>
                    <br />Receber pontos por indicações bem sucedidas.
                    <br />

                    Ao indicar um amigo e este acessar site de pedidos ou instalar o app e efetuar pedidos quem indicou recebe
                    <strong> {config.porInvite} </strong>% do pedido em pontos
                </p>

            </Card>


            <Card title={"O Programa"} style={{ marginBottom: 15 }}>
                <Row gutter={[8, 8]} style={{ alignItems: 'center' }}>
                    <Col span={isMobile ? 12 : 4}>Habilitar Programa</Col>
                    <Col span={isMobile ? 12 : 20}>
                        <Switch checkedChildren="Sim" unCheckedChildren="Não"
                            disabled={!permissions?.includes(GERENTE)}
                            checked={config.ativo === 1 ? true : false}
                            onChange={(value) => {
                                setConfig({ ...config, ativo: value === true ? 1 : 0 })

                            }} />
                    </Col>

                    <Col span={24}>{"Nome do Programa "}
                    <Input value={config.nome}
                            disabled={!permissions?.includes(GERENTE)}
                            style={{ width: 300 }}
                            onChange={(e) => setConfig({ ...config, nome: e.target.value })} />
                    </Col>
                   

                    <Col span={24}><b>Pontuações</b></Col>

                    <Col span={24}>
                        {"Cliente ganha R$ "}
                        <InputNumber style={{ width: 70 }} min={0} value={config.desconto}
                            disabled={!permissions?.includes(GERENTE)}
                            onChange={value => {
                                setConfig({ ...config, desconto: value })
                            }} />
                        {' em desconto'}
                    </Col>


                    <Col span={24}>
                        {"Quando atingir "}
                        <InputNumber style={{ width: 80 }} min={0} value={config.pontos}
                            disabled={!permissions?.includes(GERENTE)}
                            onChange={value => {
                                setConfig({ ...config, pontos: value })
                            }} />
                        {' pontos'}
                    </Col>


                    <Col span={24}>{"Convide amigos e ganhe "}
                        <InputNumber style={{ width: 80 }} min={0} value={config.porInvite}
                            disabled={!permissions?.includes(GERENTE)}
                            onChange={value => {
                                setConfig({ ...config, porInvite: value })
                            }} />
                        {' % dos pontos sobre o pedido do amigo.'}
                    </Col>


                    <Col span={24}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div></div>
                            <div>
                                <Button
                                    style={{ marginRight: 20 }}
                                    type={'primary'}
                                    danger
                                    onClick={() => load()}>
                                    Cancelar
                                </Button>

                                <Button
                                    style={{ minWidth: 100 }}
                                    type={'primary'}
                                    disabled={loading || !permissions?.includes(GERENTE)}
                                    onClick={() => update()}>
                                    Salvar
                                </Button>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Card>
        </div>

    }

}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Fidelidade)

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../admin.css'
import { api } from '../../services/api.service'
import { setNotification } from '../../services/dal.service'
import { Table, Button, Input, InputNumber, Space, Modal, Tag, Switch, Row, Col } from 'antd'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { moneyMask } from '../../services/util'
import { isBrowser, isMobile } from "react-device-detect";
import {valorMonetarioOnChange} from '../../services/util'

const { Column } = Table

export const Bairros = (props) => {


    const { token } = props
    const [bairros, setBairros] = useState([])
    const [bairro, setBairro] = useState({})
    const [loading, setLoading] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        load()
    }, [])

    async function load() {
        try {
            setLoading(true)
            var response = await api.get('/api/v2.0/bairros', {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                //  console.log(response.data)
                setBairros(response.data)
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            setNotification(error.message, 'error')
            props.history.push('/login')
        }
        setLoading(false)
    }


    async function save() {
        try {

            setLoading(true)
            var response = await api.post('/api/v2.0/bairros', bairro, {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                setIsModalVisible(false)
                setNotification('Bairro salvo com sucesso!', 'success')
                load()
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }


    async function update() {
        try {

            setLoading(true)
            var response = await api.put('/api/v2.0/bairros', bairros, {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                setNotification('Bairros atualizados com sucesso!', 'success')
                load()
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }

    async function excluirBairro(record) {
        try {

            setLoading(true)
            var response = await api.delete('/api/v2.0/bairros', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    idbairros: record.idbairros
                }
            })
            if (response.status === 200) {
                setNotification('Bairro excluido com sucesso!', 'success')
                load()
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }

    return isBrowser ? renderDesktop() : renderMobile()

    function renderDesktop() {
        return <div className="app-window">
            {renderContentArea()}
        </div>
    }

    function renderMobile() {
        return <div style={{ backgroundColor: 'white' }}>          
            {
                renderContentArea()
            }
        </div>
    }

    function renderContentArea() {
        return <div className="contentarea">           
            <Table dataSource={bairros} size="small" loading={loading}
                rowKey={'idbairros'}
                footer={() => <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Button onClick={() => {
                            setBairro({
                                descricao: '',
                                atendido: 1,
                                valor: 0,
                            })
                            setIsModalVisible(true)
                        }}>Novo Bairro
                        </Button>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', marginLeft:5}}>
                        <Button type="primary" danger style={{ marginRight: isMobile ? 5 : 15 }}
                            onClick={() => load()}>Cancelar
                        </Button>
                        <Button type="primary" style={{ minWidth: 90 }}
                            onClick={() => update()}>Salvar
                        </Button>
                    </div>
                </div>}>
                <Column title="Disponibilidade" dataIndex="atendido" key="atendido"
                    width={130}
                    render={(text, record, index) => {
                        return <Switch checkedChildren="Atendido" unCheckedChildren="Indisponível"
                            checked={text === 1 ? true : false}
                            onChange={(value) => {
                                var newdata = Object.values(bairros)
                                newdata[index].atendido = value === true ? 1 : 0
                                setBairros(newdata)
                            }} />



                    }} />

                <Column title="Bairro" dataIndex="descricao" key="descricao"
                    render={(text, record, index) => {
                        return <div>
                            <div>
                                {text}
                            </div>
                            {isBrowser === false && <div>
                                <InputNumber size="small"
                                    step='0.01'
                                    decimalSeparator={','}
                                    min={0}
                                    stringMode={true}
                                    value={moneyMask(record.valor)}
                                    onChange={(value) => {
                                        var newbairros = Object.values(bairros)
                                        newbairros[index].valor = value
                                        setBairros(newbairros)
                                    }
                                    }
                                />
                            </div>
                            }
                        </div>
                    }}
                />

                {isBrowser && <Column title="Taxa R$" dataIndex="valor" key="valor"
                    width={100}
                    render={(text, record, index) => {
                        return <InputNumber size="small"
                            step='0.01'
                            decimalSeparator={','}
                            min={0}
                            stringMode={true}
                            value={moneyMask(text)}
                            onChange={(value) => {
                                var newbairros = Object.values(bairros)
                                newbairros[index].valor = value
                                setBairros(newbairros)
                            }
                            }
                        />
                    }}
                />
                }

                <Column title="" dataIndex="edit" key="edit" width={40}
                    render={(text, record, index2) => {
                        return <Space size="middle">
                            <Button type="link" onClick={() => {
                                setBairro(record)
                                setIsModalVisible(true)
                            }}>Editar</Button>
                        </Space>
                    }}
                />


                <Column title="" dataIndex="edit" key="edit" width={40}
                    render={(text, record, index2) => {
                        return <Space size="middle" onClick={() => {
                            Modal.confirm({
                                zIndex: 99999999,
                                title: 'Atenção!',
                                icon: <QuestionCircleOutlined size='small' />,
                                content: 'Deseja realmente excluir o bairro '.concat(record.descricao, '?'),
                                okText: 'Confirmar',
                                cancelText: 'Cancelar',
                                onOk: () => {
                                    excluirBairro(record)
                                },
                                onCancel: () => {
                                    // setNotification('Cancelado pelo usuário!'.concat(error2.message), 'warning')
                                    //  console.log('cancel')
                                }
                            });
                        }}>
                            <DeleteOutlined fontSize="small"
                                className="category-content-button-icon"
                                style={{ cursor: "pointer", 
                                marginLeft: 0, 
                                color: 'gray', 
                                fontSize:20 }} />
                        </Space>
                    }}
                />

            </Table>

            <Modal title={bairro.idbairros ? 'Alterar Bairro' : 'Adicionar Bairro'} visible={isModalVisible}
                onOk={() => { save() }}
                okButtonProps={{ disabled: !bairro.descricao }}
                onCancel={() => setIsModalVisible(false)}>

                <Row>

                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={6}>Disponibilidade</Col>
                    <Col span={18}>
                        <Switch checkedChildren="Atendido" unCheckedChildren="Indisponível"
                            checked={bairro.atendido === 1 ? true : false}
                            onChange={(value) => {
                                setBairro({ ...bairro, atendido: value === true ? 1 : 0 })

                            }} />
                    </Col>

                    <Col span={6}>Nome</Col>
                    <Col span={18}><Input value={bairro.descricao} onChange={(e) => {
                        setBairro({ ...bairro, descricao: e.target.value })
                    }} /></Col>

                    <Col span={6}>Taxa (R$)</Col>
                    <Col span={18}>
                        <InputNumber
                            step='0.01'
                            decimalSeparator={','}
                            min={0}
                            stringMode={true}
                            value={moneyMask(bairro.valor)}
                            onChange={(value) => {
                                setBairro({ ...bairro, valor: value })
                            }
                            } /></Col>
                </Row>
                <Row>

                </Row>

            </Modal>

        </div>
    }
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Bairros)

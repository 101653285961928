import { Action_Pedidos_Types } from '../actions/pedidos.action'

const initialState = {
    pedidos: [],
    status:0,
    executando:false
}

const pedidosReducer = (state = initialState, action) => {

    switch (action.type) {

        case Action_Pedidos_Types.GESTOR_PEDIDOS:
            return {
                ...state,
                pedidos: action.pedidos
            }

            case Action_Pedidos_Types.LOJA_STATUS:
            return {
                ...state,
                status: action.status
            }

            case Action_Pedidos_Types.GESTOR_EXECUTANDO:
                return {
                    ...state,
                    executando: action.executando
                }

        default:
            return state;
    }
}


export default pedidosReducer;
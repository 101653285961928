export const Action_Token_Types ={
    SAVE_TOKEN:"SAVE_TOKEN",
    SAVE_TOKEN_FULL:"SAVE_TOKEN_FULL"
}

export const save_token = (token) => ({
    type: Action_Token_Types.SAVE_TOKEN,
    token
})

export const save_token_full = (token) => ({
    type: Action_Token_Types.SAVE_TOKEN_FULL,
    token
})
import React, { useEffect, useState } from 'react'
import { Table, Button } from 'antd';
import { connect } from 'react-redux'
//import 'antd/dist/antd.css';
import './reorderGeneric.css'
import { MenuOutlined } from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import Divider from '@material-ui/core/Divider';

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999', marginRight: 10 }} />);


export const ReorderGeneric = (props) => {

    const [dataSource, setDataSource] = useState(props.lista)
    const [produtos, setProdutos] = useState([])
    const [grupos, setGrupos] = useState([])
    const [itens, setItens] = useState([])
    const [pattern, setPattern] = useState(1)

    const SortableContainer = sortableContainer(props => <tbody {...props} />);
    const SortableItem = sortableElement(props => <tr {...props} />);

    const columns = [
        {
            title: 'Categoria',
            dataIndex: 'catDescricao',
            className: 'drag-visible',
            render: (text, record) => {
                return <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    color: record.selected === 1 ? 'blue' : 'gray',
                    fontWeight: record.selected === 1 ? 'bold' : 'normal',
                    fontSize: 11
                }}>
                    <DragHandle /> {text}
                </div>
            }
        }
    ];

    const columnsprod = [
        {
            title: pattern === 0 ? 'Sabores' : 'Produtos',
            dataIndex: 'NomeReduzido',
            className: 'drag-visible',
            render: (text, record) => {
                return <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    color: record.selected === 1 ? 'blue' : 'gray',
                    fontWeight: record.selected === 1 ? 'bold' : 'normal',
                    fontSize: 11

                }}>
                    <DragHandle /> {text}
                </div>
            }
        }
    ];


    const columnsgrup = [
        {
            title: pattern === 0 ? 'Tamanhos' : 'Grupos',
            dataIndex: 'descricao',
            className: 'drag-visible',
            render: (text, record) => {
                return <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    color: record.selected === 1 ? 'blue' : 'gray',
                    fontWeight: record.selected === 1 ? 'bold' : 'normal',
                    fontSize: 11
                }}>
                    <DragHandle /> {text}
                </div>
            }
        }
    ];


    const columnsitens = [
        {
            title: pattern === 0 ? ' ' : 'Itens',
            dataIndex: 'nome',
            className: 'drag-visible',
            render: (text, record) => {
                return <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    fontSize: 11

                }}>
                    <DragHandle /> {text}
                </div>
            }
        }
    ];




    useEffect(() => {
        var dt = dataSource
        dt.map((item, index) => {
            item.index = index
            item.ordem = index
        })
    }, [])


    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            newData.map((item, index) => {
                item.ordem = index
                item.index = index
            })
            setDataSource(newData)
        }
    };


    const onSortEndprod = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(produtos), oldIndex, newIndex).filter(el => !!el);
            newData.map((item, index) => {
                item.ordem = index
                item.index = index
            })
            /*
            var dt = newData
            dt.map((item, index) => {
                item.index = index
                item.ordem = index
            })
*/
            setProdutos(newData)

            var i = dataSource.findIndex(value => value.selected === 1)
            if (i >= 0) {
                var newsource = Object.values(Object.assign({}, dataSource))
                newsource[i].produtos = newData
                //  console.log(newsource)
                setDataSource(newsource)
            }
        }
    };


    const onSortEndgrup = ({ oldIndex, newIndex }) => {
        //  console.log('grupos', grupos)
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(grupos), oldIndex, newIndex).filter(el => !!el);
            newData.map((item, index) => {
                item.ordem = index
                item.index = index
            })
            //  console.log('newdata', newData)
            setGrupos(newData)

            //produtos

            var c = dataSource.findIndex(value => value.selected === 1)
            if (c >= 0) {
                if (dataSource[c].ispattern === 1) {
                    var i = produtos.findIndex(value => value.selected === 1)
                    if (i >= 0) {
                        var newprodutos = Object.values(Object.assign({}, produtos))
                        newprodutos[i].grupos = newData

                        var k = dataSource.findIndex(value => value.selected === 1)
                        if (k >= 0) {
                            var newsource = Object.values(Object.assign({}, dataSource))
                            newsource[k].produtos = newprodutos
                            setDataSource(newsource)
                        }
                    }
                } else {

                    // var newprodutos = Object.values(Object.assign({}, produtos))
                    // newprodutos[i].grupos = newData
                    var k = dataSource.findIndex(value => value.selected === 1)
                    if (k >= 0) {
                        var newsource = Object.values(Object.assign({}, dataSource))
                        newsource[k].tamanhos = newData
                        setDataSource(newsource)
                    }



                }

            }

        }
    };


    const onSortEndItens = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(itens), oldIndex, newIndex).filter(el => !!el);
            newData.map((item, index) => {
                item.ordem = index
                item.index = index
            })

            setItens(newData)

            var j = itens.findIndex(value => value.selected === 1)
            if (j >= 0) {
                var newgrupos = Object.values(Object.assign({}, grupos))
                newgrupos[j].itens = newData

                //produtos
                var i = produtos.findIndex(value => value.selected === 1)
                if (i >= 0) {
                    var newprodutos = Object.values(Object.assign({}, produtos))
                    newprodutos[i].grupos = newData

                    var k = dataSource.findIndex(value => value.selected === 1)
                    if (k >= 0) {
                        var newsource = Object.values(Object.assign({}, dataSource))
                        newsource[k].produtos = newprodutos
                        // console.log(newsource)
                        setDataSource(newsource)
                    }
                }
            }
        }
    };




    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );
    const DraggableContainerprod = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEndprod}
            {...props}
        />
    );

    const DraggableContainergrup = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEndgrup}
            {...props}
        />
    );

    const DraggableContaineritens = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEndItens}
            {...props}
        />
    );


    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    const DraggableBodyRowprod = ({ className, style, ...restProps }) => {
        const index = produtos.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    const DraggableBodyRowgrup = ({ className, style, ...restProps }) => {
        const index = grupos.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    const DraggableBodyRowitens = ({ className, style, ...restProps }) => {
        const index = produtos.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };



    return (
        <div style={{ height: 400, display: 'flex', flexDirection: 'column' }}>
            <p> Para alterar a ordem do item, clique no item desejado, segure e arraste. </p>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Table
                        scroll={{ y: 270 }}
                        size={'small'}
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey="index"
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    var copia = Object.values(Object.assign({}, dataSource))
                                    copia.map(item => (
                                        item.selected = 0
                                    ))
                                    copia[rowIndex].selected = 1
                                    setPattern(copia[rowIndex].ispattern)
                                    setDataSource(copia)
                                    //console.log('patern: ',copia[rowIndex])

                                    var dt = dataSource[rowIndex].produtos
                                    dt.map((item, index) => {
                                        item.index = index
                                        item.ordem = index
                                        item.selected = 0
                                    })

                                    setProdutos(dataSource[rowIndex].produtos)
                                    if (dataSource[rowIndex].ispattern === 0) {
                                        dataSource[rowIndex].tamanhos.map((item, index) => {
                                            item.index = index
                                            item.ordem = index
                                            item.selected = 0
                                        })
                                        /*
                                          dataSource[rowIndex].massas.map((item, index) => {
                                                                                    item.index = index
                                                                                    item.ordem = index
                                                                                    item.selected = 0
                                                                                })
                                                                                */

                                        setGrupos(dataSource[rowIndex].tamanhos)
                                        // setItens(dataSource[rowIndex].massas)
                                        // setGrupos([])
                                        setItens([])
                                    } else {
                                        setGrupos([])
                                        setItens([])
                                    }


                                },
                            };
                        }}

                    />
                    <Divider orientation="vertical" flexItem />
                    <Table
                        scroll={{ y: 270 }}
                        size={'small'}
                        pagination={false}
                        dataSource={produtos}
                        columns={columnsprod}
                        rowKey="index"
                        components={{
                            body: {
                                wrapper: DraggableContainerprod,
                                row: DraggableBodyRowprod,
                            },
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    var copia = Object.values(Object.assign({}, produtos))
                                    copia.map(item => (
                                        item.selected = 0
                                    ))
                                    copia[rowIndex].selected = 1
                                    setProdutos(copia)

                                    var dt = produtos[rowIndex].grupos
                                    dt.map((item, index) => {
                                        item.index = index
                                        item.ordem = index
                                        item.selected = 0
                                    })

                                    if (produtos[rowIndex].ispattern === 0) {

                                    } else {
                                        setGrupos(produtos[rowIndex].grupos)
                                        setItens([])
                                    }

                                }
                            };
                        }}
                    />
                    <Divider orientation="vertical" flexItem />
                    <Table
                        scroll={{ y: 270 }}
                        size={'small'}
                        pagination={false}
                        dataSource={grupos}
                        columns={columnsgrup}
                        rowKey="index"
                        components={{
                            body: {
                                wrapper: DraggableContainergrup,
                                row: DraggableBodyRowgrup,
                            },
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {

                                    var copia = Object.values(Object.assign({}, grupos))
                                    copia.map(item => (
                                        item.selected = 0
                                    ))
                                    copia[rowIndex].selected = 1
                                    setItens(copia)

                                    var dt = grupos[rowIndex].itens
                                    if (dt !== undefined) {
                                        dt.map((item, index) => {
                                            item.index = index
                                            item.ordem = index
                                            item.selected = 0
                                        })

                                        setItens(grupos[rowIndex].itens)
                                    } else {
                                        setItens([])
                                    }

                                }
                            };
                        }}
                    />
                    <Divider orientation="vertical" flexItem />
                    <Table
                        scroll={{ y: 270 }}
                        size={'small'}
                        pagination={false}
                        dataSource={itens}
                        columns={columnsitens}
                        rowKey="index"
                        components={{
                            body: {
                                wrapper: DraggableContaineritens,
                                row: DraggableBodyRowitens,
                            },
                        }}
                    />
                </div>
            </div>

            <Divider />

            <div className="category-footer-order">
                <Button danger onClick={() => {
                    props.showModalOrdenar(false)
                }}>Cancelar</Button>
                <Button style={{ minWidth: 90, marginLeft: 20 }} type="primary"
                    onClick={() => {
                        props.salveCategoryOrder(dataSource)
                    }}>Salvar</Button>
            </div>
        </div>

    );

}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ReorderGeneric)
import {createStore} from 'redux'
import rootReducer from './reducers/rootReducer'
import {encryptTransform} from 'redux-persist-transform-encrypt';
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const encryptor=encryptTransform({
    secretKey: 'KEY2CAMILAKEY2',
    onError: function (error) {
      // Handle the error.
    },
  })

  const persistConfig = {
    key:'portalapp',
    transforms:[encryptor],
    storage,
    blacklist: ['adminReducer', 'pedidosReducer']
    
}


const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer);
const persistor = persistStore(store)

export {store, persistor}

//storeSynchronize(store)
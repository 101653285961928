import React from 'react'
import { connect } from 'react-redux'
import { Col, Row, Button, Space, Avatar, Collapse, Tag, Card } from 'antd'
import './pedido.css'
import {
    CloseOutlined, GlobalOutlined, AndroidOutlined, CalendarOutlined, ClockCircleOutlined,
    UserOutlined, WalletOutlined, EnvironmentOutlined, PrinterOutlined, DollarOutlined,
    CheckOutlined, MoreOutlined, CloseCircleOutlined, ThunderboltOutlined,
    RightOutlined
} from '@ant-design/icons'
import moment from 'moment'
import { Dropdown as DropdownBS } from 'react-bootstrap';

export const Pedido = (props) => {

    const { tokenFull, pedido, setPedido, getStatus, renderDropdownConversar,
        setSelectedCancel,
        aceitarPedido,
        renderStatusOption,
        setPaid,
        setSelectedCozinha,
        setSelected,
        loading } = props

    var tempo = parseFloat(moment(new Date()).diff(moment(pedido?.dataserver), 'minutes'))
    if (tempo > 60)
        tempo = '+ 60 min'
    else
        tempo = tempo + " min"

    let cor = 'red'
    let ispago = "Não pago"
    if (pedido?.payment?.paid === 1) {
        ispago = "Pago"
        cor = "royalblue"
    } else if (pedido?.payment?.paid === 2) {
        ispago = "Pago online"
        cor = 'blue'
    }


    function renderProdutos() {
        return <Row>
            <Col span={24} className={'pedido-fontSize'}>
                <strong> ITENS DO PEDIDO: </strong>
            </Col>
            <Col span={24} className={'pedido-fontSize'}>
                {pedido?.itens?.map((item, index) => {
                    return <Row key={'ped-it-'.concat(index)} style={{ fontWeight: 700 }}>
                        <Col span={4} className={'pedido-fontSize-item'}>
                            {item.Quant} X

                        </Col>
                        <Col span={12} className={'pedido-fontSize-item'}>
                            {item.NomeReduzido}
                        </Col>
                        <Col span={4} className={'pedido-fontSize-item'}>
                            {(parseFloat(item.valorUnitarioFinal)).toFixed(2)}
                        </Col>
                        <Col span={4} style={{ textAlign: 'right', paddingRight: 8 }} className={'pedido-fontSize-item'}>
                            {(parseFloat(item.valorUnitarioFinal) * parseFloat(item.Quant)).toFixed(2)}
                        </Col>
                        {
                            item?.complementos.length > 0 && <Col span={24}>
                                {item?.complementos.map((c, indexc) => {
                                    return <Row style={{
                                        color: 'gray',
                                        marginLeft: 10,
                                        fontWeight: 'normal',

                                    }} key={"comp-it-".concat(indexc)}>
                                        <Col className='pedido-fontSize-subitem' span={4}>{c.Quant} x</Col>
                                        <Col className='pedido-fontSize-subitem' span={20}>{c.NomeReduzido}</Col>
                                    </Row>
                                })
                                }
                            </Col>
                        }

                        {
                            item?.obs && <Col span={24} className='pedido-fontSize-obs'>
                                {"Obs: ".concat(item.obs)}
                            </Col>
                        }

                    </Row>
                })}
                <Col span={24} className={'pedido-fontSize'}>
                    <hr style={{ marginTop: 2, marginBottom: 2 }} />
                </Col>
                <Col span={24} className={'pedido-fontSize'}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <strong>Subtotal</strong>
                        <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                            {(pedido.valor - pedido.taxa + (pedido.cupom !== undefined ? pedido.cupom.valor : 0)
                                + (pedido.fidelidade !== undefined ? pedido.fidelidade.valor : 0)).toFixed(2).toString()}
                        </strong>
                    </div>
                </Col>
                {
                    pedido.cupom && <Col span={24} className={'pedido-fontSize'}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <strong> Cupom </strong>
                            <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                                - {pedido.cupom.valor.toFixed(2)}
                            </strong>
                        </div>
                    </Col>
                }
                {
                    pedido.fidelidade && <Col span={24} className={'pedido-fontSize'}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <strong> {pedido.fidelidade.tag} </strong>
                            <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                                - {pedido.fidelidade.valor.toFixed(2)}
                            </strong>
                        </div>
                    </Col>
                }
                <Col span={24} className={'pedido-fontSize'}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <strong>Taxa delivery</strong>
                        <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                            {pedido?.taxa.toFixed(2)}
                        </strong>
                    </div>
                </Col>
                <Col span={24} className={'pedido-fontSize'}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <strong>Total</strong>
                        <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                            {pedido?.valor.toFixed(2)}
                        </strong>
                    </div>
                    <hr style={{ marginTop: 2, marginBottom: 2 }} />
                </Col>

                <Col span={24} className={'pedido-fontSize'} style={{
                    justifyContent: 'space-between',
                    display: 'flex', alignItems: 'center'
                }}>
                    <div style={{
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        display: 'flex', alignItems: 'center'
                    }}>
                        <WalletOutlined style={{ color: 'royalblue', marginRight: 5 }} />
                        <span style={{color:'royalblue', fontWeight:600}}> {pedido?.payment?.name}</span>
                    </div>
                    <Tag color={cor}>
                        {ispago}
                    </Tag>
                </Col>


                {
                    pedido?.obs && <>
                        <Col span={24} className={'pedido-fontSize'}>
                            <hr style={{ marginTop: 2, marginBottom: 2 }} />
                        </Col>
                        <Col span={24} className='pedido-fontSize-obs'>
                            {"Obs: ".concat(pedido.obs)}
                        </Col>
                    </>

                }


            </Col>
        </Row>
    }





    if (!pedido)
        return 'loading...'


    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
           
            <div style={{ padding: 8 }}>

                <Card>
                    <div>
                        <div className='div-horizontal-between'>
                            <Space size={'small'} direction='horizontal'>
                                {pedido.platform === "web" ?
                                    <GlobalOutlined style={{ color: 'blue', fontSize: 16 }} /> :
                                    <AndroidOutlined style={{ color: 'green', fontSize: 16 }} />}
                                <div style={{ color: 'royalblue', fontWeight: 600 }}>
                                    {pedido.type === 'PICKUP' ? 'Retirar na loja' : "Delivery "}
                                </div>
                            </Space>

                            {getStatus(pedido)}

                        </div>


                        <div className='div-horizontal-between'>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '0.94em' }}>
                                <CalendarOutlined style={{ marginRight: 3 }} />
                                {moment(pedido?.dataserver).format('DD/MM/yyyy HH:mm')}
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <ClockCircleOutlined />
                                <span style={{ marginLeft: 3, color: tempo === '+ 60 min' ? 'red' : 'darkgrey' }}> {tempo} </span>
                            </div>
                        </div>
                    </div>
                </Card>

                <Card style={{ marginTop: 10 }}>
                    <div className='div-horizontal-between'>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <UserOutlined style={{ marginRight: 5 }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontWeight: 600, fontSize: '0.9em' }}>{pedido?.user?.nome}</span>
                                <span style={{ color: 'gray', fontSize: '0.80em', marginLeft: 4 }}>
                                    {pedido?.user?.telefone}
                                </span>
                            </div>

                        </div>
                        <div style={{ fontWeight: 600, fontSize: '0.9em' }}>{
                            (pedido?.status !== 2 && pedido?.status !== 3 && pedido?.status !== 6) &&
                            <DropdownBS size="sm" onClick={(event) => { }}>
                                <DropdownBS.Toggle

                                    size="sm"
                                    style={{
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        alignItems: 'center',
                                        display: 'flex',
                                        color: 'green',
                                        backgroundColor: 'white',
                                        borderColor: 'green'
                                    }}
                                    variant="secondary"
                                    id="dropdown-basic">
                                    <img src={require('../../img/whatshap.png')} alt='whats' style={{ height: 14 }} />
                                    <span style={{ marginRight: 8, marginLeft: 5, fontSize: '0.8em' }}> {"Conversar"} </span>
                                </DropdownBS.Toggle>
                                <DropdownBS.Menu style={{ fontSize: '0.95em' }}>
                                    {renderDropdownConversar(pedido)}
                                </DropdownBS.Menu>
                            </DropdownBS>
                        }
                        </div>


                    </div>

                    {pedido.type === "DELIVERY" && <div style={{ fontSize: '0.9em' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <EnvironmentOutlined style={{ marginRight: 5, color: 'black' }} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
                                <span style={{ fontWeight: 600, fontSize: '0.9em' }}>
                                    {pedido.address?.logradouro?.concat(' ', pedido.address?.numero,
                                        ', ', pedido.address?.complemento, ', ')}
                                    {pedido.address?.bairro} - {pedido.address?.localidade}
                                </span>
                                <div>
                                    {" Referência: "}
                                    {pedido.address?.referencia}
                                </div>
                            </div>

                        </div>
                    </div>
                    }

                </Card>

                <Card style={{ marginTop: 10 }}>
                    <div style={{ overflow: 'auto' }}>
                        {renderProdutos()}
                    </div>
                </Card>
            </div>
        </div>
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    marginBottom: 3
                }}>
                {
                    /*
                    pedido.status === 0 ?
                        <Space direction='horizontal'>
                            <Button type='primary'
                                block
                                danger
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 'auto',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 4,
                                    paddingLeft: 12,
                                    paddingRight: 12
                                }}
                                onClick={(event) => {
                                    setSelectedCancel(pedido)
                                }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <CloseCircleOutlined />
                                    <MoreOutlined style={{ position: 'absolute', top: 2, right: 2 }} />
                                </div>
                                <div style={{ fontSize: '0.8em' }}>
                                    Rejeitar
                                </div>
                            </Button>
                            <Button type='primary'
                                loading={loading}
                                block
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 'auto',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 4,
                                    paddingLeft: 16,
                                    paddingRight: 16
                                }} onClick={(event) => {
                                    aceitarPedido(pedido)
                                }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <CheckOutlined />
                                    <MoreOutlined style={{ position: 'absolute', top: 2, right: 2 }} />
                                </div>
                                <div style={{ fontSize: '0.8em' }}>
                                    Aceitar
                                </div>
                            </Button>
                        </Space>
                        :
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <DropdownBS size="sm" onClick={(event) => { }}>
                                <DropdownBS.Toggle
                                    disabled={(pedido?.status === 2 || pedido?.status === 3 || pedido?.status === 6 || loading)}
                                    size="sm"
                                    style={{
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        alignItems: 'center',
                                        display: 'flex',
                                        color: 'white',
                                        backgroundColor: 'royalblue',
                                        borderColor: 'royalblue',
                                    }}
                                    variant="secondary"
                                    id="dropdown-basic">
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <ThunderboltOutlined />
                                        <span style={{ marginRight: 16, marginLeft: 5, fontSize: '0.8em', paddingLeft: 8 }}> {"status  "} </span>
                                    </div>

                                </DropdownBS.Toggle>
                                <DropdownBS.Menu style={{ fontSize: '0.95em' }}>
                                    {renderStatusOption(pedido)}
                                </DropdownBS.Menu>
                            </DropdownBS>


                            <DropdownBS size="sm" onClick={(event) => { }} style={{ marginLeft: 10, marginRight: 10 }}>
                                <DropdownBS.Toggle
                                    disabled={(pedido?.status === 2 || pedido?.status === 3 || pedido?.status === 6)}
                                    size="sm"
                                    style={{
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        paddingLeft: 0,
                                        paddingRight: 3,
                                        alignItems: 'center',
                                        display: 'flex',
                                        color: 'white',
                                        backgroundColor: 'orange',
                                        borderColor: 'orange'
                                    }}
                                    variant="secondary"
                                    id="dropdown-basic">
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <DollarOutlined />
                                        <span style={{ marginRight: 8, marginLeft: 5, fontSize: '0.8em' }}> {"Pagamento"} </span>
                                    </div>

                                </DropdownBS.Toggle>
                                <DropdownBS.Menu style={{ fontSize: '0.95em' }}>
                                    {
                                        pedido?.payment?.paid === 1 && <DropdownBS.Item href="#"
                                            onClick={() => {
                                                setPaid({ ...pedido }, 0)
                                            }}>Não pago</DropdownBS.Item>
                                    }
                                    {
                                        pedido?.payment?.paid === 0 && <DropdownBS.Item href="#"
                                            onClick={() => {
                                                setPaid({ ...pedido }, 1)
                                            }}>Pago</DropdownBS.Item>
                                    }

                                </DropdownBS.Menu>
                            </DropdownBS>


                            <DropdownBS size="sm" onClick={(event) => { }}>
                                <DropdownBS.Toggle
                                    disabled={(pedido?.status === 2 || pedido?.status === 3 || pedido?.status === 6)}
                                    size="sm"
                                    style={{
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        paddingLeft: 0,
                                        paddingRight: 3,
                                        alignItems: 'center',
                                        display: 'flex',
                                        color: 'white',
                                        backgroundColor: 'green',
                                        borderColor: 'green'
                                    }}
                                    variant="secondary"
                                    id="dropdown-basic">
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <PrinterOutlined />
                                        <span style={{ marginRight: 8, marginLeft: 5, fontSize: '0.8em', paddingLeft: 15 }}> {"Imprimir"} </span>
                                    </div>
                                </DropdownBS.Toggle>
                                <DropdownBS.Menu style={{ fontSize: '0.95em' }}>
                                    <DropdownBS.Item href="#"
                                        onClick={() => {
                                            setSelectedCozinha(pedido)
                                        }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <PrinterOutlined style={{ marginRight: 10 }} /> Cozinha
                                        </div>
                                    </DropdownBS.Item>
                                    <DropdownBS.Item href="#"
                                        onClick={() => {
                                            setSelected(pedido)
                                        }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <PrinterOutlined style={{ marginRight: 10 }} /> Recibo
                                        </div>
                                    </DropdownBS.Item>
                                </DropdownBS.Menu>
                            </DropdownBS>

                        </div>
                        */
                }

            </div>
        </div>
    </div>

}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Pedido)

import { atom, RecoilRoot, useRecoilState } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const counterState = atom({
    key: 'count',
    default: 0,
    effects_UNSTABLE: [persistAtom],
  })


  export const pedidosState = atom({
    key: 'pedidosState',
    default: [],
  //  effects_UNSTABLE: [persistAtom],
  })

  export const statusState = atom({
    key: 'statusState',
    default: false,
    effects_UNSTABLE: [persistAtom],
  })


  export const loadPedidoState = atom({
    key:'loadPedidoState',
    default:0,
   // effects 
  })


  export const canSoundState = atom({
    key:'canSound',
    default:undefined,
   // effects 
  })
import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { api } from '../../services/api.service'
import * as ActionToken from '../../store/actions/token.action'
import { Button as ButtonAntd, Space, App } from 'antd'
import './loginApp.css'
import { LoadingOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'





export const LoginApp = (props) => {

    const navigate = useNavigate()
    const { notification } = App.useApp()
    const { token, saveToken, saveTokenFull } = props


    useEffect(() => {
       login()
    }, [])


    async function login() {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const lic_id = urlParams.get('lic_id');
            const response = await api.get('/api/v2.0/usuarios/login/engfood?'.concat("token=", token, "&lic_id=",  lic_id ));
            saveTokenFull(response.data)
            saveToken(response.data.token)
            navigate('/')
          
        } catch (error) {
           
            if (error.message === 'Request failed with status code 401') {
                notification.error({
                    message: "Atenção",
                    description: "Token e/ou senha inválido!"
                })
            } else {
                notification.error({
                    message: "Atenção",
                    description: error.message
                })
            }
          //  navigate('/login')
        }
    }


    return <div className='plano-de-fundo'>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            justifyContent: 'center',
            width: 433,
            alignItems: 'center'
        }}>
            <strong style={{ fontSize: '1.7em', color: 'white' }}>Portal Menuengfood</strong>
            <div style={{ color: 'whitesmoke', marginTop: 30, marginBottom: 70 }}>
                <LoadingOutlined style={{ fontSize: 20, marginRight: 5 }} />
                Validando acesso...</div>
        </div>
    </div>


}


const mapStateToProps = (state) => ({
    token: state.tokenReducer.token
})

const mapDispatchToProps = dispatch => ({
    saveToken: (token) => dispatch(ActionToken.save_token(token)),
    saveTokenFull: (token) => dispatch(ActionToken.save_token_full(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginApp)
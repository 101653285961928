import React, { } from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core';
import { Switch } from 'antd';
//import 'antd/dist/antd.css';
import { api } from '../../../services/api.service'
import { setNotification } from '../../../services/dal.service'
import { InputNumber, Input, Modal } from 'antd';
import {QuestionCircleOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Space } from 'antd';

import * as AdminAction from '../../../store/actions/admin.action'


export const Bordas = (props) => {

    const { token, categoria, setCategoria } = props
    //const [erro, setErro] = useState(false)

    const columns = [
        {
            title: 'Borda',
            dataIndex: 'nome',
            key: 'idapppizzasbordas',
            render: (text, record) => (
                <Input value={text} maxLength={50} onChange={(e) => {
                    record.nome = e.target.value
                    var bordas = Object.values(Object.assign({}, categoria.bordas))
                    var index = bordas.findIndex(a => a.idapppizzasbordas === record.idapppizzasbordas)
                    if (index >= 0) {
                        bordas[index] = record
                        setCategoria({ ...categoria, bordas: bordas })
                    }
                }} />
            ),
        },
        {
            title: 'Preço',
            dataIndex: 'valor',
            key: 'valor',
            render: (text, record) => (
                <InputNumber min={0} defaultValue={0} value={text}
                    decimalSeparator=","
                    precision={2}
                    onChange={(e) => {
                        record.valor = e
                        var bordas = Object.values(Object.assign({}, categoria.bordas))
                        var index = bordas.findIndex(a => a.idapppizzasbordas === record.idapppizzasbordas)
                        if (index >= 0) {
                            bordas[index] = record
                            setCategoria({ ...categoria, bordas: bordas })
                        }
                    }} />
            ),
        },
        {
            title: 'Disponibilidade',
            dataIndex: 'disponivel',
            key: 'disponivel',
            render: (text, record) => (
                <Switch
                    style={{ marginLeft: '20px' }}
                    checkedChildren={'Ativado'}
                    unCheckedChildren={'Pausado'}
                    checked={text}
                    onChange={(e) => {
                        record.disponivel = e === true ? 1 : 0
                        var bordas = Object.values(Object.assign({}, categoria.bordas))
                        var index = bordas.findIndex(a => a.idapppizzasbordas === record.idapppizzasbordas)
                        if (index >= 0) {
                            bordas[index] = record
                            setCategoria({ ...categoria, bordas: bordas })
                        }
                    }} />
            ),
        },
        {
            title: 'Cód. PDV',
            dataIndex: 'produtoid',
            key: 'produtoid',
            render: (text, record) => (
                <InputNumber min={0} defaultValue={0} value={text} onChange={(e) => {
                    record.produtoid = e
                    var bordas = Object.values(Object.assign({}, categoria.bordas))
                    var index = bordas.findIndex(a => a.idapppizzasbordas === record.idapppizzasbordas)
                    if (index >= 0) {
                        bordas[index] = record
                        setCategoria({ ...categoria, bordas: bordas })
                    }
                }} />
            ),
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle" onClick={() => {
                    Modal.confirm({
                        zIndex: 99999999,
                        title: 'Atenção!',
                        icon: <QuestionCircleOutlined size='small' />,
                        content: 'Deseja realmente excluir o tamanho?',
                        okText: 'Confirmar',
                        cancelText: 'Cancelar',
                        onOk: () => {
                            return new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    if (record.idapppizzasbordas > 0) {
                                        try {
                                            const response = await api.delete('/api/v2.0/category/borda?id='.concat(record.idapppizzasbordas), {
                                                headers: {
                                                    'x-access-token': token,
                                                }
                                            })
                                            if (response.status === 200) {
                                                var bordas = Object.values(Object.assign({}, categoria.bordas))
                                                var bordas2 = bordas.filter(el => el.idapppizzasbordas !== record.idapppizzasbordas)
                                                setCategoria({ ...categoria, bordas: bordas2 })
                                                setNotification('Borda excluida com successo!', 'success')
                                            } else {
                                                setNotification('Oops errors!', 'error')
                                                reject()
                                                console.log('REQUEST')
                                            }
                                        } catch (error2) {
                                            console.log('Oops errors!', error2.message)
                                            setNotification('Oops errors!'.concat(error2.message), 'error')
                                            reject()
                                        }

                                    } else {
                                        var bordas = Object.values(Object.assign({}, categoria.bordas))
                                        var bordas2 = bordas.filter(el => el.idapppizzasbordas !== record.idapppizzasbordas)
                                        setCategoria({ ...categoria, bordas: bordas2 })
                                    }
                                    console.log(record.idapppizzasbordas)
                                    resolve()
                                }, 1000);
                            })
                                .catch(() =>{ 
                                    setNotification('Desculpe aconteceu um erro inesperado!', 'error')
                                    console.log('Oops errors!')});
                        },
                        onCancel: () => {
                           // setNotification('Cancelado pelo usuário!'.concat(error2.message), 'warning')
                          //  console.log('cancel')
                        }
                    });
                }}>
                    <DeleteOutlined fontSize="small" style={{ cursor: "pointer", color: 'gray' }} />
                </Space>
            ),
        },
    ];


    return (
        <div>
            Indique aqui quais os tipos de bordas que sua pizza possui:
            <div>
                <Table columns={columns} dataSource={categoria.bordas}
                    size="small"
                    pagination={false} />
            </div>
            <div style={{ marginTop: 10 }}>
                <Button variant="outlined" size="small"
                    onClick={() => {
                        try {
                            var index = categoria.bordas.length === 0 ? 0 :
                                Math.min(...categoria.bordas.map(item => item.idapppizzasbordas));
                            index = (index - 1) < 0 ? (index - 1) : -1 * (index - 1);
                            const newprod = {
                                disponivel: 1,
                                nome: '',
                                sabores: 1,
                                Tipo: 1,
                                produtoid:0,
                                valor:0,
                                appcategoriaid: categoria.idcategorias,
                                idapppizzasbordas: index
                            }

                            var bordas = Object.values(Object.assign({}, categoria.bordas))
                            bordas.push(newprod)
                            setCategoria({ ...categoria, bordas: bordas })
                            // console.log(categoria)
                        } catch (eroror) {
                            console.log('ERROR> ', eroror.message)
                        }

                    }}>
                    + Adicionar borda
                </Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    categoria: state.adminReducer.categoria
})

const mapDispatchToProps = dispatch => ({
    setCategoria: (categoria) => dispatch(AdminAction.save_categoria(categoria))
})

export default connect(mapStateToProps, mapDispatchToProps)(Bordas)

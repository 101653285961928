import { Action_Token_Types } from '../actions/token.action'
const initialState = {
    token: null ,
    tokenFull:null   
}

const userReducer = (state = initialState, action) => {    
    switch (action.type) {
        case Action_Token_Types.SAVE_TOKEN:
            return {
                ...state,
                token: action.token
            }

            case Action_Token_Types.SAVE_TOKEN_FULL:
                //console.log(action.token)
                return {
                    ...state,
                    tokenFull: action.token
                }


        default:
            return state;
    }
}

export default userReducer;
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Card, Button, Input, Radio, App, Table, Badge, Modal, Space } from 'antd'
import Bairros from '../bairros'
import { api } from '../../services/api.service'
import { setNotification, getPermissoes, GERENTE } from '../../services/dal.service'
import { useNavigate } from 'react-router-dom'
import { GoogleMap, CircleF, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_KEY } from '../../services/api.service'
import { valorMonetarioOnChange, valorNumericoOnChange } from '../../services/util'
import { TextField, MenuItem } from '@mui/material'
import { DeleteOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'
import ModalAtivar from '../../components/modalAtivar'


export const TaxasEntrega = (props) => {

  const { token, tokenFull } = props
  const [config, setConfig] = useState({})
  const [loading, setLoading] = useState(true)
  const { modal } = App.useApp()
  const [modalVisible, setModalVisisble] = useState(false)
  const [newArea, setNewArea] = useState({
    raio: 1000,
    tax: 2,
    tempo: 45,
    ativo: 1,
    idappTaxDistance: 0,
    path: tokenFull.path
  })
  const permissions = getPermissoes(tokenFull)
  const [openAtivar, setOpenAtivar] = useState(false)

  const navigate = useNavigate()

  const [libraries] = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_KEY,
    libraries: libraries,
  });

  const containerStyle = {
    width: '100%',
    height: '400px',
    marginLeft: isMobile ? 0 : 50,
    marginTop: isMobile ? 20 : 0
  };

  const cores = ["#00FA9A", "#8B008B", "#4169E1", "#808000", "#6A5ACD", "#4B0082", "#7CFC00", "#000080",
    "#8B4513", "#D8BFD8", "#FFFF00", "#00FFFFF", "#800000", "#7B68EE", "#1C1C1C"]


  useEffect(() => {
    // console.log('tokenFull', tokenFull)
    load()
  }, [])


  async function load() {
    try {
      var response = await api.get('/api/v2.0/taxtypes', {
        headers: {
          'x-access-token': token,
        },
        params: {
          path: tokenFull?.path
        }
      })
      setConfig(response.data)
      setLoading(false)
      //   console.log(response.data)

    } catch (error) {
      //  console.log('error', error.message)
      setNotification(error.message, 'error')
      navigate('/login')
    }

  }


  async function update(value) {
    try {

      if (!permissions?.includes(GERENTE)) {
        modal.warning({
          title: "Acesso negado!",
          content: "Você não possui permissão para executar essa operação! "
        })
        return
      }

      var temp = { ...config }
      temp.taxtype = value
      await api.put('/api/v2.0/taxtypes/set', temp, {
        headers: {
          'x-access-token': token,
        },
      })
      setNotification('Suas definições foram atualizadas!', 'success')
      setConfig(temp)

    } catch (error) {
      // console.log('error', error.message)
      setNotification(error.message, 'error')
    }
  }



  async function save(value, exibirLoad) {
    try {

      if (!permissions?.includes(GERENTE)) {
        modal.warning({
          title: "Acesso negado!",
          content: "Você não possui permissão para executar essa operação! "
        })
        return
      }

      if (!exibirLoad)
        setLoading(true)
      await api.post('/api/v2.0/taxtypes', value, {
        headers: {
          'x-access-token': token,
        },
      })
      setNotification('Suas definições foram atualizadas!', 'success')
      await load()
    } catch (error) {
      // console.log('error', error.message)
      setNotification(error.message, 'error')
    }
    setLoading(false)
    setModalVisisble(false)
  }




  function renderMap() {
    return <div style={{
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: isMobile ? 'normal' : 'space-between'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'right',
        alignItems: 'flex-end'
      }}>
        <Table size='small'
          bordered
          pagination={{
            position: ['none', 'none'],
          }}
          dataSource={config?.raios}
          loading={loading}
          rowKey={(value) => "raios-key-".concat(value.idappTaxDistance)}>
          <Table.Column
            width={250}
            title="Alcance"
            dataIndex="raio"
            key="idappTaxDistance"
            render={(text, record, index) => {
              return <Badge
                color={cores[index]}
                text={"Até ".concat(text / 1000, " KM")}
              />
            }} />
          <Table.Column width={150} title="Valor R$*" dataIndex="tax" key="idappTaxDistance"
            render={(text, record, index) => {
              return <Input
                disabled={!permissions?.includes(GERENTE)}
                style={{ textAlign: 'right' }}
                value={text}
                size='small'
                onChange={(e) => {

                  if (!permissions?.includes(GERENTE)) {
                    modal.warning({
                      title: "Acesso negado!",
                      content: "Você não possui permissão para executar essa operação! "
                    })
                    return
                  }

                  var value = valorMonetarioOnChange(e)
                  var raios = [...config.raios]
                  raios[index].tax = value
                  setConfig({ ...config, raios: raios })
                }}
                onBlur={() => {
                  save(record, true)
                }}
              />
            }} />
          <Table.Column width={120} title="Tempo(min)" dataIndex="tempo" key="idappTaxDistance"
            render={(text, record, index) => {
              return <Input
                style={{ textAlign: 'center' }}
                value={text}
                size='small'
                disabled={!permissions?.includes(GERENTE)}
                onChange={(e) => {

                  if (!permissions?.includes(GERENTE)) {
                    modal.warning({
                      title: "Acesso negado!",
                      content: "Você não possui permissão para executar essa operação! "
                    })
                    return
                  }

                  var value = valorNumericoOnChange(e)
                  var raios = [...config.raios]
                  raios[index].tempo = value
                  setConfig({ ...config, raios: raios })
                }}
                onBlur={() => {
                  save(record, true)
                }}
              />
            }} />

          {permissions?.includes(GERENTE) && <Table.Column title="" dataIndex="ativo" key="idappTaxDistance"
            render={(text, record, index) => {
              return <DeleteOutlined style={{ fontSize: 20, color: 'gray', cursor: 'pointer' }}

                onClick={() => {

                  if (!permissions?.includes(GERENTE)) {
                    modal.warning({
                      title: "Acesso negado!",
                      content: "Você não possui permissão para executar essa operação! "
                    })
                    return
                  }


                  modal.confirm({
                    title: "Atenção!",
                    content: "Deseja realmente excluir a taxa até ".concat(record.raio / 1000, " KM?"),
                    okText: "Sim, quero excluir",
                    cancelText: "Não",
                    onOk: () => {
                      var value = { ...record }
                      value.ativo = 0
                      console.log(value)
                      save(value)
                    },

                  })
                }} />
            }} />
          }
        </Table>


        <Button
          size='small'
          disabled={!permissions?.includes(GERENTE)}
          danger
          type='primary'
          style={{ marginTop: 10 }}
          onClick={() => {

            if (!permissions?.includes(GERENTE)) {
              modal.warning({
                title: "Acesso negado!",
                content: "Você não possui permissão para executar essa operação! "
              })
              return
            }

            if (config?.raios.length > 0) {
              if (config.raios[config?.raios.length - 1].raio < 10000) {
                setNewArea({ ...newArea, raio: config.raios[config?.raios.length - 1].raio + 1000 })
              }
            }
            setModalVisisble(true)
          }}>
          + Nova área
        </Button>

      </div>

      {isLoaded && <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: parseFloat(config?.latitude),
          lng: parseFloat(config?.longitude)
        }}
        options={{
          disableDefaultUI: true,
          clickableIcons: false
        }}
        zoom={13}
        onLoad={() => {

        }}
        onUnmount={() => {

        }}
      >
        { /* Child components, such as markers, info windows, etc. */}
        <>
          {
            /*
            <MarkerF position={{
              lat: parseFloat(config?.latitude),
              lng: parseFloat(config?.longitude)
            }} 
            icon={"https://www.programaengfood.com/shopping.png"}
           />
            */
          }


          {config?.raios.map((item, index) => {
            return <CircleF
              key={"raio-key-".concat(index)}
              radius={item?.raio}
              options={{
                strokeColor: cores[index]
              }}
              center={{
                lat: parseFloat(config?.latitude),
                lng: parseFloat(config?.longitude)
              }} />
          })}
        </>
      </GoogleMap>
      }

    </div>
  }




  return (
    <div>
      <Card
        title="Define como o custo de entrega é calculado"
        bordered={false}
        style={{
          width: '100%',
        }}>
        <Radio.Group
          name="radiogroup"
          value={config?.taxtype}
          onChange={(e) => {

            if (!permissions?.includes(GERENTE)) {
              modal.warning({
                title: "Acesso negado!",
                content: "Você não possui permissão para executar essa operação! "
              })
              return
            }

            if (e.target.value === 1 && tokenFull.license === 'free') {
              setOpenAtivar(true)
              return
            }
            update(e.target.value)
          }}
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
          <Radio value={0} style={{ marginBottom: 5 }}>Estabelecer custo por bairros</Radio>
          <Radio value={1}>Estabelecer valores por intervalo (validado por Google Maps)</Radio>
        </Radio.Group>

        {config?.taxtype === 0 && <Bairros />}

        {config?.taxtype === 1 && renderMap()}


      </Card>


      <Modal
        open={modalVisible}
        onCancel={() => setModalVisisble(false)}
        destroyOnClose={true}
        title="Adicionar nova área"
        onOk={() => save(newArea)}>
        <Space direction='horizontal' size={'large'} style={{ width: '100%', margin: 15 }}>
          <TextField
            value={newArea?.raio}
            onChange={(e) => setNewArea({ ...newArea, raio: e.target.value })}
            style={{ width: 100 }}
            select
            size='small'
            label="Até">
            <MenuItem value={1000}> 1km </MenuItem>
            <MenuItem value={2000}> 2km </MenuItem>
            <MenuItem value={3000}> 3km </MenuItem>
            <MenuItem value={4000}> 4km </MenuItem>
            <MenuItem value={5000}> 5km </MenuItem>
            <MenuItem value={6000}> 6km </MenuItem>
            <MenuItem value={7000}> 7km </MenuItem>
            <MenuItem value={8000}> 8km </MenuItem>
            <MenuItem value={9000}> 9km </MenuItem>
            <MenuItem value={10000}> 10km </MenuItem>
          </TextField>


          <TextField
            size='small'
            style={{ width: 120 }}
            variant='outlined'
            placeholder='Valor'
            label="Valor R$"
            value={newArea?.tax}
            onChange={(e) => {
              var tax = valorMonetarioOnChange(e)
              setNewArea({ ...newArea, tax: tax })
            }} />

          <TextField
            style={{ width: 120 }}
            size='small'
            variant='outlined'
            placeholder='45'
            label="Tempo(minutos)"
            value={newArea?.tempo}
            onChange={(e) => {
              var tempo = valorNumericoOnChange(e)
              setNewArea({ ...newArea, tempo: tempo })
            }} />


        </Space>
      </Modal>


      <Modal
        footer={null}
        open={openAtivar}
        closable={true}
        onCancel={() => setOpenAtivar(false)}>
        <ModalAtivar />
      </Modal>

    </div>
  )
}

const mapStateToProps = (state) => ({
  token: state.tokenReducer.token,
  tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TaxasEntrega)
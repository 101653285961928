import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './grupos.css'
import { Button as ButtonAntd, Col, Row, Collapse, App, Modal, List, Space, Drawer } from 'antd'
import * as AdminAction from '../../../store/actions/admin.action'
import { Typography, Tag, Select } from 'antd'
import { SwipeableDrawer } from '@material-ui/core'
import Grupo from '../grupo'
import { PlusOutlined, CopyOutlined, MenuOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { isBrowser } from "react-device-detect";

const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;


export const Grupos = (props) => {

    const { produto, setProduto } = props
    const [anchorEl, setAnchorEl] = useState(null);
    const [grupo, setGrupo] = useState(null);
    const [drawerGrupo, setDrawerGrupo] = useState(false);
    const [modalExcluir, setModalExcluir] = useState(false)
    const [copyGroupVisible, setCopyGroupVisisble] = useState(false)

    const [categorias, setCategorias] = useState(props.categorias ? props.categorias
        .filter(value => (value.ispattern === 1)) : [])
    const [copyCategoria, setCopyCategoria] = useState({})
    const [copyProduto, setCopyProduto] = useState({})
    const { modal } = App.useApp()

    //const [modal, contextHolder] = Modal.useModal();

    function renderCopiarGrupo() {
        return <div className="group-copy-other">
            <Row>
                <Col span={24}>Selecione a categoria</Col>
                <Col span={24}>
                    <Select style={{ width: "100%" }}
                        value={copyCategoria.idcategorias}
                        onChange={(value, option) => {
                            console.log(option, value)
                            setCopyCategoria(categorias[parseInt(option.key)])
                            setCopyProduto({})
                        }}>
                        {
                            categorias.map((cat, catIndex) => (
                                <Option key={catIndex} value={cat.idcategorias}>{cat.catDescricao}</Option>
                            ))
                        }
                    </Select>
                </Col>
                <Col span={24}>Selecione o produto</Col>
                <Col span={24}>
                    <Select style={{ width: "100%" }} value={copyProduto?.idappprodutos}
                        onChange={(value2, option2) => {
                            console.log(value2)
                            var r = copyCategoria?.produtos?.find(x => x.idappprodutos === value2)
                            // console.log("r", r)
                            if (r)
                                setCopyProduto(r)
                        }}>
                        {
                            copyCategoria?.produtos?.filter(value => value.grupos.length > 0).map((prodItem, prodIndex) => {
                                return <Option key={"prodIndex".concat(prodItem.idappprodutos)}
                                    value={prodItem.idappprodutos}>{prodItem.NomeReduzido}</Option>
                            })
                        }
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col align="middle" span={24} style={{ marginTop: 15, justifyItems: 'rigth' }}>
                    <ButtonAntd style={{ width: 200 }} type="primary"
                        disabled={copyProduto?.grupos?.length === 0 || copyProduto?.grupos === undefined}
                        onClick={() => {
                            var prod = Object.assign({}, copyProduto)
                            prod.grupos.map((grupo, i) => {
                                grupo.idappgrupos = 0
                                grupo.appprodutoid = 0
                                grupo.itens.map((item, k) => {
                                    item.idappgrupoitens = 0
                                    item.idgrupo = 0
                                })
                            })

                            var newproduto = { ...produto }
                            newproduto.grupos.map((g, i) => {
                                g.deleted = true
                                g.itens.map((p, k) => {
                                    p.deleted = true
                                })
                            })

                            prod.grupos.map((g, i) => {
                                newproduto.grupos.push(g)
                            })

                            newproduto.grupos.map((grupo, j) => {
                                if (grupo.idappgrupos === 0) {
                                    var idappgrupos = Math.min.apply(Math, prod.grupos.map(function (o) { return o.idappgrupos; }))
                                    if (idappgrupos === undefined || idappgrupos === null || idappgrupos >= 0)
                                        idappgrupos = -1
                                    else
                                        idappgrupos = idappgrupos - 1

                                    newproduto.grupos[j].idappgrupos = idappgrupos
                                } else {

                                }
                            })
                            setCopyGroupVisisble(false)
                            setProduto(newproduto)

                        }}
                    >Copiar</ButtonAntd>
                </Col>
            </Row>
        </div>
    }



    function addGrupo(g) {
        // console.log('g', g)
        var newproduto = { ...produto }
        if (grupo.idappgrupos === 0) {
            var idappgrupos = Math.min.apply(Math, produto.grupos.map(function (o) { return o.idappgrupos; }))
            if (idappgrupos === undefined || idappgrupos === null || idappgrupos >= 0)
                idappgrupos = -1
            else
                idappgrupos = idappgrupos - 1
            g.idappgrupos = idappgrupos
            newproduto.grupos.push(g)
        } else {
            var index = newproduto.grupos.findIndex(a => a.idappgrupos === g.idappgrupos)
            //  console.log('index', index)
            newproduto.grupos[index] = g
            //  newproduto.grupos[index] = g
        }
        setProduto(newproduto)
    }

    /*
        const handleClickGrupo = (event, grupo) => {
            setGrupo(grupo)
            setAnchorEl(event.currentTarget);
        };
        */
    {
        /*
     const handleCloseGrupo = () => {
            setAnchorEl(null);
        };
        */
    }


    const toggleDrawerGrupo = (open) => (event) => {
        setDrawerGrupo(open);
    };



    function renderGrupos() {
        return <div>
            <div>
                Grupos de complementos que compõem esse produto
            </div>
            <div>
                {
                    produto.grupos && produto.grupos.map((grupo, indexGrupo) => {
                        // console.log('grupo', grupo)
                        return grupo.deleted === true ? <div key={"grupo-item-".concat(indexGrupo)}>  </div> :
                            <Collapse
                                key={"grupo-item-".concat(indexGrupo)}
                                size='small'
                                style={{ marginBottom: 15 }}
                                defaultActiveKey={['1']}
                                onChange={() => { }}
                                expandIconPosition={'right'}>
                                <Panel header={
                                    <div className="accordion-header-content">
                                        <div className="accordion-header-content-left">
                                            <MenuOutlined />
                                            <Text style={{ marginLeft: 15 }}>
                                                <strong> {grupo.descricao ? grupo.descricao : "Selecione"} </strong> <br />
                                                {grupo.maximo}{grupo.maximo > 1 ? ' opções' : ' opção'}
                                            </Text>
                                        </div>
                                        <div className="accordion-header-content-rigth">
                                            <Tag color={grupo.obrigatorio === 1 ? '#3b5999' : 'processing'}>
                                                {grupo.obrigatorio === 1 ? 'obrigatório' : 'opcional'}
                                            </Tag>

                                            <Space size={'small'} direction='horizontal'>
                                                <EditOutlined style={{
                                                    color: 'gray', 
                                                    cursor: 'pointer', 
                                                    fontSize: 20,
                                                    marginLeft: 5,
                                                    marginRight: 5
                                                }}
                                                    onClick={() => {
                                                        setGrupo(grupo)
                                                        setDrawerGrupo(true)
                                                        // handleCloseGrupo()
                                                    }} />
                                                <DeleteOutlined style={{ color: 'gray', cursor: 'pointer', fontSize: 20 }}
                                                    onClick={() => {
                                                        setGrupo(grupo)
                                                        modal.confirm({
                                                            title: "Atenção",
                                                            content: 'Deseja realmente excluir o grupo '.concat(grupo?.descricao, '?'),
                                                            cancelText: "Cancelar",
                                                            okText: "Sim, quero excluir",
                                                            onOk: () => {
                                                                var grupo1 = Object.assign({}, grupo)
                                                                grupo1.deleted = true
                                                                addGrupo(grupo1)
                                                            }
                                                        })
                                                        // setModalExcluir(true)
                                                        // handleCloseGrupo()
                                                    }} />
                                            </Space>
                                            {
                                                /*
                                             <MoreOutlined style={{ cursor: 'pointer' }}
                                                     aria-controls="simple-menu-grupo" aria-haspopup="true" onClick={(event) => {
                                                     setGrupo(grupo) handleClickGrupo(event, grupo) }} />
                                                */
                                            }

                                        </div>
                                    </div>
                                } key="1" >

                                    {
                                        (grupo.itens && grupo.deleted !== 1) && <div>
                                            {
                                                grupo.itens.map((item, itemIndex) => {

                                                    if (item.deleted === true)
                                                        return

                                                    return <Tag color='blue'>
                                                        <Space direction='horizontal'>
                                                            {item.nome}{'R$'.concat(item.valor)}
                                                        </Space>
                                                    </Tag>
                                                })

                                            }

                                        </div>

                                    }
                                </Panel>
                            </Collapse>
                    })
                }
            </div>
            {
                /*
             <Menu
                            id="simple-menu-grupo"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseGrupo}>
                            <MenuItem onClick={() => {
                                setDrawerGrupo(true)
                                handleCloseGrupo()
                            }}>Editar</MenuItem>
                            <MenuItem onClick={() => {
                                handleCloseGrupo()
                                setModalExcluir(true)
                            }}>
                                Excluir
                            </MenuItem>
                        </Menu>
                */
            }


            {/*
            <Dialog onClose={() => setModalExcluir(false)}
                aria-labelledby="simple-dialog-title-grupo"
                open={modalExcluir}>
                <DialogTitle id="simple-dialog-title-excluir-grupo">
                    Atenção!
                </DialogTitle>
                <div style={{ margin: 20 }}>
                    {grupo &&
                        'Deseja realmente escluir o grupo '.concat(grupo.descricao, '?')
                    }
                </div>
                <div className="cardapio-category-models">

                    <ButtonAntd
                        className="cardapio-category-model"

                        danger onClick={() => {
                            setModalExcluir(false)
                        }}>
                        NÃO
                    </ButtonAntd>

                    <ButtonAntd
                        className="cardapio-category-model"

                        type="primary" onClick={() => {
                            var grupo1 = Object.assign({}, grupo)
                            grupo1.deleted = true
                            addGrupo(grupo1)
                            setModalExcluir(false)
                        }}>
                        SIM
                    </ButtonAntd>
                </div>
            </Dialog>
            */}


            <Drawer
                destroyOnClose={true}
                bodyStyle={{ padding: 0 }}
                placement={'right'}
                closeIcon={null}
                closable={false}
                width={isBrowser ? 700 : '100%'}
                open={drawerGrupo}
                onClose={toggleDrawerGrupo(false)} >
                <Grupo setDrawerGrupo={setDrawerGrupo}
                    grupo={grupo}
                    addGrupo={addGrupo} />
            </Drawer>

            <div className="grupo-complementos-buttons">
                <ButtonAntd icon={<PlusOutlined />}
                    size='small' type='primary'
                    disabled={copyGroupVisible}
                    onClick={() => {
                        setGrupo({
                            appprodutoid: produto.idappprodutos,
                            chave: 0,
                            descricao: "",
                            idappgrupos: 0,
                            itens: [],
                            maximo: 20,
                            minimo: 0,
                            modo: 2,
                            obrigatorio: 0,
                            ordem: 0,
                            path: '',
                            pausado: 0,
                            produtoid: produto.ProdutoID,
                            selected: 0
                        })
                        setDrawerGrupo(true)

                    }}>
                    Novo grupo
                </ButtonAntd>

                <ButtonAntd icon={<CopyOutlined />}
                    size='small' type='primary' danger
                    onClick={() => {
                        setCopyGroupVisisble(true)
                        /*
                        modal.confirm({
                            title: "Copiar grupo(s) de outro produto",
                            content: renderCopiarGrupo(),
                            okText: "Copiar",
                            okButtonProps: {
                                disabled: copyProduto?.grupos?.length === 0 || copyProduto?.grupos === undefined,
                            },
                            cancelButtonProps: {
                                disabled: false
                            },
                            cancelText:"Fechar"
    
                        })
                        */
                    }}>
                    Copiar grupo de outro item
                </ButtonAntd>
            </div>


            <Modal
                open={copyGroupVisible}
                destroyOnClose={true}
                centered
                onCancel={() => setCopyGroupVisisble(false)}
                onOk={() => {
                    var prod = Object.assign({}, copyProduto)
                    prod.grupos.map((grupo, i) => {
                        grupo.idappgrupos = 0
                        grupo.appprodutoid = 0
                        grupo.itens.map((item, k) => {
                            item.idappgrupoitens = 0
                            item.idgrupo = 0
                        })
                    })

                    var newproduto = { ...produto }
                    newproduto.grupos.map((g, i) => {
                        g.deleted = true
                        g.itens.map((p, k) => {
                            p.deleted = true
                        })
                    })

                    prod.grupos.map((g, i) => {
                        newproduto.grupos.push(g)
                    })

                    newproduto.grupos.map((grupo, j) => {
                        if (grupo.idappgrupos === 0) {
                            var idappgrupos = Math.min.apply(Math, prod.grupos.map(function (o) { return o.idappgrupos; }))
                            if (idappgrupos === undefined || idappgrupos === null || idappgrupos >= 0)
                                idappgrupos = -1
                            else
                                idappgrupos = idappgrupos - 1

                            newproduto.grupos[j].idappgrupos = idappgrupos
                        } else {
                            //  var index = newproduto.grupos.findIndex(a => a.idappgrupos === g.idappgrupos)                                           
                            //  newproduto.grupos[index] = g
                        }
                    })
                    setCopyGroupVisisble(false)
                    setProduto(newproduto)
                }}
                okText={"Copiar"}
                okButtonProps={{
                    disabled: copyProduto?.grupos?.length === 0 || copyProduto?.grupos === undefined
                }}
                cancelButtonProps={{
                    disabled: false
                }}
                cancelText={"Voltar"}
            >
                {renderCopiarGrupo()}
            </Modal>


        </div>
    }


    return renderGrupos()

}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    produto: state.adminReducer.produto
})

const mapDispatchToProps = dispatch => ({
    setProduto: (produto) => dispatch(AdminAction.save_produto(produto))
})

export default connect(mapStateToProps, mapDispatchToProps)(Grupos)

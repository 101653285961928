import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { Button, Card, Divider, List, Space, Tag, Avatar, Alert, Modal, Row, Col, Form, Input, App } from 'antd'
import { DollarOutlined, KeyOutlined, MailOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import MAP_PNG from '../../img/map-location.png'
import TICK_PNG from '../../img/tick.png'
import CUPOM_PNG from '../../img/cupom.png'
import OUTRAS_PNG from '../../img/cadastro.png'
import { api } from '../../services/api.service'

export const Plano = (props) => {

  const { message } = App.useApp()
  const { tokenFull, token } = props
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [senha1, setSenha1] = useState('')
  const [senha2, setSenha2] = useState('')
  const [salvando, setSalvando] = useState(false)

  useEffect(() => {
    console.log(tokenFull)
  }, [])

  const onFinish = (values) => {
    //console.log('Success:', values);
    updateSenha()
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  async function updateSenha() {
    try {
      setSalvando(true)
      await api.put('/api/v2.0/usuarios/pwd',
        {
          senha: senha1,
          cpf: '000.000.000-00',
          email: tokenFull.email
        }, {
        headers: {
          'x-access-token': token,
        }
      })
      message.success("Dados alterados com sucesso!")
      setOpen(false)
    } catch (error) {
      message.error("Erro ao proccessar a solicitação. ".concat(error.message))
    }
    setSalvando(false)
  }


  function renderPlanoFull() {
    return <div>
      <Divider />
      <div style={{ fontSize: '1.1em', letterSpacing: '0.06em', fontWeight: 600, marginBottom: 20 }}>
        Todas as funcionalidades estão disponíveis para o seu app/site.
      </div>
      <Alert
        type='success'
        message={<strong>Algumas das funcionalidades</strong>}
        description={
          <Space direction='vertical'>
            <Space size={'small'} direction='horizontal'>
              <Avatar src={TICK_PNG} size={'small'} />
              <div>Pagamento online pelo app/site.</div>
            </Space>
            <Space size={'small'} direction='horizontal'>
              <Avatar src={MAP_PNG} size={'small'} />
              <div>Opção de calcular a taxa de entrega por geolocalização (distância).</div>
            </Space>
            <Space size={'small'} direction='horizontal'>
              <Avatar src={CUPOM_PNG} size={'small'} />
              <div>Impressão do cupom de pedido.</div>
            </Space>
            <Space size={'small'} direction='horizontal'>
              <Avatar src={OUTRAS_PNG} size={'small'}></Avatar>
              <div>Outras funcionalidades</div>
            </Space>
          </Space>
        }
      />


    </div >
  }



  function renderPlanoBasic() {
    return <div style={{ width: '100%' }}>
      <Divider />
      <div style={{ fontSize: '1.1em', letterSpacing: '0.06em', fontWeight: 600, marginBottom: 20 }}>
        Você está utilizando o plano Básico
      </div>


      <Alert
        message={<strong>Ative os pagamentos online e obtenha mais recursos.</strong>}
        description={
          <Space direction='vertical'>
            <Space size={'small'} direction='horizontal'>
              <Avatar src={TICK_PNG} size={'small'} />
              <div>Praticidade para seu cliente pagar online, direto pelo app/site.</div>
            </Space>
            <Space size={'small'} direction='horizontal'>
              <Avatar src={MAP_PNG} size={'small'} />
              <div>Opção de calcular a taxa de entrega por geolocalização (distância).</div>
            </Space>
            <Space size={'small'} direction='horizontal'>
              <Avatar src={CUPOM_PNG} size={'small'} />
              <div>Impressão do cupom de pedido.</div>
            </Space>
            <Space size={'small'} direction='horizontal'>
              <Avatar src={OUTRAS_PNG} size={'small'}></Avatar>
              <div>Outras funcionalidades</div>
            </Space>
          </Space>
        }
        type="info"
        action={
          <Space direction="vertical">
            <Button size="small" type="primary" style={{ width: 100 }}
              onClick={() => navigate('/formas-de-pagamento')}>
              Ativar
            </Button>
          </Space>
        }
      />

    </div>
  }



  return (

    <div style={{ margin: 5 }}>

      <Card bordered>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          backgroundColor: 'white'
        }}>
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'
            }}>
              <span style={{ fontWeight: 500, fontSize: '1.05em' }}><MailOutlined /> E-mail</span>
              <span style={{ color: 'gray' }}>{tokenFull?.email}</span>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
              marginLeft: isMobile ? 0 : 70,

            }}>
              <span style={{ fontWeight: 500, fontSize: '1.05em' }}><KeyOutlined /> Senha</span>
              <span style={{ color: 'gray' }}>{'***********'}</span>
              <Tag style={{
                color: 'royalblue', width: 100, cursor: 'pointer',
                justifyContent: 'center',
                textAlign: 'center'
              }} onClick={() => setOpen(true)}>Trocar senha</Tag>
            </div>
          </div>
          {
            (tokenFull?.license === 'programaEngfood' || tokenFull?.license === 'full')
              ? renderPlanoFull() : renderPlanoBasic()
          }
        </div>
      </Card>

      <Modal
        footer={null}
        onCancel={() => setOpen(false)}
        closable={true}
        title={"Alteração de senha"}
        open={open}
        destroyOnClose={true}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Nova senha"
            name="password1"
            rules={[
              {
                required: true,
                message: 'Informe a nova senha!',
              },
            ]}
          >
            <Input.Password value={senha1} onChange={e => setSenha1(e.target.value)} />
          </Form.Item>

          <Form.Item
            label="Confirmar senha"
            name="password2"
            rules={[
              {
                required: true,
                message: 'Informe a nova senha!',
              },

              {
                validator: (rule, value, callback) => {
                  //   console.log('senha1: ', senha1, ' senha2: ', senha2)
                  if (senha1 !== senha2 && senha1.trim() !== '' && senha1.length > 3) {
                    callback('As senhas não são iguais!');
                  } else {
                    callback();
                  }
                }
              }]}
          >
            <Input.Password value={senha2} onChange={e => setSenha2(e.target.value)} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Space direction='horizontal' size={'large'}>
              <Button onClick={() => setOpen(false)}>
                Voltar
              </Button>
              <Button type="primary" htmlType="submit"
                loading={salvando}>
                Alterar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>


    </div>
  )
}

const mapStateToProps = (state) => ({
  token: state.tokenReducer.token,
  tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Plano)
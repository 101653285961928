import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    Button, Space, Table, Tabs, Row, Col, Select, Input, Tag, Tooltip, Image,
    Modal, DatePicker, InputNumber, ConfigProvider, App
} from 'antd'
import { api } from '../../services/api.service'
import { setNotification, loadCliente, getPermissoes, GERENTE } from '../../services/dal.service'
import dayjs from 'dayjs';
import exclusive from '../../img/exclusive.png'
import { moneyMask } from '../../services/util'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import ptBR from "antd/es/locale/pt_BR";
import { isMobile } from 'react-device-detect'

const dateFormat = 'DD/MM/YYYY';
const { Option } = Select
const { Column } = Table
const { TabPane } = Tabs;

export const Cliente = (props) => {

    const { setIsModalVisible, token, uid, tokenFull } = props
    const [cliente, setCliente] = useState({})
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [isModalCupomVisible, setIsModalCupomVisible] = useState(false)
    const [cupom, setCupom] = useState({ tag: '' })
    const [isModalFidelidadeVisible, setIsModalFidelidadeVisible] = useState(false)
    const [fidelidade, setFidelidade] = useState({ valor: 10, descricao: '' })

    const permissions = getPermissoes(tokenFull)
    const { modal } = App.useApp()

    useEffect(() => {
        console.log(uid)
        getCliente(uid)
    }, [])



    async function getCliente(uid) {
        try {
            setLoading2(true)
            const d = await loadCliente(uid, token)
            setCliente(d)

        } catch (error) {
            setNotification(error.message, 'error')
        }
        setLoading2(false)
    }


    async function saveCupom() {
        try {
            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }

            setLoading2(true)
            var response = await api.post('/api/v2.0/cliente/cupom', cupom, {
                headers: {
                    'x-access-token': token,
                }

            })
            if (response.status === 200) {
                console.log('aux', response.data)
                const c = await loadCliente(cliente.uid, token)
                setCliente(c)
                setIsModalCupomVisible(false)
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
                //  setData2({ fidelidade: [], cupons: [] })
            }

        } catch (error) {
            // console.log('error', error.message)
            setNotification(error.message, 'error')
            // setData2({ fidelidade: [], cupons: [] })
        }
        setLoading2(false)
    }



    async function saveFidelidade() {
        try {

            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }

            setLoading2(true)
            var response = await api.post('/api/v2.0/cliente/fidelidade', fidelidade, {
                headers: {
                    'x-access-token': token,
                }

            })
            if (response.status === 200) {
                // console.log('aux', response.data)
                const c = await loadCliente(cliente.uid, token)
                setCliente(c)
                setIsModalFidelidadeVisible(false)
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
                //  setData2({ fidelidade: [], cupons: [] })
            }

        } catch (error) {
            // console.log('error', error.message)
            setNotification(error.message, 'error')
            // setData2({ fidelidade: [], cupons: [] })
        }
        setLoading2(false)
    }



    return (
        <div>
            <Tabs defaultActiveKey="1" style={{ marginTop: 0 }} onChange={() => { }}>
                <TabPane tab="DADOS GERAIS" key="1">
                    <Row gutter={[8, 8]}>
                        <Col span={5}>Telefone</Col>
                        <Col span={18}><Input value={cliente.phoneNumber} /></Col>
                        <Col span={5}>E-mail</Col>
                        <Col span={18}><Input value={cliente.email} /></Col>
                        <Col span={5}>Cadastro</Col>
                        <Col span={18}><Input value={dayjs(cliente.createAt, 'YYYY-MM-DD').format('DD/MM/YYYY')} /></Col>
                    </Row>
                </TabPane>
                <TabPane tab="PROGRAMA DE FIDELIDADE" key="2">
                    <Row gutter={[8, 0]}>
                        <Col span={8}>Invite Code: <Tag color={"volcano"}>{cliente.inviteCode}</Tag> </Col>
                        <Col span={8}>Saldo: <Tag color={"blue"}>{cliente.pontos}</Tag>pts.</Col>
                        <Col span={8}><Button
                        size={isMobile ? 'small' : 'middle'}
                        danger type="primary" onClick={() => {
                            setFidelidade({
                                valor: 10,
                                descricao: '',
                                uid: cliente.uid
                            })
                            setIsModalFidelidadeVisible(true)
                        }}>
                            {isMobile ? "Incluir Pts" : "Incluir Pontuação"}
                        </Button> </Col>
                    </Row>

                    <Table size="small"
                        style={{ marginTop: 5 }}
                        loading={loading2}
                        dataSource={cliente.fidelidade}>
                        <Column title="Data"
                            key="data"
                            dataIndex="data"
                            width={95}
                            render={(text) => {
                                return dayjs(text, 'YYYY-MM-DD').format('DD/MM/YYYY')
                            }
                            }
                        />
                        <Column title="Pts" key="pontos"
                            dataIndex="pontos"
                            width={60}
                            render={(text) => {
                                return text
                            }
                            } />
                        <Column title="Historico" key="descricao" dataIndex="descricao" />
                        <Column title="Saldo" key="saldo" width={60} dataIndex="saldo" />
                    </Table>


                </TabPane>
                <TabPane tab="CUPONS" key="3">
                    <Row gutter={[8, 8]}>
                        {permissions?.includes(GERENTE) && <Col span={24}>
                            <Button danger type="primary"
                                disabled={!permissions?.includes(GERENTE)}
                                onClick={() => {
                                    setCupom({
                                        ativo: 1,
                                        valor: 10,
                                        tag: '',
                                        minimo: 10,
                                        quant: 1,
                                        tipo: 1,
                                        exclusive: cliente.uid,
                                        viarest: 1,
                                        validade: dayjs().format('YYYY-MM-DD'),
                                        uid: cliente.uid
                                    })
                                    setIsModalCupomVisible(true)
                                }}>Novo Cupom</Button>
                        </Col>
                        }
                        <Col span={24}>
                            <Table size="small"
                                style={{ marginTop: 5 }}
                                loading={loading2}
                                dataSource={cliente.cupons} >
                                <Column title="TAG"
                                    key="tag"
                                    dataIndex="tag"
                                    render={(text, record) => {
                                        return <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {record.exclusive && <Tooltip color="blue" title="Cupom exclusive para o cliente.">
                                                <Image src={exclusive} width={20} height={20} preview={false} />
                                            </Tooltip>
                                            }
                                            <span style={{ marginLeft: 8 }}> {text} </span>
                                        </div>
                                    }}
                                />
                                <Column title="Valor"
                                    key="valor"
                                    dataIndex="valor"
                                    width={95}
                                    render={(text) => {
                                        return text.toFixed(2)
                                    }
                                    }
                                />
                                <Column title="Utilizado"
                                    key="utilizado"
                                    dataIndex="utilizado"
                                    width={95}
                                    render={(text) => {
                                        return text ? <Tag color="volcano"> {dayjs(text, 'YYYY-MM-DD').format('DD/MM/YYYY')} </Tag> : ''
                                    }
                                    }
                                />

                                {permissions?.includes(GERENTE) && <Column title="" dataIndex="edit" key="edit" width={30}
                                    render={(text, record, index2) => {
                                        return <Space size="small">
                                            <Button type="link" onClick={() => {
                                                record.uid = cliente.uid
                                                setCupom(record)
                                                setIsModalCupomVisible(true)
                                                console.log(record)
                                            }}>Editar</Button>
                                        </Space>
                                    }}
                                />
                                }
                            </Table>
                        </Col>
                    </Row>

                </TabPane>
            </Tabs>

            <Modal title="CUPOM"
                open={isModalCupomVisible}
                onOk={() => saveCupom()}
                okText=" Salvar "
                okButtonProps={{
                    disabled: cupom.tag.trim().length < 3
                }}
                onCancel={() => setIsModalCupomVisible(false)}>
                <Row gutter={[8, 8]}>
                    <Col span={6}>TAG</Col>
                    <Col span={18}>
                        <Input maxLength={50}
                            placeholder="Exemplo: CUPOM10"
                            value={cupom.tag}
                            onChange={(e) => setCupom({ ...cupom, tag: e.target.value })} />
                    </Col>
                    <Col span={6}>Valor R$</Col>
                    <Col span={18}>
                        <InputNumber
                            step='0.01'
                            decimalSeparator={','}
                            min={1}
                            stringMode={true}
                            value={moneyMask(cupom.valor)} onChange={(e) => {
                                setCupom({ ...cupom, valor: e })
                            }} />
                        <Tooltip color="blue" title={"Valor do desconto em reais que será concedido ao utilizar o cupom."}>
                            <ExclamationCircleOutlined style={{ color: 'grey', marginLeft: 10 }} />
                        </Tooltip>
                    </Col>
                    <Col span={6}>V. Mínimo R$</Col>
                    <Col span={18}>
                        <InputNumber
                            value={cupom.minimo}
                            step='0.01'
                            decimalSeparator={','}
                            min={1}
                            stringMode={true}
                            onChange={(e) => {
                                setCupom({ ...cupom, minimo: e })
                            }} />
                        <Tooltip color="blue" title={"Valor mínimo do pedido para utilização do cupom."}>
                            <ExclamationCircleOutlined style={{ color: 'grey', marginLeft: 10 }} />
                        </Tooltip>
                    </Col>
                    <Col span={6}>Válido até</Col>
                    <Col span={18}>
                        <ConfigProvider locale={ptBR}>
                            <DatePicker
                                locale={ptBR}
                                format={dateFormat}
                                allowClear={false}
                                value={dayjs(cupom.validade, 'YYYY-MM-DD')}
                                onChange={(value) => {
                                    setCupom({ ...cupom, validade: value.format('YYYY-MM-DD') })

                                }} />
                        </ConfigProvider>
                        <Tooltip color="blue" title={"Date que até que dia esse cupom estará disponível."}>
                            <ExclamationCircleOutlined style={{ color: 'grey', marginLeft: 10 }} />
                        </Tooltip>
                    </Col>
                    <Col span={6}>Status</Col>
                    <Col span={18}>
                        <Select
                            value={cupom.ativo}
                            onChange={(e) => setCupom({ ...cupom, ativo: e })}>
                            <Option value={0}>Indisponível</Option>
                            <Option value={1}>Disponível</Option>
                        </Select>
                    </Col>
                </Row>
            </Modal>


            <Modal title="Controle de Pontos"
                open={isModalFidelidadeVisible}
                onOk={() => { saveFidelidade() }}
                okText=" Salvar "
                okButtonProps={{
                    disabled: (fidelidade.descricao.trim().length < 3 || fidelidade.valor <= 0)
                }}
                onCancel={() => setIsModalFidelidadeVisible(false)}>
                <Row gutter={[8, 8]}>
                    <Col span={6}>Pontos</Col>
                    <Col span={18}>
                        <InputNumber
                            value={fidelidade.valor}
                            decimalSeparator={','}
                            onChange={(e) => {
                                setFidelidade({ ...fidelidade, valor: e })
                            }} />
                    </Col>
                    <Col span={6}>Descrição</Col>
                    <Col span={18}>
                        <Input
                            value={fidelidade.descricao}
                            placeholder={"Informe a descrição da pontuação."}
                            maxLength={100}
                            onChange={(e) => {
                                setFidelidade({ ...fidelidade, descricao: e.target.value })
                            }} />
                    </Col>

                </Row>
            </Modal>


        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Cliente)
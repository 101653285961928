import React from 'react'
import { Provider } from 'react-redux'
import { store, persistor } from './store/store'
import './App.css';
import { ToastProvider } from 'react-toast-notifications'
import { PersistGate } from 'redux-persist/integration/react'
import Rotas from './routes'
//import 'antd/dist/antd.css';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PedidosService from './services/pedidos.service'
import 'bootstrap/dist/css/bootstrap.min.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


//const AppContext = React.createContext('appPedidos');

function App() {



  return (<ToastProvider placement='bottom-right'>

      <React.Fragment>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Rotas />
          </PersistGate>
        </Provider>
        <NotificationContainer />
      </React.Fragment>
    
  </ToastProvider>
  );
}

export default App;

import React from 'react';
import { NotificationManager } from 'react-notifications';
import { api } from './api.service'
import 'react-notifications/lib/notifications.css';



export const validateEmail = (email) => {
    // console.log(email)
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(email).toLowerCase().trim())

}


export const calcularTaxa = (address, bairros) => {
    try {

        var result = bairros.filter(item => {
            return item.atendido === 1 && item.descricao.toUpperCase() === address.bairro.toUpperCase()
        })

        if (result.length > 0) {
            address.atendido = 1;
            address.taxa = result[0].valor;
        } else {
            address.taxa = -1;
            address.atendido = 0;
        }

    } catch (error) {
        address.taxa = -1
        address.atendido = 0;
    }

    return address;

}



export const setNotification = (msg, tipo) => {
    switch (tipo) {
        case 'info':
            NotificationManager.info(msg, '', 3000);
            break;
        case 'success':
            NotificationManager.success(msg, '', 3000);
            break;
        case 'warning':
            NotificationManager.warning(msg, '', 3000);
            break;
        case 'error':
            NotificationManager.error(msg, '', 5000, () => {
                // alert('callback');
            });
            break;

        default:
            NotificationManager.error(msg, '', 5000, () => {
                // alert('callback');
            });
            break;
        //    }
    };

}




//
export const getOrders = async (token) => {
    try {
        var response = await api.get('/api/v2.0/pedidos/loja/orders/all', {
            headers: {
                'x-access-token': token,
            },
        })
        return response.data
    } catch (error) {
        return null
    }
}





export const cancelarPedido = async (item, token) => {
    try {
        await api.post('/api/v2.0/pedido/ordercanceled', item, {
            headers: {
                'x-access-token': token,
            },
        })
        //await getOrders(token)
        return true
    } catch (error) {

    }
    return false
}

export const emEntrega = async (item, token) => {
    try {
        await api.put('/api/v2.0/pedido/'.concat(item.type === 'DELIVERY' ? 'orderdelivered' : 'readyToDeliver'), item, {
            headers: {
                'x-access-token': token,
            },
        })
        // await getOrders(token)
        return true
    } catch (error) {

    }
    return false
}

export const updatePaid = async (item, token) => {
    try {
        await api.put('/api/v2.0/pedido/setpaid', item, {
            headers: {
                'x-access-token': token,
            },
        })
        // await getOrders(token)
        return true
    } catch (error) {

    }
    return false
}


export const finalizado = async (item, token) => {

    try {
        await api.put('/api/v2.0/pedido/finish', item, {
            headers: {
                'x-access-token': token,
            },
        })
        // await getOrders(token)
        return true
    } catch (error) {

    }
    return false
}


export const confirmarPedido = async (item, token) => {
    try {

        await api.put('/api/v2.0/pedido/confirmorder', item, {
            headers: {
                'x-access-token': token,
            },
        })
        //  await getOrders(token)
        return true
    } catch (error) {
        console.log(error)
    }
    return false
}


export const loadCliente = async (uid, token) => {
    try {
        var response = await api.get('/api/v2.0/cliente', {
            headers: {
                'x-access-token': token,
            },
            params: {
                uid: uid
            }
        })
        return response.data

    } catch (error) {
        throw new Error(error);
    }
}

export const ADMINISTRADOR = "administrador"
export const GERENTE = "gerente"
export const ENTREGADOR = "entregador"
export const VANDAS = "vendas"


export const getPermissoes = (tokenFull) => {
    //console.log(tokenFull)
    switch (tokenFull?.cargosid) {
        case 0:
            return ['administrador', 'gerente', 'entregador', 'vendas']
        case 1:
            return ['gerente', 'entregador', 'evndas']
        case 2:
            return ['vendas']
        case 3:
            return ['entregador']
        default:
            return []
    }
}







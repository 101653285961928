import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core';
import { Switch } from 'antd';
//import 'antd/dist/antd.css';
import { api } from '../../../services/api.service'
import {DeleteOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import { setNotification } from '../../../services/dal.service'
import { Rate } from 'antd';
import { InputNumber, Input, Modal } from 'antd';

import { Table, Space } from 'antd';

import * as AdminAction from '../../../store/actions/admin.action'

export const Tamanhos = (props) => {

    const { token, categoria, setCategoria } = props
    //   const [categoria, setCategoria] = useState(props.categoria)
    const [erro, setErro] = useState(false)

    const columns = [
        {
            title: 'Disponibilidade',
            dataIndex: 'Disponivel',
            key: 'Disponivel',
            render: (text, record) => (
                <Switch
                    style={{ marginLeft: '20px' }}
                    checkedChildren={'Ativado'}
                    unCheckedChildren={'Pausado'}
                    checked={text}
                    onChange={(e) => {
                        record.Disponivel = e === true ? 1 : 0
                        var tamanhos = Object.values(Object.assign({}, categoria.tamanhos))
                        var index = tamanhos.findIndex(a => a.idapptamanhos === record.idapptamanhos)
                        if (index >= 0) {
                            tamanhos[index] = record
                            setCategoria({ ...categoria, tamanhos: tamanhos })
                        }
                    }} />
            ),
        },
        {
            title: 'Tamanho',
            dataIndex: 'NomeReduzido',
            key: 'idapptamanhos',
            render: (text, record) => (
                <Input value={text} maxLength={50} onChange={(e) => {
                    record.NomeReduzido = e.target.value
                    var tamanhos = Object.values(Object.assign({}, categoria.tamanhos))
                    var index = tamanhos.findIndex(a => a.idapptamanhos === record.idapptamanhos)
                    if (index >= 0) {
                        tamanhos[index] = record
                        setCategoria({ ...categoria, tamanhos: tamanhos })
                    }
                }} />
            ),
        },
        {
            title: 'Qtde. Sabores',
            dataIndex: 'sabores',
            key: 'sabores',
            render: (text, record) => (
                <Rate defaultValue={1} character={({ index }) => (
                    index + 1
                )}
                    value={text}
                    onChange={(e) => {
                        record.sabores = e
                        var tamanhos = Object.values(Object.assign({}, categoria.tamanhos))
                        var index = tamanhos.findIndex(a => a.idapptamanhos === record.idapptamanhos)
                        if (index >= 0) {
                            tamanhos[index] = record
                            setCategoria({ ...categoria, tamanhos: tamanhos })
                        }
                    }}
                    style={{ color: 'blue', fontWeight: '500' }} count={4} />
            ),
        },
        {
            title: 'Cód. PDV',
            dataIndex: 'Tipo',
            key: 'Tipo',
            render: (text, record) => (
                <InputNumber min={0} defaultValue={0} value={text} onChange={(e) => {
                    record.Tipo = e
                    var tamanhos = Object.values(Object.assign({}, categoria.tamanhos))
                    var index = tamanhos.findIndex(a => a.idapptamanhos === record.idapptamanhos)
                    if (index >= 0) {
                        tamanhos[index] = record
                        setCategoria({ ...categoria, tamanhos: tamanhos })
                    }
                }} />
            ),
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle" onClick={() => {
                    Modal.confirm({
                        zIndex: 99999999,
                        title: 'Atenção!',
                        icon: <QuestionCircleOutlined size='small' />,
                        content: 'Deseja realmente excluir o tamanho?',
                        okText: 'Confirmar',
                        cancelText: 'Cancelar',
                        onOk: () => {
                            return new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    try {
                                        if (record.idapptamanhos > 0) {
                                            const response = await api.delete('/api/v2.0/category/tamanho?id='.concat(record.idapptamanhos), {
                                                headers: {
                                                    'x-access-token': token,
                                                }
                                            })
                                            if (response.status === 200) {
                                                var tamanhos = Object.values(Object.assign({}, categoria.tamanhos))
                                                var tamanhos2 = tamanhos.filter(el => el.idapptamanhos !== record.idapptamanhos)
                                                setCategoria({ ...categoria, tamanhos: tamanhos2 })
                                                setNotification("Tamanho excluido com sucesso", 'success')
                                            }

                                        } else {
                                            var tamanhos = Object.values(Object.assign({}, categoria.tamanhos))
                                            var tamanhos2 = tamanhos.filter(el => el.idapptamanhos !== record.idapptamanhos)
                                            setCategoria({ ...categoria, tamanhos: tamanhos2 })
                                        }
                                    } catch (error2) {
                                        setNotification('Oops errors!', 'error')
                                        reject()
                                    }
                                    console.log(record.idapptamanhos)
                                    resolve()
                                }, 1000);
                            })
                                .catch(() => {
                                    console.log('Oops errors!')
                                    setNotification('Desculpe aconteceu um erro inesperado!', 'error')
                                });
                        },
                        onCancel: () => {
                            console.log('cancel')
                        }
                    });
                }}>
                    <DeleteOutlined fontSize="small" style={{ cursor: "pointer", color: 'gray' }} />
                </Space>
            ),
        },
    ];


    return (
        <div>
            Indique aqui quais os tamanhos produzidos das Pizzas e em quantos sabores seu
            restaurante monta para cada tamanho:
            <div>
                <Table columns={columns} dataSource={categoria.tamanhos}
                    size="small"
                    pagination={false} />
            </div>
            <div style={{ marginTop: 10 }}>
                <Button variant="outlined" size="small"
                    onClick={() => {
                        try {
                            var index = categoria.tamanhos.length === 0 ? 0 :
                                Math.min(...categoria.tamanhos.map(item => item.idapptamanhos));
                            index = (index - 1) < 0 ? (index - 1) : -1 * (index - 1);
                            const newprod = {
                                Disponivel: 1,
                                NomeReduzido: '',
                                sabores: 1,
                                Tipo: 0,
                                idapptamanhos: index
                            }

                            var tamanhos = Object.values(Object.assign({}, categoria.tamanhos))
                            tamanhos.push(newprod)
                            setCategoria({ ...categoria, tamanhos: tamanhos })
                            // console.log(categoria)
                        } catch (eroror) {
                            console.log('ERROR> ', eroror.message)
                        }

                    }}>
                    + novo tamanho
                </Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    categoria: state.adminReducer.categoria
})

const mapDispatchToProps = dispatch => ({
    setCategoria: (categoria) => dispatch(AdminAction.save_categoria(categoria))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tamanhos)

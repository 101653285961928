import { Action_User_Types } from '../actions/user.action'

const initialState = {
    user: null

}

//const initialState = defineState(defaultState)('userReducer')



const userReducer = (state = initialState, action) => {

    switch (action.type) {

        case Action_User_Types.SAVE_USER:
            return {
                ...state,
                user: action.user
            }

        case Action_User_Types.SAVE_CATEGORIA:
            return {
                ...state,
                categoria: action.categoria
            }

        default:
            return state;
    }
}


export default userReducer;
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../admin.css'
import { api } from '../../services/api.service'
import { setNotification, getPermissoes, GERENTE } from '../../services/dal.service'
import {
    Table, Select, Button, Card, Input, InputNumber, Space, Tag, Row, Col,
    Drawer, Tooltip, DatePicker, App
} from 'antd'
import moment from 'moment';
import ilustracion from '../../img/ilustracoes/undraw_printing_invoices_5r4r.png'
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { moneyMask } from '../../services/util'
import { isBrowser } from "react-device-detect";

const { Option } = Select
const { Column } = Table

export const Cupons = (props) => {

    const { token, tokenFull } = props
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [cupom, setCupom] = useState({
        tag: '',
        validade: moment().format('YYYY-MM-DD'),
        assunto: '',
        valor: 10,
        minimo: 10,
        ativo: 1,
        quant: 1,
        utilizado: 0
    })
    const permissions = getPermissoes(tokenFull)
    const { modal } = App.useApp()

    useEffect(() => {
        load()
    }, [])

    async function load() {
        try {
            setLoading(true)
            var response = await api.get('/api/v2.0/cupom', {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                // console.log(response.data)
                setData(response.data)
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            props.history.push('/login')
        }
        setLoading(false)
    }


    async function salvar() {
        try {

            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }

            setLoading(true)
            //  cupom.data = moment().format('DD/MM/YYYY HH:mm:ss')
            var response = await api.post('/api/v2.0/cupom', cupom, {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                setIsModalVisible(false)
                setNotification('Cupom salvo com sucesso!', 'success')
                load()
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }

    return isBrowser ? renderDesktop() : renderMobile()

    function renderMobile() {
        return <div style={{ backgroundColor: 'white', flex: 1 }}>

            {
                renderContextArea()
            }
        </div>
    }

    function renderDesktop() {
        return <div className="app-window">
            {renderContextArea()}
        </div>
    }

    function renderContextArea() {
        return <React.Fragment>
            <div className="contentarea">
                <Card title={null} style={{ marginBottom: 15 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img style={{ marginRight: 40 }} src={ilustracion} alt="img" height={70} width={90} />
                        <div>
                            <h3>Cupons de Descontos.</h3>
                            <p>.</p>
                        </div>
                    </div>
                </Card>

                <Table dataSource={data} size="small" loading={loading}
                rowKey={(value)=> "row-key-cupons-".concat(value.idengcupons)}
                    footer={() => <div
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        </div>
                        <div>
                            <Button disabled={!permissions?.includes(GERENTE)} type="primary" onClick={() => {
                                setCupom({
                                    tag: '',
                                    validade: moment().format('YYYY-MM-DD'),
                                    assunto: '',
                                    valor: 10,
                                    minimo: 10,
                                    ativo: 1,
                                    quant: 1,
                                    utilizado: 0,
                                    tipo: 0
                                })
                                setIsModalVisible(true)
                            }}>Novo Cupom
                            </Button>
                        </div>
                    </div>}>
                    <Column title="TAG" dataIndex="tag" key="datagta"

                        render={(text, record, index) => {
                            return <div>
                                <div>  {text} </div>
                                <div>
                                    {/*
                                        <Space size="middle" >
                                            <Button type="link" onClick={() => {
                                                setCupom(record)
                                                setIsModalVisible(true)
                                            }}>Edit</Button>
                                        </Space>
                                        */
                                    }

                                </div>
                            </div>
                        }} />

                    <Column title="Valor" dataIndex="valor" key="valor"
                        width={80}
                        render={(text, record, index) => {
                            return 'R$ '.concat(text.toFixed(2))
                        }}
                    />

                    {isBrowser && <Column title="Data" dataIndex="createAt" key="createAt"
                        width={80}
                        render={(text) => {
                            return moment(text, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        }}
                    />
                    }

                    {isBrowser && <Column title="Mínimo" dataIndex="minimo" key="minimo"
                        width={80}
                        render={(text, record, index) => {
                            return 'R$ '.concat(text.toFixed(2))
                        }}
                    />
                    }

                    {isBrowser && <Column title="Quantidade" dataIndex="quant" key="quant"
                        width={80}
                        render={(text, record, index) => {
                            return <Tag color='green'> {text} </Tag>
                        }}
                    />
                    }

                    {isBrowser && <Column title="Disponíveis" dataIndex="utilizados" key="utilizados"
                        width={80}
                        render={(text, record) => {
                            return <Tag color={record.quant >= record.utilizados ? 'blue' : 'magenta'}> {record.quant - record.utilizados} </Tag>
                        }}
                    />
                    }

                    <Column title="Validade" dataIndex="validade" key="validade"
                        width={80}
                        render={(text) => {
                            return moment(text, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        }}
                    />

                    <Column title="Status" dataIndex="ativo" key="ativo"
                        width={80}
                        render={(text, record) => {
                            var validade = new Date(moment(record.validade, 'YYYY-MM-DD'))
                            var dataNow = new Date(moment().format('YYYY-MM-DD'))
                            var result = validade >= dataNow
                            var result2 = text === 1
                            // console.log(result, result2, dataNow)
                            return <Tag color={(result && (text === 1)) ? 'blue' : 'magenta'}> {(result && (text === 1)) ? 'Disponível' : 'Indisponível'}</Tag>
                        }}
                    />

                    {(isBrowser && permissions?.includes(GERENTE) )&& <Column title="" width={40}
                        render={(text, record, index2) => {
                            return <Space size="middle" >
                                <Button type="link" onClick={() => {
                                    setCupom(record)
                                    setIsModalVisible(true)
                                }}>Edit</Button>
                            </Space>
                        }}
                    />
                    }

                </Table>

            </div>

            <Drawer title={cupom.tag.length > 0 ? cupom.tag : 'CUPOM'}
                placement="right" onClose={() => setIsModalVisible(false)}
                width={isBrowser ? 500 : '100%'}
                open={isModalVisible}>
                <Row gutter={[8, 8]}>
                    <Col span={6}>TAG</Col>
                    <Col span={18}>
                        <Input maxLength={50}
                            placeholder="Exemplo: CUPOM10"
                            value={cupom.tag}
                            onChange={(e) => setCupom({ ...cupom, tag: e.target.value })} />
                    </Col>
                    <Col span={6}>Valor R$</Col>
                    <Col span={18}>
                        <InputNumber
                            step='0.01'
                            decimalSeparator={','}
                            min={1}
                            stringMode={true}
                            value={moneyMask(cupom.valor)} onChange={(e) => {
                                setCupom({ ...cupom, valor: e })
                            }} />
                        <Tooltip color="blue" title={"Valor do desconto em reais que será concedido ao utilizar o cupom."}>
                            <ExclamationCircleOutlined style={{ color: 'grey', marginLeft: 10 }} />
                        </Tooltip>
                    </Col>
                    <Col span={6}>V. Mínimo R$</Col>
                    <Col span={18}>
                        <InputNumber
                            value={cupom.minimo}
                            min={1}
                            onChange={(e) => {
                                setCupom({ ...cupom, minimo: e })
                            }} />
                        <Tooltip color="blue" title={"Valor mínimo do pedido para utilização do cupom."}>
                            <ExclamationCircleOutlined style={{ color: 'grey', marginLeft: 10 }} />
                        </Tooltip>
                    </Col>
                    <Col span={6}>Válido até</Col>
                    <Col span={18}>
                        <DatePicker
                            value={moment(cupom.validade, 'YYYY-MM-DD')}
                            format={"DD/MM/YYYY"}
                            clearIcon={false}
                            onChange={(value) => {

                                setCupom({ ...cupom, validade: value.format('YYYY-MM-DD') })

                            }} />
                        <Tooltip color="blue" title={"Date que até que dia esse cupom estará disponível."}>
                            <ExclamationCircleOutlined style={{ color: 'grey', marginLeft: 10 }} />
                        </Tooltip>
                    </Col>
                    <Col span={6}>Quantidade</Col>
                    <Col span={18}>
                        <InputNumber
                            value={cupom.quant}
                            min={1}
                            onChange={(e) => {
                                setCupom({ ...cupom, quant: e })
                            }} />
                        <Tooltip color="blue" title={"Total de cupons disponibilizados."}>
                            <ExclamationCircleOutlined style={{ color: 'grey', marginLeft: 10 }} />
                        </Tooltip>
                    </Col>
                    <Col span={6}>Tipo</Col>
                    <Col span={18}>
                        <Select
                            style={{ width: 120 }}
                            value={cupom.tipo}
                            onChange={(e) => setCupom({ ...cupom, tipo: e })}>
                            <Option value={0}>Normal</Option>
                            <Option value={1}>1º pedido</Option>
                        </Select>
                    </Col>
                    <Col span={6}>Status</Col>
                    <Col span={18}>
                        <Select
                            style={{ width: 120 }}
                            value={cupom.ativo}
                            onChange={(e) => setCupom({ ...cupom, ativo: e })}>
                            <Option value={0}>Indisponível</Option>
                            <Option value={1}>Disponível</Option>
                        </Select>
                    </Col>

                    <Col span={24}>
                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between', marginTop: 10
                        }}>
                            <div></div>
                            <div>
                                <Button danger type="primary" onClick={() => setIsModalVisible(false)}>
                                    Cancelar
                                </Button>
                                <Button type="primary"
                                    disabled={cupom.tag.length < 2}
                                    style={{ marginLeft: 15 }}
                                    onClick={() => salvar()}>
                                    Salvar
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Drawer>
        </React.Fragment>
    }


}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Cupons)

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../admin.css'
import './gestor.css'
import { api } from '../../services/api.service'
import {
    Table,  Tag, Button, Typography, App,
    Space, Drawer, Row, Col, Divider, Input, Steps, Collapse, Checkbox
} from 'antd'
import moment from 'moment';
import { isBrowser } from "react-device-detect";
import deliveryPNG from '../../img/delivery-man.png'
import retiradaPNG from '../../img/takeaway.png'
import * as ActionPedidos from '../../store/actions/pedidos.action'

const { TextArea } = Input;
const { Column } = Table
const { Step } = Steps
const { Panel } = Collapse;

export const Gestor = (props) => {

    const {notification} = App.useApp()
    const { token, pedidos, setPedidos, saveExecutando } = props
    const [loading, setLoading] = useState(true)
    const [pedido, setPedido] = useState(undefined)
    const [total, setTotal] = useState(0)
    const [taxas, setTaxas] = useState(0)

    const [textMotivo, setTextMotivo] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);



    function getDiaSemana(value) {
        var dias = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
        var meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
        var dia = new Date(value)
        return dias[dia.getDay()].concat(', ', dia.getDate(), ' ', meses[dia.getMonth()], ' ', dia.getFullYear())

    }

    function renderTagStatus(text) {
        var status = 'Não confirmado'
        var cor = 'orange'
        switch (text) {
            case 1:
                cor = 'blue'
                status = 'Confirmado'
                break;
            case 2:
                cor = 'magenta'
                status = 'Cancelado'
                break;

            case 3:
                cor = 'orange'
                status = 'Cancelado'
                break;

            case 4:
                cor = '#f50'
                status = 'Em entrega'
                break;

            case 5:
                cor = 'purple'
                status = 'Pronto retirar'
                break;

                case 10:
                cor = '#87d068'
                status = 'Finalizado'
                break;

            default:
                break;
        }
        return <Tag color={cor}> {status}</Tag>
    }


    async function getPedido(pedid) {
        try {           
            setLoading(true)
            var response = await api.get('/api/v2.0/pedido', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    pedid: pedid
                }
            })
            if (response.status === 200) {           
                setPedido(response.data)             
            } else {
                setPedido(undefined)
                notification.error({
                    message:"Atenção!",
                    description: 'O servidor não está respondendo com dados válidos!'
                })
            }

        } catch (error) {
            notification.error({
                message:"Atenção!",
                description: error.message
            })
            setPedido(undefined)
        }
        setLoading(false)

    }


    async function cancelarPedido(item) {
        try {

            if (textMotivo.length < 6) {
                setIsModalOpen(false)
               
                notification.error({
                    message:"Atenção!",
                    description: "Informe o motivo do cancelamento"
                })
            
                return
            }
            saveExecutando(true)
            item.motivoCancelamento = textMotivo
            setLoading(true)
            var response = await api.post('/api/v2.0/pedido/ordercanceled', item, {
                headers: {
                    'x-access-token': token,
                },
            })
            notification.error({
                message:"Atenção!",
                description: 'Pedido cancelado com sucesso!'
            })
            setIsModalOpen(false)
            setPedido(undefined)
            setTextMotivo('')
            getPedidos()

        } catch (error) {
            notification.error({
                message:"Atenção!",
                description: error.message
            })
        }
        setLoading(false)
        saveExecutando(false)
    }

    async function emEntrega(item){
        try {
            saveExecutando(true)
            setLoading(true)
            await api.put('/api/v2.0/pedido/'.concat(item.type === 'DELIVERY' ? 'orderdelivered' : 'readyToDeliver'), item, {
                headers: {
                    'x-access-token': token,
                },
            })
            notification.error({
                message:"Atenção!",
                description: 'Status alterado com sucesso!'
            })
            setPedido(undefined)
            getPedidos()

        } catch (error) {
           
            notification.error({
                message:"Atenção!",
                description: error.message
            })
        }
        setLoading(false)
        saveExecutando(false)
    }


    async function finalizado(item){
        try {
            saveExecutando(true)
            setLoading(true)
            await api.put('/api/v2.0/pedido/finish', item, {
                headers: {
                    'x-access-token': token,
                },
            })
            notification.success({
                message:"Atenção!",
                description: 'Status alterado com sucesso!'
            })
            setPedido(undefined)
            getPedidos()

        } catch (error) {
            notification.error({
                message:"Atenção!",
                description: error.message
            })
        }
        setLoading(false)
        saveExecutando(false)
    }


    async function confirmarPedido(item) {
        try {
            saveExecutando(true)
            setLoading(true)
            var response = await api.put('/api/v2.0/pedido/confirmorder', item, {
                headers: {
                    'x-access-token': token,
                },
            })
            item.state = 1
            //   setPedidos(...pedidos)
            notification.success({
                message:"Atenção!",
                description: 'Pedido confirmado com sucesso!'
            })
            setPedido(undefined)
            getPedidos()

        } catch (error) {
           
            notification.error({
                message:"Atenção!",
                description: error.message
            })
        }
        setLoading(false)
        saveExecutando(false)
    }


    async function getPedidos() {
        try {
            if (token) {

                const rota1 = '/api/v2.0/pedidos/loja/orders'
                const rota2 = '/api/v2.0/pedidos/loja/orders/initial'
                var response = await api.get(pedidos.length === 0 ? rota2 : rota2, {
                    headers: {
                        'x-access-token': token,
                    }
                })

                if (response.data.orders.length > 0) {
                    var peds = Object.values(Object.assign(pedidos));
                    var orders = response.data.orders;
                    var i = 0
                    for (i = 0; i < orders.length; i++) {

                        var index = peds.findIndex(value => orders[i].pedid === value.pedid)

                        if (index >= 0)
                            peds[index] = orders[i];
                        else
                            peds.push(orders[i])

                        if (orders[i].renotifica !== 0) {
                            await api.put('/api/v2.0/pedido/renotifica', orders[i], {
                                headers: {
                                    'x-access-token': token,
                                }
                            })
                        }
                    }
                    setPedidos(peds.sort((a, b) => {
                        if (a.pedid > b.pedid)
                            return -1;
                        if (a.pedid < b.pedid)
                            return 1;
                        return 0;
                    }))
                }
            }

        } catch (error) {
            console.log('error', error.message)
        }
    }



    return <div>

        {
            isBrowser ? renderDesktop() : renderMobile()
        }

        <Drawer title={<div>
            {"Detalhes do pedido Nº ".concat(pedido?.pedid)}
        </div>}
            placement="right" onClose={() => setIsModalOpen(false)}
            width={350}
            visible={isModalOpen}
            destroyOnClose={true}
        >

            O cliente receberá essa informação!

            <TextArea rows={4} placeholder="Informe o motivo do cancelamento" maxLength={50}
                value={textMotivo}
                onChange={(value) => setTextMotivo(value.target.value)} />
            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row-reverse' }}>
                <Button type='primary'
                    disabled={loading}
                    onClick={() => {
                        handleOk()
                    }}>Confirmar cancelamento</Button>
            </div>

        </Drawer>


    </div>

    function renderSteps() {

        return <React.Fragment >
            {pedido.history.map((value, index) => {
                return <Step key={index} title={<div style={{ fontSize: 11 }}>
                    <div>{value.title}</div>
                    <div>{value.time}</div>
                </div>} />
            })}

            {pedido.status === 0 && <Step title={<div style={{ fontSize: 11 }}>
                <div>{"Confirmado"}</div>
            </div>} />}

            {pedido.status === 1 && <Step title={<div style={{ fontSize: 11 }}>
                <div>{"Em entrega"}</div>
            </div>} />}

            {pedido.status === 1 && <Step title={<div style={{ fontSize: 11 }}>
                <div>{"Finalizado"}</div>
            </div>} />}

        </React.Fragment>
    }


    function renderDesktop() {
        return <div className="app-window">
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10, width: '100%' }}>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Typography.Title level={3}>Gestor de Pedidos</Typography.Title>
                    <Tag color='geekblue' style={{marginLeft:10}}>Beta</Tag>
                </div>

                <div >
                    <Table dataSource={pedidos} size="small" loading={false}
                        pagination={{
                            defaultPageSize: 20,
                            pageSize: 50

                        }}
                        footer={() => {
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                <div>Nº de pedidos: <strong> {pedidos.length} </strong></div>
                                <div>Total R$: <strong> {pedidos.reduce((partialSum, a) => partialSum + a.valor, 0).toFixed(2)} </strong></div>
                                <div>Confirmado R$: <strong> {pedidos.reduce((partialSum, a) => (a.status !== 3 && a.status !== 2 && a.status !== 0) ?
                                    (partialSum + a.valor) :
                                    (partialSum + 0), 0).toFixed(2)}
                                </strong>
                                </div>
                            </div>
                        }} rowKey={"pedid"} style={{ width: '100%' }}>
                        <Column title="Data" dataIndex="dataserver" key="dataserver"
                            width={140}
                            render={(text, record) => {
                                return record.pedid ? moment(text, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''
                            }} />

                        <Column title="Pedido" dataIndex="pedid" key="pedid"
                            width={80}
                            render={(text, record) => {
                                return record.pedid ? <Space> <Tag color="warning">{text}</Tag>
                                    {renderTagStatus(record.status)}
                                </Space> : 'Registros: '.concat(pedidos?.length)
                            }}
                        />

                        <Column title="Taxa" dataIndex="taxa" key="pedid"
                            width={80}
                            render={(text, record) => {
                                return record.pedid > 0 ? 'R$ '.concat(text.toFixed(2)) : "R$ ".concat(taxas.toFixed(2)) //'R$ '.concat(text.toFixed(2))
                            }}
                        />

                        <Column title="Valor" dataIndex="valor" key="valor"
                            width={80}
                            render={(text) => {
                                return text ? 'R$ '.concat(text.toFixed(2)) : 'R$ '.concat(total.toFixed(2))
                            }}
                        />

                        <Column title="Tipo" dataIndex="type" key="type"
                            width={80}
                            render={(text) => {
                                return text ? <Tag >
                                    {text === 'DELIVERY' ? <img src={deliveryPNG} height={16} alt="deliveriImg" /> :
                                        <img src={retiradaPNG} height={16} alt="imgretirada" />}
                                    {text === 'PICKUP' ? "Retirada" : 'Delivery'} </Tag>
                                    : ''
                            }}
                        />


                        <Column title="Cliente" dataIndex="nome" key="pedid" width={'auto'}
                            render={(text) => {
                                return text
                            }}
                        />

                        <Column title="" width={40} dataIndex="pedid" key="pedid"
                            render={(text, record) => {
                                return record.pedid ? <Space size="middle" >
                                    <Button type={record?.status === 0 ? "primary" : 'dashed'}
                                        size="small" onClick={() => {
                                            // setIsModalOpen(true)
                                            getPedido(text)
                                        }}> {record?.status === 0 ? 'Confirmar' : 'Visualizar'}</Button>
                                </Space> : ''
                            }}
                        />

                    </Table>
                </div>

            </div>


            <Drawer title={<div>
                {"Detalhes do pedido Nº ".concat(pedido?.pedid)}
            </div>}
                placement="right" onClose={() => setPedido(undefined)}
                width={570}
                open={pedido}>
                {pedido && <Row gutter={[8, 8]}>
                    <Col span={24}><b>{pedido.usuario.nome}</b></Col>
                    <Col span={12}>

                        <Tag >
                            {pedido.type === 'DELIVERY' ? <img src={deliveryPNG} height={16} alt="deliveriImg" /> :
                                <img src={retiradaPNG} height={16} alt="imgretirada" />}
                            {pedido.type === 'PICKUP' ? "Retirada" : 'Delivery'} </Tag>
                        {/*<Tag color={pedido.type === 'DELIVERY' ? 'blue' : 'green'}>{pedido.type === 'PICKUP' ? "Retirada" : 'Delivery'}</Tag>*/}
                        {renderTagStatus(pedido.status)}

                    </Col>
                    <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                        {getDiaSemana(pedido.datahora.substring(0, 19))}
                    </Col>
                    <Col span={24}>
                        {((pedido.status === 2 || pedido.status === 3) && pedido.motivocancelamento) && <Tag color={pedido.status === 2 ? 'magenta' : 'orange'}> {pedido.motivocancelamento} </Tag>}
                    </Col>

                    <Col span={24} style={{ backgroundColor: 'white' }}>
                        <Collapse ghost style={{ marginTop: -10, marginLeft: -30 }}>
                            <Panel header={<b>Historico</b>} key="1" style={{ marginTop: -10 }}>
                                <Steps direction='horizontal' size='small' style={{ marginTop: -15 }}
                                    current={pedido.history.length - 1}
                                    progressDot>
                                    {renderSteps()}
                                </Steps>
                            </Panel>
                        </Collapse>
                    </Col>



                    {
                        pedido.endereco.logradouro && <Col span={24}>
                            <Row gutter={[8, 0]}>
                                <Col span={16} style={{ fontWeight: 'bold' }}>Endereço</Col>
                                <Col span={8} style={{ fontWeight: 'bold' }}>Complemento</Col>
                                <Col span={16}>
                                    {pedido.endereco.logradouro.concat(', ', pedido.endereco.numero)}
                                    {/* <Input size="small" value={pedido.endereco.logradouro.concat(', ', pedido.endereco.numero,)} /> */}
                                </Col>
                                <Col span={8}>
                                    {pedido.endereco.complemento}
                                    {/*<Input size="small" value={pedido.endereco.complemento} />*/}
                                </Col>

                                <Col span={8} style={{ fontWeight: 'bold' }}>Bairro</Col>
                                <Col span={8} style={{ fontWeight: 'bold' }}>Referencia</Col>
                                <Col span={8} style={{ fontWeight: 'bold' }}>Localidade</Col>
                                <Col span={8}>
                                    {pedido.endereco.bairro}
                                    {/*<Input size="small" value={pedido.endereco.bairro} />*/}
                                </Col>
                                <Col span={8}>
                                    {pedido.endereco.referencia}
                                    {/*<Input size="small" value={pedido.endereco.referencia} />*/}
                                </Col>
                                <Col span={8}>
                                    {pedido.endereco.localidade}
                                    {/*<Input size="small" value={pedido.endereco.localidade} />*/}</Col>
                            </Row>




                        </Col>
                    }
                    <Col span={24}>
                        <Table size="small" dataSource={pedido.itens} pagination={false} rowKey={'pedid'}
                            footer={() =>
                                <Row>
                                    <Col span={14}>Subtotal</Col>
                                    <Col span={10} className="pedidos-col-value">
                                        R$ {(pedido.valor - pedido.taxa + (pedido.cupom !== undefined ? pedido.cupom.valor : 0)
                                            + (pedido.fidelidade !== undefined ? pedido.fidelidade.valor : 0)).toFixed(2).toString()}
                                    </Col>
                                    <Col span={24}>
                                        <Divider style={{ marginBottom: 2, marginTop: 2 }} />
                                    </Col>
                                    {
                                        pedido.cupom && <Col span={14}>Cupom</Col>
                                    }
                                    {
                                        pedido.cupom && <Col span={10} className="pedidos-col-value">- R$ {pedido.cupom.valor.toFixed(2)}</Col>
                                    }
                                    {
                                        pedido.cupom && <Divider style={{ marginBottom: 2, marginTop: 2 }} />
                                    }

                                    {
                                        pedido.fidelidade && <Col span={14}>{pedido.fidelidade.tag}</Col>
                                    }
                                    {
                                        pedido.fidelidade && <Col span={10} className="pedidos-col-value">- R$ {pedido.fidelidade.valor.toFixed(2)}</Col>
                                    }
                                    {
                                        pedido.fidelidade && <Divider style={{ marginBottom: 2, marginTop: 2 }} />
                                    }

                                    <Col span={14}>Taxa Delivery</Col>
                                    <Col span={10} className="pedidos-col-value">
                                        +R$ {pedido.taxa.toFixed(2)}
                                    </Col>

                                    <Col span={24}>
                                        <Divider style={{ marginBottom: 2, marginTop: 2 }} />
                                    </Col>

                                    <Col span={14}><b>Total</b></Col>
                                    <Col span={10} className="pedidos-col-value">
                                        <b>  R$ {pedido.valor.toFixed(2)}</b>
                                    </Col>
                                </Row>

                            }>
                            <Column title="Quant" dataIndex="Quant" key="Quant" width={40} />
                            <Column title="Produto" dataIndex="NomeReduzido" key="NomeReduzido"
                                render={(text, it) => {
                                    return <Row>
                                        <Col span={24}>
                                            {text}
                                        </Col>
                                        {it.complementos.map(c => {
                                            return <Col span={24} style={{ color: 'gray', marginLeft: 5, marginBottom: 3 }}>
                                                <div color="geekblue" style={{ fontSize: 10, fontStyle: 'italic', color: 'gray' }}>
                                                    {parseInt(c.Quant).toString().concat('  X  ', c.NomeReduzido)}
                                                </div>
                                            </Col>
                                        })
                                        }
                                    </Row>
                                }} />
                            <Column title="Total" width={80} dataIndex="valorUnitarioFinal" key="valorUnitarioFinal"
                                render={(text, record) => {
                                    return (parseFloat(text) * parseFloat(record.Quant)).toFixed(2)
                                }} />
                        </Table>
                    </Col>
                </Row>
                }

                <Row>
                    <Col span={24}>
                        {pedido?.status === 0 && <div style={{
                            display: 'flex', marginTop: 20, flexDirection: 'row-reverse',
                        }}>
                            <Button type='primary' disabled={loading}
                                onClick={() => confirmarPedido(pedido)}>Confirmar pedido</Button>

                            <Button type='default' danger style={{ marginRight: 20 }}
                                onClick={() => {
                                    // setPedido(undefined)
                                    //  console.log('cancelar informar motivo')
                                    setIsModalOpen(true)
                                }}
                                disabled={loading}>Cancelar</Button>

                        </div>}
                    </Col>

                    <Col span={24}>
                        {pedido?.status === 1 && <div style={{
                            display: 'flex', marginTop: 20, flexDirection: 'row-reverse',
                        }}>
                            <Button type='primary' disabled={loading}
                                onClick={() => emEntrega(pedido)}>
                                {pedido.type === 'DELIVERY' ? 'Saiu para entrega' : 'Pronto para retirar'}
                            </Button>
                        </div>}
                    </Col>

                    <Col span={24}>
                        {(pedido?.status === 4 || pedido?.status === 5) && <div style={{
                            display: 'flex', marginTop: 20, flexDirection: 'row-reverse',
                        }}>
                            <Button type='primary' disabled={loading}
                                onClick={() => finalizado(pedido)}>
                               Finalizado
                            </Button>
                        </div>}
                    </Col>



                </Row>



            </Drawer>






        </div>
    }

    async function handleOk() {
        //  console.log(motivo)
        cancelarPedido(pedido)

    }

    function renderMobile() {
        return <div style={{ backgroundColor: 'white' }}>           
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10, width: '100%' }}>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Typography.Title level={3}>Gestor de Pedidos</Typography.Title>
                    <Tag color='geekblue' style={{marginLeft:10}}>Beta</Tag>
                </div>

                <div >
                    <Table dataSource={pedidos} size="small" loading={false}
                        pagination={{
                            defaultPageSize: 20,
                            pageSize: 50

                        }}
                        footer={() => {
                            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                <div>Pedidos: <strong> {pedidos.length} </strong></div>
                                <div>Total R$: <strong> {pedidos.reduce((partialSum, a) => partialSum + a.valor, 0).toFixed(2)} </strong></div>
                                <div>Confir. R$: <strong> {pedidos.reduce((partialSum, a) => (a.status !== 3 && a.status !== 2 && a.status !== 0) ?
                                    (partialSum + a.valor) :
                                    (partialSum + 0), 0).toFixed(2)}
                                </strong>
                                </div>
                            </div>
                        }} rowKey={"pedid"} style={{ width: '100%' }}>
                        <Column title="Hora" dataIndex="dataserver" key="dataserver"
                            
                            render={(text, record) => {
                                return record.pedid ? moment(text, 'YYYY-MM-DD HH:mm').format('HH:mm') : ''
                            }} />

                        <Column title="Pedido" dataIndex="pedid" key="pedid"                           
                            render={(text, record) => {
                                return record.pedid ? <Space> <Tag color="warning">
                                    {record.type === 'DELIVERY' ? <img src={deliveryPNG} height={16} alt="deliveriImg" /> :
                                        <img src={retiradaPNG} height={16} alt="imgretirada" />}
                                    {text}</Tag>
                                    {renderTagStatus(record.status)}
                                    

                                </Space> : 'Registros: '.concat(pedidos?.length)
                            }}
                        />                       

                        


                        <Column title="Cliente" dataIndex="nome" key="pedid" width={'auto'}
                            render={(text) => {
                                return text
                            }}
                        />

                        <Column title="" width={40} dataIndex="pedid" key="pedid"
                            render={(text, record) => {
                                return record.pedid ? <Space size="middle" >
                                    <Button type={record?.status === 0 ? "primary" : 'primary'}
                                    style={{marginRight:10}} 
                                        size="small" onClick={() => {
                                            // setIsModalOpen(true)
                                            getPedido(text)
                                        }}> {record?.status === 0 ? 'Ver' : 'Ver'}</Button>
                                </Space> : ''
                            }}
                        />

                    </Table>
                </div>

            </div>


            <Drawer title={<div>
                {"Detalhes do pedido Nº ".concat(pedido?.pedid)}
            </div>}
                placement="right" onClose={() => setPedido(undefined)}
                width={'100%'}
                visible={pedido}>
                {pedido && <Row gutter={[8, 8]}>
                    <Col span={24}><b>{pedido.usuario.nome}</b></Col>
                    <Col span={12}>

                        <Tag >
                            {pedido.type === 'DELIVERY' ? <img src={deliveryPNG} height={16} alt="deliveriImg" /> :
                                <img src={retiradaPNG} height={16} alt="imgretirada" />}
                            {pedido.type === 'PICKUP' ? "Retirada" : 'Delivery'} </Tag>
                        {/*<Tag color={pedido.type === 'DELIVERY' ? 'blue' : 'green'}>{pedido.type === 'PICKUP' ? "Retirada" : 'Delivery'}</Tag>*/}
                        {renderTagStatus(pedido.status)}

                    </Col>
                    <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                        {getDiaSemana(pedido.datahora.substring(0, 19))}
                    </Col>
                    <Col span={24}>
                        {((pedido.status === 2 || pedido.status === 3) && pedido.motivocancelamento) && <Tag color={pedido.status === 2 ? 'magenta' : 'orange'}> {pedido.motivocancelamento} </Tag>}
                    </Col>

                    <Col span={24} style={{ backgroundColor: 'white' }}>
                        <Collapse ghost style={{ marginTop: -10, marginLeft: -30 }}>
                            <Panel header={<b>Historico</b>} key="1" style={{ marginTop: -10 }}>
                                <Steps direction='horizontal' size='small' style={{ marginTop: -15 }}
                                    current={pedido.history.length - 1}
                                    progressDot>
                                    {renderSteps()}
                                </Steps>
                            </Panel>
                        </Collapse>
                    </Col>



                    {
                        pedido.endereco.logradouro && <Col span={24}>
                            <Row gutter={[8, 0]}>
                                <Col span={16} style={{ fontWeight: 'bold' }}>Endereço</Col>
                                <Col span={8} style={{ fontWeight: 'bold' }}>Complemento</Col>
                                <Col span={16}>
                                    {pedido.endereco.logradouro.concat(', ', pedido.endereco.numero)}
                                    {/* <Input size="small" value={pedido.endereco.logradouro.concat(', ', pedido.endereco.numero,)} /> */}
                                </Col>
                                <Col span={8}>
                                    {pedido.endereco.complemento}
                                    {/*<Input size="small" value={pedido.endereco.complemento} />*/}
                                </Col>

                                <Col span={8} style={{ fontWeight: 'bold' }}>Bairro</Col>
                                <Col span={8} style={{ fontWeight: 'bold' }}>Referencia</Col>
                                <Col span={8} style={{ fontWeight: 'bold' }}>Localidade</Col>
                                <Col span={8}>
                                    {pedido.endereco.bairro}
                                    {/*<Input size="small" value={pedido.endereco.bairro} />*/}
                                </Col>
                                <Col span={8}>
                                    {pedido.endereco.referencia}
                                    {/*<Input size="small" value={pedido.endereco.referencia} />*/}
                                </Col>
                                <Col span={8}>
                                    {pedido.endereco.localidade}
                                    {/*<Input size="small" value={pedido.endereco.localidade} />*/}</Col>
                            </Row>




                        </Col>
                    }
                    <Col span={24}>
                        <Table size="small" dataSource={pedido.itens} pagination={false} rowKey={'pedid'}
                            footer={() =>
                                <Row>
                                    <Col span={14}>Subtotal</Col>
                                    <Col span={10} className="pedidos-col-value">
                                        R$ {(pedido.valor - pedido.taxa + (pedido.cupom !== undefined ? pedido.cupom.valor : 0)
                                            + (pedido.fidelidade !== undefined ? pedido.fidelidade.valor : 0)).toFixed(2).toString()}
                                    </Col>
                                    <Col span={24}>
                                        <Divider style={{ marginBottom: 2, marginTop: 2 }} />
                                    </Col>
                                    {
                                        pedido.cupom && <Col span={14}>Cupom</Col>
                                    }
                                    {
                                        pedido.cupom && <Col span={10} className="pedidos-col-value">- R$ {pedido.cupom.valor.toFixed(2)}</Col>
                                    }
                                    {
                                        pedido.cupom && <Divider style={{ marginBottom: 2, marginTop: 2 }} />
                                    }

                                    {
                                        pedido.fidelidade && <Col span={14}>{pedido.fidelidade.tag}</Col>
                                    }
                                    {
                                        pedido.fidelidade && <Col span={10} className="pedidos-col-value">- R$ {pedido.fidelidade.valor.toFixed(2)}</Col>
                                    }
                                    {
                                        pedido.fidelidade && <Divider style={{ marginBottom: 2, marginTop: 2 }} />
                                    }

                                    <Col span={14}>Taxa Delivery</Col>
                                    <Col span={10} className="pedidos-col-value">
                                        +R$ {pedido.taxa.toFixed(2)}
                                    </Col>

                                    <Col span={24}>
                                        <Divider style={{ marginBottom: 2, marginTop: 2 }} />
                                    </Col>

                                    <Col span={14}><b>Total</b></Col>
                                    <Col span={10} className="pedidos-col-value">
                                        <b>  R$ {pedido.valor.toFixed(2)}</b>
                                    </Col>
                                </Row>

                            }>
                            <Column title="Quant" dataIndex="Quant" key="Quant" width={40} />
                            <Column title="Produto" dataIndex="NomeReduzido" key="NomeReduzido"
                                render={(text, it) => {
                                    return <Row>
                                        <Col span={24}>
                                            {text}
                                        </Col>
                                        {it.complementos.map(c => {
                                            return <Col span={24} style={{ color: 'gray', marginLeft: 5, marginBottom: 3 }}>
                                                <div color="geekblue" style={{ fontSize: 10, fontStyle: 'italic', color: 'gray' }}>
                                                    {parseInt(c.Quant).toString().concat('  X  ', c.NomeReduzido)}
                                                </div>
                                            </Col>
                                        })
                                        }
                                    </Row>
                                }} />
                            <Column title="Total" width={80} dataIndex="valorUnitarioFinal" key="valorUnitarioFinal"
                                render={(text, record) => {
                                    return (parseFloat(text) * parseFloat(record.Quant)).toFixed(2)
                                }} />
                        </Table>
                    </Col>
                </Row>
                }

                <Row>
                    <Col span={24}>
                        {pedido?.status === 0 && <div style={{
                            display: 'flex', marginTop: 20, flexDirection: 'row-reverse',
                        }}>
                            <Button type='primary' disabled={loading}
                                onClick={() => confirmarPedido(pedido)}>Confirmar pedido</Button>

                            <Button type='default' danger style={{ marginRight: 20 }}
                                onClick={() => {
                                    // setPedido(undefined)
                                    //  console.log('cancelar informar motivo')
                                    setIsModalOpen(true)
                                }}
                                disabled={loading}>Cancelar</Button>

                        </div>}
                    </Col>

                    <Col span={24}>
                        {pedido?.status === 1 && <div style={{
                            display: 'flex', marginTop: 20, flexDirection: 'row-reverse',
                        }}>
                            <Button type='primary' disabled={loading}
                                onClick={() => emEntrega(pedido)}>
                                {pedido.type === 'DELIVERY' ? 'Saiu para entrega' : 'Pronto para retirar'}
                            </Button>
                        </div>}
                    </Col>

                    <Col span={24}>
                        {(pedido?.status === 4 || pedido?.status === 5) && <div style={{
                            display: 'flex', marginTop: 20, flexDirection: 'row-reverse',
                        }}>
                            <Button type='primary' disabled={loading}
                                onClick={() => finalizado(pedido)}>
                               Finalizado
                            </Button>
                        </div>}
                    </Col>



                </Row>



            </Drawer>
        </div>
    }


}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    pedidos: state.pedidosReducer.pedidos
})

const mapDispatchToProps = dispatch => ({
    setPedidos: (pedidos) => dispatch(ActionPedidos.save_pedidos(pedidos)),
    saveExecutando: (executando) => dispatch(ActionPedidos.save_executando(executando))
})

export default connect(mapStateToProps, mapDispatchToProps)(Gestor)
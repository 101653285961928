import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../admin.css'
import '../../view/config/config.css'
import { api } from '../../services/api.service'
import { setNotification, getPermissoes, ADMINISTRADOR, GERENTE } from '../../services/dal.service'
import {
  Button, Card, Input, Upload, message, Avatar, Tag, Switch, Space, List, ColorPicker, Select,
  App
} from 'antd'
//import ilustracao from '../../img/ilustracoes/undraw_Setup_wizard_re_nday.png'
import { isMobile } from "react-device-detect";
import ImgCrop from 'antd-img-crop';
import { BASEURL } from '../../services/api.service'
import { CameraOutlined, StarFilled, EditFilled } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { valorMonetarioOnChange } from '../../services/util'
import BRASILPNG from '../../img/brasil.png'
import TelefoneBrasileiroInput from 'react-telefone-brasileiro'
import * as ActionToken from '../../store/actions/token.action'
import './home.css'

const heightFundo = 360
const widthFundo = 1400

export const Home = (props) => {

  const { token, saveTokenFull, tokenFull } = props
  const [config, setConfig] = useState({})
  const [loading, setLoading] = useState(true)
  const [salvando, setSalvando] = useState(false)
  const navigate = useNavigate()
  const [shoptypes, setShoptypes] = useState([])
  const { modal } = App.useApp()
  const permissions = getPermissoes(tokenFull)

  useEffect(() => {
    loadShopTypes()
    load()

  }, [])

  async function load() {
    try {

      var response = await api.get('/api/v2.0/config', {
        headers: {
          'x-access-token': token,
        },
      })
     // console.log(response.data)
      setConfig(response.data)
      setLoading(false)
    } catch (error) {
      // console.log('error', error.message)
      setNotification(error.message, 'error')
      navigate('/login')
    }
  }

  async function loadShopTypes() {
    try {

      var response = await api.get('/api/v2.0/config/shoptypes', {
        headers: {
          'x-access-token': token,
        },
      })
      setShoptypes(response.data)

    } catch (error) {
    }
  }




  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };


  function beforeUpload(file) {

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      console.log('Você só pode fazer upload de imagem JPG/PNG You can only upload JPG/PNG file!')
      message.error('Você só pode fazer upload de imagem JPG/PNG You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 / 1024 < 3;
    if (!isLt2M) {
      console.log('A imagem deve ser menor que 3MB!')
      message.error('A imagem deve ser menor que 3MB!');
    }
    console.log('beforeUpload', isJpgOrPng, isLt2M)
    return isJpgOrPng && isLt2M;
  }


  const handleChangeUpload = info => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false)
      setConfig({ ...config, Url: BASEURL.concat('background/', info.fileList[0].response.filename) })

    }

    if (info.file.status === 'error') {
      message.error('Erro ao fazer upload da imagem! Verifique se a extensão da imagem está no formato .png ou .jpg')
      // Get this url from response in real world.
      // console.log('info.file', info.file)
      setLoading(false)
      return;
    }

  };


  const handleChangeUploadLogo = info => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false)
      setConfig({ ...config, logo: BASEURL.concat('logos/', info.fileList[0].response.filename) })

    }

    if (info.file.status === 'error') {
      message.error('Erro ao fazer upload da imagem! Verifique se a extensão da imagem está no formato .png ou .jpg')
      // Get this url from response in real world.
      // console.log('info.file', info.file)
      setLoading(false)
      return;
    }

  };



  async function update() {
    try {

      if (!permissions?.includes(GERENTE)) {
        modal.warning({
          title: "Acesso negado!",
          content: "Você não possui permissão para executar essa operação! "
        })
        return
      }

      setSalvando(true)
      await api.put('/api/v2.0/config2', config, {
        headers: {
          'x-access-token': token,
        },
      })
      setNotification('Suas definições foram atualizadas!', 'success')
      ///api/v2.0/usuarios/refreshtoken
      const respresfresh = await api.post('api/v2.0/usuarios/refreshtoken', null, {
        headers: {
          'x-access-token': token,
        },
      })

      saveTokenFull(respresfresh.data)

    } catch (error) {
      // console.log('error', error.message)
      setNotification(error.message, 'error')
    }
    setSalvando(false)
  }





  return (<Card className='meu-card-home' style={{ margin: isMobile ? 0 : 5, padding:0}}>
    <div>
      <div style={{
        height: 150,
        backgroundColor: 'gray',
        backgroundImage: `url(` + config?.Url + `)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>

        <div style={{ position: 'relative' }}>
          <div style={{
            position: 'absolute',
            backgroundColor: 'transparent',
            top: 85,
            left: 30,
            display: 'flex',
            flexDirection: 'row'
          }}>
            {
              permissions?.includes(ADMINISTRADOR) ?

                <ImgCrop className="crop" aspect={96 / 96}>
                  <Upload
                    multiple={false}
                    headers={
                      {
                        'x-access-token': token,
                      }
                    }
                    name="file"
                    maxCount={1}
                    showUploadList={false}
                    action={BASEURL.concat('api/v2.0/uploads/uploadImageLogo')}
                    beforeUpload={beforeUpload}
                    onChange={handleChangeUploadLogo}
                    onPreview={onPreview}>
                    <div style={{ position: 'relative', backgroundColor: 'transparent' }}>
                      <Avatar src={config?.logo} size={120} shape='square' style={{ backgroundColor: 'transparent' }} />
                      <Avatar size={'small'}
                        style={{
                          backgroundColor: 'royalblue',
                          position: 'absolute',
                          top: 6,
                          right: 6,
                          cursor: 'pointer'
                        }}>
                        <EditFilled style={{ color: 'white' }} />
                      </Avatar>
                    </div>
                  </Upload>
                </ImgCrop>
                :
                <Avatar src={config?.logo} size={120} shape='square' style={{ backgroundColor: 'transparent' }} />
            }
            <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {!isMobile && <Input id="outlined-basic"
                  disabled={!permissions?.includes(ADMINISTRADOR)}
                  placeholder='Nome fantasia'
                  style={{ minWidth: 300 }}
                  value={config?.Fantasia} onChange={e => setConfig({ ...config, Fantasia: e.target.value })} />}

                <Tag style={{ marginLeft: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <StarFilled style={{ color: 'yellow', fontSize: 20, marginRight: 5, marginLeft: 5 }} />
                  <Switch size='small' style={{ marginRight: 5 }}
                    disabled={!permissions?.includes(GERENTE)}
                    checked={config?.canavalia === 1}
                    onChange={(value) => {
                      if (!permissions?.includes(GERENTE)) {
                        modal.warning({
                          title: "Acesso negado!",
                          content: "Você não possui permissão para executar essa operação! "
                        })
                        return
                      }
                      setConfig({ ...config, canavalia: config?.canavalia === 1 ? 0 : 1 })
                    }} />
                </Tag>
              </div>
            </div>
          </div>
          {permissions?.includes(ADMINISTRADOR) &&
            <div style={{
              position: 'absolute',
              top: 115,
              right: 20,
              display: 'flex',
              flexDirection: 'row'
            }} >
              <ImgCrop className="crop" aspect={widthFundo / heightFundo}>
                <Upload
                  multiple={false}
                  headers={
                    {
                      'x-access-token': token,
                    }
                  }
                  name="file"
                  maxCount={1}
                  showUploadList={false}
                  action={BASEURL.concat('api/v2.0/uploads/uploadImageFundo')}
                  beforeUpload={beforeUpload}
                  onChange={handleChangeUpload}
                  onPreview={onPreview}>
                  <Button size='small' icon={<CameraOutlined />}>

                    {isMobile ? "Editar 1380 x 350px" : "Editar imagem 1380 x 350px"}</Button>
                </Upload>
              </ImgCrop>
            </div>
          }
        </div>

      </div>


      <List style={{ marginTop: 70, marginBottom: isMobile ? 30 : 0 }} bordered size='small'>
        {isMobile && <List.Item actions={[
          <Input id="outlined-basic"
            disabled={!permissions?.includes(GERENTE)}
            value={config?.Fantasia} onChange={e => setConfig({ ...config, Fantasia: e.target.value })} />
        ]}>
          <List.Item.Meta
            title={"Empresa"}
          />
        </List.Item>
        }


        <List.Item actions={[
          <Select value={config?.shoptypeid}
            disabled={!permissions?.includes(GERENTE)}
            style={{ minWidth: 150 }}
            onChange={(value) => setConfig({ ...config, shoptypeid: value })}>
            {
              shoptypes?.map((item, index) => {
                return <Select.Option key={"shotype".concat(index)} value={item.id}>
                  {item.nome}
                </Select.Option>
              })
            }
          </Select>
        ]}>
          <List.Item.Meta
            title="Segmento"
            description={isMobile ? null : "Informe qual tipo de loja é o seu negócio."}
          />
        </List.Item>


        <List.Item actions={[
          <Select value={config?.tempo1}
            disabled={!permissions?.includes(GERENTE)}
            style={{ minWidth: 150 }}
            onChange={(value) => setConfig({ ...config, tempo1: value })}>
            <Select.Option value={"15-30"}>15-30 min</Select.Option>
            <Select.Option value={"30-45"}>30-45 min</Select.Option>
            <Select.Option value={"45-60"}>45-60 min</Select.Option>
            <Select.Option value={"60-1h30"}>60-1h30 min</Select.Option>
            <Select.Option value={"10-25"}>10-25 min</Select.Option>
          </Select>
        ]}>
          <List.Item.Meta
            title="Tempo de entrega"
            description={isMobile ? null : "Em média qual o tempo de entrega do pedido."}
          />
        </List.Item>




        <List.Item actions={[
          <Input
            disabled={!permissions?.includes(GERENTE)}
            style={{ textAlign: 'right', width: 130 }}
            value={config?.minimo}
            defaultValue={config?.minimo}
            onChange={e => {
              const value = valorMonetarioOnChange(e)
              setConfig({ ...config, minimo: value })
            }} />
        ]}>
          <List.Item.Meta
            title={"Pedido mínimo"}
            description={isMobile ? null : "O valor minimo em reais do pedido não incluso descontos e/ou taxa de entrega"}
          />
        </List.Item>



        <List.Item actions={[
          <Switch style={{ marginRight: 5 }}
            disabled={!permissions?.includes(GERENTE)}
            checked={config?.exibirEndereco == 1 ? true : false}
            onChange={(e) => {
              setConfig({ ...config, exibirEndereco: e ? 1 : 0 })
            }} />
        ]}>
          <List.Item.Meta
            title="Exibir endereço"
            description={isMobile ? null : "Defina se o endereço será exibido no app/site."}
          />
        </List.Item>

        <List.Item actions={[
          <Switch style={{ marginRight: 5 }}
            disabled={!permissions?.includes(GERENTE)}
            checked={config?.retirarLocal == 1 ? true : false}
            onChange={(e) => {
              console.log(e)
              setConfig({ ...config, retirarLocal: e ? 1 : 0 })
            }} />
        ]}>
          <List.Item.Meta
            title="Retirar no balcão"
            description={isMobile ? null : "Configure se será permitido o cliente retirar o pedido direto no estabelecimento."}
          />
        </List.Item>

        <List.Item actions={[
          <ColorPicker disabledAlpha={true}
            disabled={!permissions?.includes(GERENTE)}
            format='hex'
            showText={false}
            value={config?.cor}
            onChange={(value => {
              setConfig({ ...config, cor: "#".concat(value.toHex()) })
            })} />
        ]}>
          <List.Item.Meta
            title="Cor padrão"
            description={isMobile ? null : "A cor principal do seu cardápio digital."}
          />
        </List.Item>

        <List.Item actions={[<Space size={'small'} direction='horizontal'>
          <Avatar shape='square' size={'small'} src={BRASILPNG} />
          {
            <TelefoneBrasileiroInput
              disabled={!permissions?.includes(GERENTE)}
              className={"telefone-brasileiro-input"}
              temDDD={true}
              separaDDD={true}
              style={{ textAlign: 'right', width: 130 }}
              value={config?.Telefone}
              defaultValue={config?.Telefone}
              onChange={e => {
                setConfig({ ...config, Telefone: e.target.value })
              }} />
          }

        </Space>
        ]}>
          <List.Item.Meta
            title="Telefone"
            description={isMobile ? null : "O telefone de contato que será exibido ao seu cliente."}
          />
        </List.Item>

        <List.Item actions={[<Space size={'small'} direction='horizontal'>
          {isMobile ? null : <Switch style={{ marginRight: 5 }}
            disabled={!permissions?.includes(GERENTE)}
            checked={config?.whatsappenable == 1 ? true : false}
            onChange={(e) => {
              setConfig({ ...config, whatsappenable: e ? 1 : 0 })
            }} />}
          <Avatar shape='square' size={'small'} src={BRASILPNG} style={{}} />
          {

            <TelefoneBrasileiroInput
              className={"telefone-brasileiro-input"}
              temDDD={true}
              separaDDD={true}
              disabled={config?.whatsappenable === 0 || !permissions?.includes(GERENTE)}
              style={{
                textAlign: 'right',
                width: 130
              }}
              value={config?.whatsappnumber}
              onChange={e => {
                setConfig({ ...config, whatsappnumber: e.target.value })
              }}>

            </TelefoneBrasileiroInput>

          }

        </Space>
        ]}>
          <List.Item.Meta
            title={ <Space direction='horizontal'>WhatsApp  {isMobile && <Switch style={{ marginRight: 5 }}
              disabled={!permissions?.includes(GERENTE)}
              checked={config?.whatsappenable == 1 ? true : false}
              onChange={(e) => {
                setConfig({ ...config, whatsappenable: e ? 1 : 0 })
              }} />}
              
              </Space>
            }

            description={isMobile ? null : "Exibir número e permitir que os clientes possam iniciar conversas."}
          />

         

        </List.Item>



        <List.Item actions={[
          <Button onClick={()=> load()}>Cancelar</Button>,
          <Button 
            disabled={!permissions?.includes(GERENTE)}
            type='primary'
            loading={salvando}
            onClick={() => update()}>Salvar</Button>

        ]}>
          <List.Item.Meta
            title=""
            description={isMobile ? null : ""}
          />
        </List.Item>




      </List>




    </div>
  </Card>
  )
}

const mapStateToProps = (state) => ({
  token: state.tokenReducer.token,
  tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = dispatch => ({
  //  saveToken: (token) => dispatch(ActionToken.save_token(token)),
  saveTokenFull: (token) => dispatch(ActionToken.save_token_full(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
import React, { useEffect } from "react";
import {
  Routes, Route, BrowserRouter,  Outlet,
  useNavigate
} from "react-router-dom";
import { connect } from 'react-redux'
import { Result, Button } from "antd";
import LayoutPortal from "./view/layoutPortal";
import LoginPage from './view/login/index';
import AuthorizationCode from './view/autorization-code/index';
import Gestor from './view/gestor/index'
import Schedule from './view/schedule/index'
import Fidelidade from './view/fidelidade/index'
import Cupons from "./view/cupons/index";
import Definicoes from './view/config/index'
import FormasdePagamento from './view/formas-de-pagamento/index'
import Notification from './view/notification/index'
import Cardapio from './view/cardapio/index'
import TaxasEntrega from './view/taxas-entrega'
import Clientes from './view/clientes/index'
import AutorizationMercadoPago from './view/autorizacionMercadopago'
import LoginApp from './view/loginEngfood'
import Forgot from "./view/forgot";
import Home from "./view/home";
import Plano from './view/plano'
import Myapp from "./view/myapp";
import Orders from "./view/orders";
import MinhaEquipe from './view/equipe'
import Estatistica from "./view/estatistica";
import Register from "./view/register";

function MyApp() {
  return <Outlet />
}


const Erro404 = () => {
  const navigate = useNavigate()
  return <Result
    status="404"
    title="404"
    subTitle="Desculpa, a pagina que você visitou não existe!"
    extra={<Button type="primary" onClick={() => {
      navigate("/login")
    }}>Voltar principal</Button>}
  />
}


export const Teste = (props) => {

  useEffect(() => {
    const urlParans = new URLSearchParams(window.location.search)
    console.log(urlParans.get('code'))
  }, [])

  return <div></div>
}



export const Rotas = (props) => {


  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="/" element={<MyApp />}>
          <Route path="/" element={<LayoutPortal />} >
            <Route path="/" element={<Home />} />
            <Route path="/plano" element={<Plano />} />
            <Route path="/produtos" element={<Cardapio />} />
            <Route path="/cardapio" element={<Cardapio />} />
            <Route path="/gestor" element={<Gestor />} />
            <Route path="/horarios" element={<Schedule />} />
            <Route path="/programa-de-fidelidade" element={<Fidelidade />} />
            <Route path="/cupons" element={<Cupons />} />
            <Route path="/localizacao" element={<Definicoes />} />
            <Route path="/formas-de-pagamento" element={<FormasdePagamento />} />
            <Route path="/campanhas" element={<Notification />} />            
            <Route path="/taxas-de-entrega" element={<TaxasEntrega />} />
            <Route path="/clientes" element={<Clientes />} />
            <Route path="/authorization-mercadopago" element={<AutorizationMercadoPago />} />  
            <Route path="/myapp" element={<Myapp />} />
            <Route path="/orders" element={<Orders />} /> 
            <Route path="/minha-equipe" element={<MinhaEquipe />} />   
            <Route path="/estatistica" element={<Estatistica />} />
            <Route path="/authorization-code" element={<AuthorizationCode/>} /> 
          </Route>

        </Route>
        
        <Route path="/login/app" element={<LoginApp />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Erro404 />} />
      </Routes>
    </BrowserRouter>
  )






  /*
  
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  
  
    const PrivateRoute = ({ Component, ...rest }) => {
  
      return <Route
        {...rest}
        render={props => {
          token ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
        }
      />
    };
  
    return <BrowserRouter>
      <PedidosService />
      <Suspense className="text-center" fallback={<div style={{height:'100vh', backgroundColor:'white'}}> 
      <Lottie style={{display:'flex', justifyContent:'center', alignItems:'center'}}
        options={defaultOptions}
        height={200}
        width={200}
      /> </div>}>
        <Switch>
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/gestor" component={Gestor} />
          <PrivateRoute exact path="/schedule" component={Schedule} />
          <PrivateRoute exact path="/bairros" component={Bairros} />
          <PrivateRoute exact path="/programa-de-fidelidade" component={Fidelidade} />
          <PrivateRoute exact path="/setup" component={Definicoes} />
          <PrivateRoute exact path="/formas-de-pagamento" component={FormasdePagamento} />
          <PrivateRoute exact path="/cardapio" component={Cardapio} />
          <PrivateRoute exact path="/clientes" component={Clientes} />
          <PrivateRoute exact path="/notification" component={Notification} />
          <PrivateRoute exact path="/cupom" component={Cupom} />
          <PrivateRoute exact path="/pedidos" component={Pedidos} />
          <Route exact path="/authorization-code" component={AuthorizationCode} />
          <Route exact path="/" component={SignInSide} />
          <Route path="/login" component={SignInSide} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  */

}

const mapStateToProps = (state) => ({
  token: state.tokenReducer.token,
  //pedidos: state.pedidosReducer.pedidos
})

const mapDispatchToProps = dispatch => ({
  //savePedidos: (pedidos) => dispatch(ActionPedidos.save_pedidos(pedidos)),
  //saveStatus: (status) => dispatch(ActionPedidos.save_status(status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Rotas)
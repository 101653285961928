import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Tabs, Switch, InputNumber, Input, Col, Row, List, App, Space, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Divider } from '@material-ui/core';
import { isBrowser } from "react-device-detect";
import TextField from '@mui/material/TextField';
import { valorMonetarioOnChange, valorNumericoOnChange, valorMonetarioOnPaste, valorMonetarioOnKeyPress } from '../../../services/util'


import '../../product/product.css'
import './grupo.css'

const { TabPane } = Tabs;
const { TextArea } = Input

export const Grupo = (props) => {

    // const { grupo } = props
    const [erro, setErro] = useState(false)
    const [grupo, setGrupo] = useState(props.grupo)
    //  const [openExcluirItem, setOpenExcluirItem] = useState(false)
    //  const [itemExcluir, setItemExcluir] = useState({})
    const { modal } = App.useApp()

    useEffect(() => {
        //   console.log('validar')
        validar()
    }, [grupo])


    function validar() {
        var erro = false
        try {
            erro = grupo.descricao.trim() === '' ? true : false
            grupo.itens.map(item => {
                if (item.deleted !== true) {
                    if (item.nome.trim() === '' || parseFloat(item?.valor) < 0) {
                        erro = true
                    }
                }
            })
        } catch (error) {
            erro = true
        }
        setErro(erro)
    }


    function getMinimoDisable() {
        if (grupo.obrigatorio === 0)
            return true
        else if (grupo.obrigatorio === 1 && grupo.chave === 99998) {
            return true
        }

        return false;
    }

    function setMinValue(value) {
        var minimo = 0
        if (grupo.obrigatorio === 1) {
            if (grupo.chave === 99998) {
                minimo = grupo.maximo
            } else {
                minimo = value ? value : 0;
            }
        } else {
            minimo = 0
        }
        if (minimo)
            if (grupo.maximo < minimo)
                minimo = grupo.maximo
        setGrupo({ ...grupo, minimo: minimo })
    }


    function setMaxValue(value) {
        var maximo = value ? value : 1
        var minimo = grupo.minimo
        if (grupo.obrigatorio === 1) {
            if (grupo.chave === 99998) {
                // maximo = value
                minimo = value;
            }
        }

        if (maximo)
            if (maximo < minimo)
                minimo = grupo.maximo
        setGrupo({ ...grupo, maximo: maximo, minimo: minimo })
        console.log('Maximo:', grupo, maximo)
    }


    return <div className="main">
        <div className={isBrowser ? "grupo-main" : "grupo-main-mobile"}>
            <div>
                <Tabs defaultActiveKey="1" onChange={() => { }}>
                    <TabPane tab="Detalhes" key="1">
                        <div>
                            <Row>
                                {
                                    /*
                                <Col span={24}>
                                    Descrição do Grupo
                                </Col>
                                    */
                                }

                                <Col span={24}>
                                    {
                                        /*
                                            <Input
                                        placeholder="Selecione seus complementos"
                                        value={grupo.descricao}
                                        fullWidth
                                        showCount
                                        maxLength={50}
                                        onChange={(e) => {
                                            setGrupo({ ...grupo, descricao: e.target.value })
                                        }} />
                                        */
                                    }
                                    <TextField
                                        placeholder='Selecione seus complementos'
                                        variant='outlined'
                                        size='small'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        value={grupo.descricao}
                                        maxRows={1}
                                        label="Descrição do Grupo"
                                        onChange={(e) => {
                                            setGrupo({ ...grupo, descricao: e.target.value })
                                        }}
                                    />

                                </Col>
                            </Row>

                        </div>
                        <br />
                        <div>
                            <List bordered size='small'>
                                <List.Item
                                    actions={[<Switch
                                        disabled={grupo.chave === 99998}
                                        checkedChildren="Obrigatório"
                                        unCheckedChildren=" Opcional "
                                        checked={grupo.chave === 99998 ? true : (grupo.obrigatorio === 0 ? false : true)}
                                        onChange={(e) => {
                                            if (e === true && grupo.minimo === 0) {
                                                setGrupo({ ...grupo, obrigatorio: (e === true ? 1 : 0), minimo: 1 })
                                            }
                                            else if (e === false) {
                                                setGrupo({ ...grupo, obrigatorio: e === true ? 1 : 0, minimo: 0 })

                                            } else {
                                                setGrupo({ ...grupo, obrigatorio: e === true ? 1 : 0 })
                                            }
                                        }} />]}>
                                    <List.Item.Meta
                                        title={"Obrigatoriedade"}
                                        description="Indique se esse grupo é necessário para pedir o produto."
                                    />
                                </List.Item>

                                <List.Item
                                    actions={[
                                        <div >
                                            <span style={{ marginRight: 5 }}>
                                                Mínimo
                                            </span>
                                            <InputNumber
                                                disabled={getMinimoDisable()}
                                                max={20}
                                                min={grupo.obrigatorio}
                                                value={grupo.minimo}
                                                defaultValue="1"
                                                onChange={(e) => {
                                                    setMinValue(e)
                                                    // setGrupo({ ...grupo, minimo: e })
                                                }}
                                            />

                                            <span style={{ marginLeft: 10, marginRight: 5 }}>
                                                Máximo
                                            </span>
                                            <InputNumber
                                                value={grupo.maximo}
                                                max={20}
                                                min={grupo.obrigatorio}
                                                onChange={(e) => {
                                                    setMaxValue(e)
                                                    //  setGrupo({ ...grupo, maximo: e })
                                                }}
                                                defaultValue="1"
                                            />
                                        </div>
                                    ]}>
                                    <List.Item.Meta
                                        title={"Quantidade"}
                                        description="Indique quantos itens podem ser selecionados."
                                    />
                                </List.Item>

                            </List>
                        </div>
                    </TabPane>


                    {/* ITENS DO GRUPO */}
                    <TabPane tab="Itens" key="2">
                        <div>
                            {
                                grupo.itens && grupo.itens.map((item, itemIndex) => {
                                    if (item.deleted === true)
                                        return

                                    return <React.Fragment key={"g-item-".concat(itemIndex)}>
                                        <div className="grupo-item-main">
                                            <div className="grupo-item-lines">
                                                <Row style={{ width: '100%' }}>
                                                    <Col span={24}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <TextField
                                                                placeholder='Nome do item'
                                                                variant='outlined'
                                                                size='small'
                                                                inputProps={{ maxLength: 50 }}
                                                                fullWidth
                                                                value={item.nome}
                                                                maxRows={1}
                                                                label="Nome do item"
                                                                onChange={(e) => {
                                                                    var newitens = Object.assign({}, grupo.itens)
                                                                    newitens[itemIndex].nome = e.target.value
                                                                    setGrupo({ ...grupo, itens: Object.values(newitens) })
                                                                }}
                                                            />
                                                            <Space size={'small'} direction='horizontal' 
                                                            style={{ marginLeft: 10 }}>
                                                                <TextField
                                                                    value={item?.valor}
                                                                    label=" Preço R$"
                                                                    variant='outlined'
                                                                    size='small'
                                                                    style={{
                                                                        width: 90
                                                                    }}
                                                                    onChange={(event) => {
                                                                        var value = valorMonetarioOnChange(event)
                                                                        var newitens = Object.assign({}, grupo.itens)
                                                                        newitens[itemIndex].valor = value //parseFloat(value)
                                                                        setGrupo({ ...grupo, itens: Object.values(newitens) })
                                                                    }}
                                                                />


                                                                <TextField
                                                                    size='small'
                                                                    label="Cód. Engfood"
                                                                    value={item?.produtoid}
                                                                    style={{ width: 100 }}
                                                                    inputProps={{
                                                                        maxlength: 8
                                                                    }}
                                                                    onChange={(e) => {
                                                                        var value = valorNumericoOnChange(e)
                                                                        var newitens = Object.assign({}, grupo.itens)
                                                                        newitens[itemIndex].produtoid = value
                                                                        setGrupo({ ...grupo, itens: Object.values(newitens) })
                                                                    }} />

                                                                <Switch
                                                                    style={{minWidth: 100 }}
                                                                    checkedChildren="Disponível"
                                                                    unCheckedChildren=" Pausado "
                                                                    checked={item.disponivel === 1}
                                                                    onChange={(e) => {
                                                                        var newitens = Object.assign({}, grupo.itens)
                                                                        newitens[itemIndex].disponivel = e === true ? 1 : 0
                                                                        setGrupo({ ...grupo, itens: Object.values(newitens) })
                                                                    }} />

                                                                <Button
                                                                    type='link'
                                                                    onClick={() => {
                                                                        var newitens = Object.assign({}, grupo.itens)
                                                                        modal.confirm({
                                                                            title: "Atenção",
                                                                            content: 'Deseja realmente excluir o item '
                                                                                .concat(newitens[itemIndex].nome, '?'),
                                                                            cancelText: "Cancelar",
                                                                            okText: "Sim, quero excluir",
                                                                            onOk: () => {
                                                                                newitens[itemIndex].deleted = true
                                                                                setGrupo({ ...grupo, itens: Object.values(newitens) })
                                                                            }
                                                                        })
                                                                    }}>
                                                                    <DeleteOutlined style={{fontSize:20, color:'gray'}}/>
                                                                </Button>


                                                            </Space>


                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row style={{ marginTop: 10, width: '100%' }}>
                                                    <Col span={24}>

                                                        <TextField
                                                            placeholder='Sem cebola e muito molho'
                                                            variant='outlined'
                                                            size='small'
                                                            fullWidth
                                                            inputProps={{ maxLength: 255 }}
                                                            value={item.info}
                                                            multiline
                                                            rows={2}
                                                            label="Informações sobre esse item"
                                                            onChange={(e) => {
                                                                var newitens = Object.assign({}, grupo.itens)
                                                                newitens[itemIndex].info = e.target.value
                                                                setGrupo({ ...grupo, itens: Object.values(newitens) })
                                                            }}
                                                        />


                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                                    </React.Fragment>
                                })
                            }

                            <Button
                                type="primary"
                                size='small'
                                danger
                                onClick={() => {
                                    var ordem = Math.max(...grupo.itens.map(item => item.ordem))
                                    if (ordem)
                                        ordem = ordem + 1
                                    else
                                        ordem = 0
                                    var newprod = {
                                        appgrupoid: null,
                                        chave: 0,
                                        disponivel: 1,
                                        grobalvalor: 1,
                                        idappgrupoitens: 0,
                                        idgrupo: grupo.idappgrupos,
                                        info: "",
                                        nome: "",
                                        ordem: ordem,
                                        path: "",
                                        produtoid: '',
                                        selected: 0,
                                        valor: 0,
                                        valornew: 0
                                    }
                                    var itens = Object.values(Object.assign({}, grupo.itens))
                                    itens.push(newprod)
                                    setGrupo({ ...grupo, itens: itens })

                                }}>+ adicionar item</Button>

                        </div>
                    </TabPane>


                </Tabs>
            </div>
        </div>
        <div className="product-footer">
            <Button danger
                onClick={() => props.setDrawerGrupo(false)}>
                Cancelar
            </Button>
            <Button style={{ marginLeft: 15, width: 100 }} type="primary"
                disabled={erro}
                onClick={() => {
                    console.log(grupo)
                    props.addGrupo(grupo)
                    props.setDrawerGrupo(false)
                }}>
                Processar
            </Button>
        </div>

        {
            /*
                <Dialog
                    maxWidth={'xs'}
                    open={openExcluirItem}
                    onClose={() => setOpenExcluirItem(false)}
                    aria-labelledby="max-width-dialog-title" >
                    <DialogTitle id="max-width-dialog-title">Atenção!</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {'Isso irá excluir o item '}{grupo.itens[itemExcluir]?.nome}<br />{'Deseja continuar?'} .
                        </DialogContentText>
                        <Button variant="contained" color="primary" size="small" fullWidth
                            onClick={() => {
                                var newitens = Object.assign({}, grupo.itens)
                                newitens[itemExcluir].deleted = true
                                setGrupo({ ...grupo, itens: Object.values(newitens) })
                                setOpenExcluirItem(false)
                            }}>
                            Confirmar</Button>
        
                        <Button variant="contained" color="secondary" size="small" fullWidth
                            style={{ marginTop: 10, marginBottom: 15 }}
                            onClick={() => setOpenExcluirItem(false)}>Cancelar</Button>
                    </DialogContent>
        
                </Dialog>
            */
        }



    </div>
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Grupo)

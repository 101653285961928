import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Card, Button, Table, Switch, Space, Tag, Modal, Select, Input, Avatar, App } from 'antd'
import { api } from '../../services/api.service'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { setNotification, getPermissoes, ADMINISTRADOR } from '../../services/dal.service'
import { useNavigate } from 'react-router-dom'
import TelefoneBrasileiroInput from 'react-telefone-brasileiro/dist/TelefoneBrasileiroInput'
import BRASILPNG from '../../img/brasil.png'
import { validateEmail } from '../../services/dal.service'
import ModalAtivar from '../../components/modalAtivar'
import { isDesktop, isMobile } from 'react-device-detect'

export const MinhaEquipe = (props) => {

    const { modal } = App.useApp()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { token, tokenFull } = props
    const [data, setData] = useState([])
    const [equipe, setEquipe] = useState(undefined)
    const [openAtivar, setOpenAtivar] = useState(false)

    const permissions = getPermissoes(tokenFull)

    useEffect(() => {
        // console.log(tokenFull)
        load()
    }, [])

    async function load() {
        try {
            setLoading(true)
            var response = await api.get('/api/v2.0/usuarios/equipe', {
                headers: {
                    'x-access-token': token,
                },
            })
            setData(response.data)
        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            navigate('/login')
        }
        setLoading(false)
    }


    async function save(item) {
        try {

            if (!permissions?.includes(ADMINISTRADOR)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }

            if (!item?.username) {
                return setNotification("O Nome é obrigatorio!", 'error')
            }
            if (!validateEmail(item?.email)) {
                return setNotification("Informe um e-mail válido!", 'error')
            }
            if (item.fone.trim().length !== 15) {
                return setNotification("Informe o número do telefone!", 'error')
            }
            if (!item?.senha) {
                return setNotification("O campo senha e obrigatório!", 'error')
            }
            if (item?.senha.length < 4) {
                return setNotification("O campo senha deve ter pelo menos 4 caracteres!", 'error')
            }

            await api.post('/api/v2.0/usuarios/equipe', item, {
                headers: {
                    'x-access-token': token,
                },
            })
            setNotification('Dados salvo com successo!', 'success')
            setEquipe(undefined)
            load()


        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            // navigate('/login')
        }
        setLoading(false)
    }

    async function saveStatus(item) {
        try {

            if (!permissions?.includes(ADMINISTRADOR)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }

            await api.put('/api/v2.0/usuarios/equipe', item, {
                headers: {
                    'x-access-token': token,
                },
            })
            setNotification('Dados salvo com successo!', 'success')
            load()

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            // navigate('/login')
        }
        setLoading(false)
    }


    return (
        <div style={{ margin: 10 }}>
            <Card >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: '1.2em', letterSpacing: .233, fontWeight: 600, marginBottom: 8 }}>Minha Equipe</span>
                    <Table size='small'
                        footer={null}
                        pagination={false}
                        bordered
                        rowKey={'createAt'}
                        dataSource={data}
                        loading={loading}>


                        {isDesktop && <Table.Column key={'createAt'} title="Usuário" dataIndex={'username'} render={(item, record) => {
                            return record.username
                        }} />
                        }
                        {isDesktop && <Table.Column key={'createAt'} title="E-mail" dataIndex={'email'} render={(item, record) => {
                            return record.email
                        }} />}

                        {isDesktop && <Table.Column key={'createAt'} title="Função" dataIndex={'status'} render={(item, record) => {
                            return <Tag color={record.idcargos !== 0 ? 'volcano-inverse' : 'cyan-inverse'}> {record.cargoname} </Tag>
                        }} />
                        }


                        {isMobile && <Table.Column key={'createAt'} title="Status" dataIndex={'status'}
                            render={(item, record, index) => {
                                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>{record.username}</div>
                                    <div>{record.email}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignItems:'center' }}>
                                        <Tag style={{minWidth:100, textAlign:'center'}} color={record.idcargos !== 0 ? 'volcano-inverse' : 'cyan-inverse'}>
                                            {record.cargoname}
                                        </Tag>
                                        <Space size={'small'} direction='horizontal'>
                                            {
                                                record.idcargos === 0 ?
                                                    tokenFull.cargosid === 0 ? <Button type='link'
                                                        onClick={() => navigate('/plano')}>Editar perfil</Button> : "" :
                                                    <Switch checked={record.ativo === 1} size='small'
                                                        onChange={(e) => {

                                                            if (!permissions?.includes(ADMINISTRADOR)) {
                                                                modal.warning({
                                                                    title: "Acesso negado!",
                                                                    content: "Você não possui permissão para executar essa operação! "
                                                                })
                                                                return
                                                            }

                                                            var _data = [...data]
                                                            var eq = _data[index]
                                                            if (eq.ativo === 0 && tokenFull.license === 'free') {
                                                                setOpenAtivar(true)
                                                                return
                                                            }

                                                            eq.ativo = e === true ? 1 : 0
                                                            saveStatus(eq)
                                                            //  setData(_data)
                                                        }}
                                                    />
                                            }{
                                                record.idcargos !== 0 && <Space direction='horizontal' style={{ marginLeft: 10 }}>
                                                    {permissions?.includes(ADMINISTRADOR) && <Button size='small' type='link'
                                                        onClick={() => {
                                                            if (!permissions?.includes(ADMINISTRADOR)) {
                                                                modal.warning({
                                                                    title: "Acesso negado!",
                                                                    content: "Você não possui permissão para executar essa operação! "
                                                                })
                                                                return
                                                            }
                                                            var _equipe = { ...record }
                                                            _equipe.senha = ""
                                                            setEquipe(_equipe)
                                                        }}><EditOutlined style={{ color: 'blue' }} /></Button>
                                                    }

                                                    {permissions?.includes(ADMINISTRADOR) && <Button size='small' type='link'
                                                        onClick={() => {

                                                            if (!permissions?.includes(ADMINISTRADOR)) {
                                                                modal.warning({
                                                                    title: "Acesso negado!",
                                                                    content: "Você não possui permissão para executar essa operação! "
                                                                })
                                                                return
                                                            }

                                                            modal.confirm({
                                                                title: "Atenção!",
                                                                content: <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <div>Deseja excluir o item selecionado?</div>
                                                                    <div style={{ color: 'gray' }}>Esta ação não pode ser desfeita.</div>
                                                                </div>,
                                                                okText: "Sim, quero excluir.",
                                                                onOk: () => {
                                                                    var _data = [...data]
                                                                    var eq = _data[index]
                                                                    eq.excluido = 1
                                                                    saveStatus(eq)
                                                                }
                                                            })
                                                        }}><DeleteOutlined style={{ color: 'red' }} /></Button>
                                                    }
                                                </Space>
                                            }
                                        </Space>

                                    </div>
                                </div>

                            }}
                        />
                        }

                        {isDesktop && <Table.Column key={'createAt'} title="Status" dataIndex={'status'}
                            render={(item, record, index) => {
                                return <Space size={'small'} direction='horizontal'>
                                    {
                                        record.idcargos === 0 ?
                                            tokenFull.cargosid === 0 ? <Button type='link'
                                                onClick={() => navigate('/plano')}>Editar perfil</Button> : "" :
                                            <Switch checked={record.ativo === 1} size='small'
                                                onChange={(e) => {

                                                    if (!permissions?.includes(ADMINISTRADOR)) {
                                                        modal.warning({
                                                            title: "Acesso negado!",
                                                            content: "Você não possui permissão para executar essa operação! "
                                                        })
                                                        return
                                                    }

                                                    var _data = [...data]
                                                    var eq = _data[index]
                                                    if (eq.ativo === 0 && tokenFull.license === 'free') {
                                                        setOpenAtivar(true)
                                                        return
                                                    }

                                                    eq.ativo = e === true ? 1 : 0
                                                    saveStatus(eq)
                                                    //  setData(_data)
                                                }}
                                            />
                                    }{
                                        record.idcargos !== 0 && <Space direction='horizontal' style={{ marginLeft: 10 }}>
                                            {permissions?.includes(ADMINISTRADOR) && <Button size='small' type='link'
                                                onClick={() => {
                                                    if (!permissions?.includes(ADMINISTRADOR)) {
                                                        modal.warning({
                                                            title: "Acesso negado!",
                                                            content: "Você não possui permissão para executar essa operação! "
                                                        })
                                                        return
                                                    }
                                                    var _equipe = { ...record }
                                                    _equipe.senha = ""
                                                    setEquipe(_equipe)
                                                }}><EditOutlined style={{ color: 'blue' }} /></Button>
                                            }

                                            {permissions?.includes(ADMINISTRADOR) && <Button size='small' type='link'
                                                onClick={() => {

                                                    if (!permissions?.includes(ADMINISTRADOR)) {
                                                        modal.warning({
                                                            title: "Acesso negado!",
                                                            content: "Você não possui permissão para executar essa operação! "
                                                        })
                                                        return
                                                    }

                                                    modal.confirm({
                                                        title: "Atenção!",
                                                        content: <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <div>Deseja excluir o item selecionado?</div>
                                                            <div style={{ color: 'gray' }}>Esta ação não pode ser desfeita.</div>
                                                        </div>,
                                                        okText: "Sim, quero excluir.",
                                                        onOk: () => {
                                                            var _data = [...data]
                                                            var eq = _data[index]
                                                            eq.excluido = 1
                                                            saveStatus(eq)
                                                        }
                                                    })
                                                }}><DeleteOutlined style={{ color: 'red' }} /></Button>
                                            }
                                        </Space>
                                    }
                                </Space>
                            }} />
                        }




                    </Table>

                    <div style={{marginTop:15}}>
                        {permissions?.includes(ADMINISTRADOR) && <Button type='primary'
                            style={{ width: 'auto', marginTop: 10, alignItems: 'center', display: 'flex' }}
                            onClick={() => {
                                setEquipe({
                                    excluido: 0,
                                    cargosid: 3,
                                    ativo: 0
                                })
                            }}>
                            <PlusOutlined />
                            Adicionar Usuário
                        </Button>
                        }
                    </div>
                </div>
            </Card>



            <Modal
                title="Controle de Equipe"
                width={400}
                open={equipe}
                onCancel={() => setEquipe(undefined)}
                closable={false}
                okText={"Salvar"}
                onOk={() => save(equipe)}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>Selecione a função</div>
                    <div>
                        <Select
                            style={{ width: '100%' }}
                            value={equipe?.cargosid}
                            onChange={(v) => setEquipe({ ...equipe, cargosid: v })}>
                            <Select.Option value={1}>Gerente</Select.Option>
                            <Select.Option value={2}>Vendas</Select.Option>
                            <Select.Option value={3}>Entregador</Select.Option>
                        </Select>
                    </div>
                    <div style={{ marginTop: 5 }}>
                        Nome
                    </div>
                    <div>
                        <Input
                            showCount={true}
                            maxLength={45} value={equipe?.username} onChange={(e) => setEquipe({ ...equipe, username: e.target.value })} />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        E-mail
                    </div>
                    <div>
                        <Input
                            value={equipe?.email}
                            onChange={(e) => setEquipe({ ...equipe, email: e.target.value.toLowerCase() })} />
                    </div>

                    <div style={{ marginTop: 5 }}>
                        Telefone  <Avatar shape='square' size={'small'} src={BRASILPNG} />
                    </div>
                    <div>
                        <TelefoneBrasileiroInput
                            className={"telefone-brasileiro-input"}
                            temDDD={true}
                            separaDDD={true}
                            style={{ width: '100%' }}
                            value={equipe?.fone}
                            defaultValue={equipe?.fone}
                            onChange={e => {
                                setEquipe({ ...equipe, fone: e.target.value })
                            }} />
                    </div>

                    <div style={{ marginTop: 5 }}>
                        Senha
                    </div>
                    <div>
                        <Input.Password
                            maxLength={20}
                            showCount={true}
                            value={equipe?.senha}
                            onChange={(e) => setEquipe({ ...equipe, senha: e.target.value })} />
                    </div>


                </div>

            </Modal>


            <Modal
                footer={null}
                open={openAtivar}
                closable={true}
                onCancel={() => setOpenAtivar(false)}>
                <ModalAtivar />
            </Modal>


        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MinhaEquipe)
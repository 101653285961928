export const Action_User_Types ={
    SAVE_USER:"SAVE_USER"
}

export const save_user = (user) => ({
    type: Action_User_Types.SAVE_USER,
    user
})




import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import '../admin.css'
import './config.css'
import { api } from '../../services/api.service'
import { setNotification, getPermissoes, GERENTE } from '../../services/dal.service'
import { Select, Button, Card, Col, Row, Input, message, Space, Drawer, Checkbox, App } from 'antd'
import ilustracao from '../../img/ilustracoes/undraw_business_shop_qw5t.png'
import { isBrowser, isMobile } from "react-device-detect";
import { BASEURL } from '../../services/api.service'
import {  LeftOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { GoogleMap, StandaloneSearchBox, useJsApiLoader, Marker, MarkerF } from '@react-google-maps/api';
import { GOOGLE_KEY } from '../../services/api.service'
import axios from 'axios'
import PIN_PRETO from '../../img/pinpreto.png'

const { Option } = Select


export const Config = (props) => {

    const [open, setOpen] = useState(false)
    const { token, tokenFull } = props
    const [config, setConfig] = useState({})
    const [loading, setLoading] = useState(true)
    const [enderecoTemp, setEnderecoTemp] = useState(undefined)
    const [modoEndereco, setModoEndereco] = useState(1)
    const inputRef = useRef();
    const navigate = useNavigate()

    const permissions = getPermissoes(tokenFull)
    const { modal } = App.useApp()

    const [libraries] = useState(['places']);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_KEY,
        libraries: libraries,
    });

    const [salvando, setSalvando] = useState(false)
    const [mapref, setMapRef] = useState(null);
    const [marker, serMarker] = useState(null);
    const [center, setCenter] = useState({
        lat: enderecoTemp?.latitude,
        lng: enderecoTemp?.longitude
    })
    const [markerVisible, setMarkerVisible] = useState(true)



    const containerStyle = {
        width: '90%',
        height: '300px',
        marginLeft: isMobile ? 0 : 30,
        marginTop: isMobile ? 20 : 0
    };

    useEffect(() => {
        load()
    }, [])


    async function salvar() {
        try {

            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }

            setSalvando(true)
            console.log(enderecoTemp)
            await api.put('/api/v2.0/update-localizacao', enderecoTemp, {
                headers: {
                    'x-access-token': token,
                },
            })
            setNotification('Suas definições foram atualizadas!', 'success')
            setOpen(false)
            setModoEndereco(1)
            load()

        } catch (error) {
            message.error(error.message)
        }
        setSalvando(false)
    }




    function handleCenterChanged() {
        if (mapref) {
            const newCenter = mapref.getCenter();
        }
    };

    const handleOnLoad = map => {
        setMapRef(map);
    };

    const onLoadMarker = marker => {
        serMarker(marker);
    };

    function renderModo() {
        switch (modoEndereco) {
            case 2:
                return renderModo2();
                break
            default:
                return renderModo1();
                break;
        }
    }


    function renderModo1() {
        return <div>
            {isLoaded && <StandaloneSearchBox
                onLoad={ref => inputRef.current = ref}
                debouce={600}
                onPlacesChanged={handlePlaceChanged}>
                <input
                    style={{ width: isMobile ? '100%' : '100%', padding: 5 }}
                    type="text"
                    className={"telefone-brasileiro-input"}
                    placeholder="Informe o endereço"
                    onChange={(e) => {
                        setEnderecoTemp(undefined)
                    }}
                />
            </StandaloneSearchBox>
            }

            {
                enderecoTemp?.Estado && <div>
                    <Row gutter={[8, 8]} style={{ marginTop: 10 }}>
                        <Col span={24}>
                            <Select
                                placeholder="Estado"
                                defaultValue={config?.Estado}
                                value={enderecoTemp?.Estado}
                                style={{ width: '100%' }}
                                onChange={(value) => {
                                    setEnderecoTemp({ ...enderecoTemp, Estado: value })
                                }}>

                                <Option value="AC">Acre</Option>
                                <Option value="AL" >Alagoas</Option>
                                <Option value="AM" >Amazonas</Option>
                                <Option value="AP" >Amapa</Option>
                                <Option value="BA" >Bahia</Option>
                                <Option value="CE" >Ceará</Option>
                                <Option value="DF" >Distrito Federal</Option>
                                <Option value="ES" >Espirito Santo</Option>
                                <Option value="GO" >Goias</Option>
                                <Option value="MA" >Maranhão</Option>
                                <Option value="MG" >Minhas Gerais</Option>
                                <Option value="MS" >Mato Grosso do Sul</Option>
                                <Option value="MT" >Mato Grosso</Option>
                                <Option value="PA" >Pará</Option>
                                <Option value="PB" >Paraíba</Option>
                                <Option value="PE" >Pernambuco</Option>
                                <Option value="PI" >Piauí</Option>
                                <Option value="PR" >Paraná</Option>
                                <Option value="RJ" >Rio de Janeiro</Option>
                                <Option value="RN" >Rio Grande do Norte</Option>
                                <Option value="RS" >Rio Grande do Sul</Option>
                                <Option value="RO" >Roraima</Option>
                                <Option value="RR" >Rondônia</Option>
                                <Option value="SC" >Santa Catarina</Option>
                                <Option value="SP" >São Paulo</Option>
                                <Option value="SE" >Sergipe</Option>
                                <Option value="TO" >Tocantins</Option>
                            </Select>
                        </Col>
                        <Col span={24}>
                            <Input placeholder="Cidade*" value={enderecoTemp?.Cidade}
                                onChange={(e) => setEnderecoTemp({ ...enderecoTemp, Cidade: e.target.value })} />
                        </Col>
                        <Col span={24}>
                            <Input placeholder="Bairro*" value={enderecoTemp.Bairro}
                                onChange={(e) => setEnderecoTemp({ ...enderecoTemp, Bairro: e.target.value })} />

                        </Col>
                        <Col span={12}><Input placeholder="Rua*" value={enderecoTemp.Endereco}
                            onChange={(e) => setEnderecoTemp({ ...enderecoTemp, Endereco: e.target.value })} /></Col>
                        <Col span={12}>
                            <Input placeholder="Número*"
                                disabled={enderecoTemp?.numero === 's/n'}
                                value={enderecoTemp.numero}
                                onChange={(e) => setEnderecoTemp({ ...enderecoTemp, numero: e.target.value })} /></Col>
                        <Col span={12}><Input placeholder="Complemento" value={enderecoTemp.complemento}
                            onChange={(e) => setEnderecoTemp({ ...enderecoTemp, complemento: e.target.value })} /></Col>
                        <Col span={12}><Input placeholder="Referência*" value={enderecoTemp.referencia}
                            onChange={(e) => setEnderecoTemp({ ...enderecoTemp, referencia: e.target.value })} /></Col>

                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Checkbox checked={enderecoTemp?.numero === 's/n'}
                                onChange={(e) => {
                                    //   console.log(e)
                                    if (e.target.checked)
                                        setEnderecoTemp({ ...enderecoTemp, numero: 's/n' })
                                    else
                                        setEnderecoTemp({ ...enderecoTemp, numero: '' })
                                }}>
                                Endereço sem número
                            </Checkbox>
                        </Col>

                        <Col span={24}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                <div className="address--buttons-item">
                                    <Button type="dashed" danger onClick={() => {
                                        setEnderecoTemp(undefined)
                                        setOpen(false)
                                    }}>Voltar</Button>
                                </div>
                                <div className="address--buttons-item">
                                    <Button type="primary" style={{ minWidth: 100 }}
                                        disabled={disableButtonSalvar()}
                                        onClick={() => {
                                            buscar()
                                        }}
                                    >Buscar</Button>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
            }


        </div>
    }

    function renderModo2() {
        return <div style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
        }}>
            {isLoaded && <GoogleMap
                onLoad={handleOnLoad}
                mapContainerStyle={containerStyle}
                center={center}
                options={{
                    disableDefaultUI: true,
                    clickableIcons: false
                }}
                zoom={17}
                onCenterChanged={handleCenterChanged}
                onDragStart={() => setMarkerVisible(false)}
                onDragEnd={() => {
                    if (mapref) {
                        const newCenter = mapref.getCenter();
                        var ed = { ...enderecoTemp }
                        ed.latitude = newCenter.lat()
                        ed.longitude = newCenter.lng()
                        setEnderecoTemp(ed)
                        setCenter({
                            lat: ed.latitude,
                            lng: ed.longitude
                        })
                    }
                    setMarkerVisible(true)
                }}>

                <MarkerF
                    onLoad={onLoadMarker}
                    visible={markerVisible}
                    position={center}>

                </MarkerF>


            </GoogleMap>
            }

            <img src={PIN_PRETO}
                height={36}
                width={36}
                hidden={markerVisible}
                style={{
                    left: '50%',
                    marginLeft: -18,
                    marginTop: -34,
                    position: 'absolute',
                    top: '50%'
                }}
            />

            <span hidden={!markerVisible} style={{
                width: 150,
                left: '50%',
                marginLeft: -75,
                marginTop: -102,
                position: 'absolute',
                top: '50%',
                backgroundColor: 'white',
                textAlign: 'center',
                paddingTop: 5,
                paddingBottom: 5
            }}>
                <form >
                    <div className='form-div'>Você está aqui?</div>
                    <div className='form-div-2'>Ajuste a localização</div>
                </form>
            </span>


            <div className='address-title'>
                <div className='address-title-1'>
                    {enderecoTemp?.Endereco.concat(" ", enderecoTemp?.numero)}
                </div>
                <div className='address-title-2'>
                    {enderecoTemp?.Bairro.concat(', ', enderecoTemp?.Bairro, "-", enderecoTemp?.Estado)}
                </div>
            </div>

            <div className='button-confirm'
                hidden={!markerVisible}>

                <Button
                    size='large'
                    style={{ marginRight: 10 }}
                    icon={<LeftOutlined />}
                    onClick={() => setModoEndereco(1)}
                >
                    Voltar
                </Button>

                <Button
                    type='primary'
                    size='large'
                    danger
                    loading={salvando}
                    disabled={disableButtonSalvar()}
                    onClick={() => salvar()}>
                    Confirmar localização
                </Button>
            </div>
        </div>
    }



    const handlePlaceChanged = () => {
        var end = {}
        const [place] = inputRef.current.getPlaces();
        if (place) {
            place.address_components.forEach(element => {
                if (element.types.find(e => 'route' === e)) { end.Endereco = element.long_name }
                else if (element.types.find(e => 'sublocality_level_1' === e || 'sublocality' === e)) { end.Bairro = element.long_name }
                else if (element.types.find(e => 'administrative_area_level_2' === e)) { end.Cidade = element.long_name }
                else if (element.types.find(e => 'administrative_area_level_1' === e)) { end.Estado = element.short_name }
                else if (element.types.find(e => 'country' === e)) { end.pais = element.long_name }
                else if (element.types.find(e => 'postal_code' === e)) { end.cep = element.long_name }
                else if (element.types.find(e => 'street_number' === e)) { end.numero = element.long_name }
            });
            const lat = place.geometry.location.lat()
            const lng = place.geometry.location.lng()
            end.longitude = lng
            end.latitude = lat
            console.log(place.address_components)
            setEnderecoTemp(end)
            // var distanceInKm = distance(loja?.informacoes?.latitude, loja?.informacoes?.longitude, lat, lng)
            // console.log(`A distância entre os pontos é de ${distanceInKm} km.`);
        } else {
            setEnderecoTemp(undefined)
        }
    }



    async function load() {
        try {
            var response = await api.get('/api/v2.0/config', {
                headers: {
                    'x-access-token': token,
                },
            })
            setConfig(response.data)
            setLoading(false)
        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            navigate('/login')
        }
    }



    return isBrowser ? renderDesktop() : renderMobile()


    function renderDesktop() {
        return <div className="app-window">

            {renderContextArea()}
        </div>
    }

    function renderMobile() {
        return <div style={{ backgroundColor: 'white' }}>
            {
                renderContextArea()
            }
        </div>
    }


    function renderContextArea() {
        return <div className="contentarea">
            <Card title={
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img style={{ marginRight: 5 }} src={ilustracao} alt="img" height={50} />
                    <div >
                        <div><strong> Localização </strong></div>
                        <div style={{
                            display: 'flex', flexWrap: 'wrap',
                            color: 'darkgrey', fontSize: '.85em',
                            fontWeight: 'normal',
                            whiteSpace: 'initial'
                        }}>Informe  a localização do seu estabelecimento restaurante.</div>
                    </div>

                </div>
            } style={{ marginBottom: 15 }}>

                <Row>
                    <Col span={isMobile ? 24 : 12}>
                        {isLoaded && <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={{
                                lat: parseFloat(config?.latitude),
                                lng: parseFloat(config?.longitude)
                            }}
                            options={{
                                disableDefaultUI: true,
                                clickableIcons: false
                            }}
                            zoom={16}
                            onLoad={() => {
                            }}
                            onUnmount={() => {
                            }}
                        >
                            { /* Child components, such as markers, info windows, etc. */}
                            <>
                                <Marker position={{
                                    lat: parseFloat(config?.latitude),
                                    lng: parseFloat(config?.longitude)
                                }}></Marker>
                            </>
                        </GoogleMap>
                        }
                    </Col>
                    <Col span={isMobile ? 24 : 12}>
                        <div style={{ marginLeft: 30 }}>
                            <Space direction='vertical'>
                                <div style={{ fontSize: '1.5em', fontWeight: 600 }}>Endereço do Estabelecimento</div>
                                <div>{config?.Endereco?.concat(config?.numero ? ', '.concat(config?.numero) : '')}</div>
                                {config?.complemento && <div>{config?.complemento} </div>}
                                <div>{config?.Bairro?.concat(", ", config?.Cidade, "-", config?.Estado)}</div>
                                <div><Button size='large' type='primary' 
                                disabled={!permissions?.includes(GERENTE)}
                                    onClick={() => {
                                        if (!permissions?.includes(GERENTE)) {
                                            modal.warning({
                                                title: "Acesso negado!",
                                                content: "Você não possui permissão para executar essa operação! "
                                            })
                                            return
                                        }
                                        setModoEndereco(1)
                                        setOpen(true)
                                    }}>Alterar endereço</Button></div>
                            </Space>
                        </div>
                    </Col>
                </Row>


            </Card>


            <Drawer
                open={open}
                width={isMobile ? '100%' : 600}
                onClose={() => {
                    setOpen(false)
                    setModoEndereco(1)
                }}
                destroyOnClose={true}
                title={"Informe seu endereço"}>

                {renderModo()}

            </Drawer>


        </div>
    }

    function disableButtonSalvar() {
        try {
            // console.log('Endereco: ', enderecoTemp)
            if (!enderecoTemp?.Estado)
                return true
            if (!enderecoTemp?.Endereco)
                return true
            if (!enderecoTemp?.Bairro)
                return true
            if (!enderecoTemp?.Cidade)
                return true
            if (!enderecoTemp?.numero)
                return true
            if (!enderecoTemp?.referencia)
                return true

            return false

        } catch (error) {
            return true
        }
    }


    async function buscar() {
        try {

            var aux = enderecoTemp?.Endereco.concat(enderecoTemp?.numero === 's/n' ? ' ' : enderecoTemp?.numero,
                ", ", enderecoTemp?.Bairro, ",", enderecoTemp?.Cidade, '-', enderecoTemp?.Estado)
            var url = "https://maps.googleapis.com/maps/api/geocode/json?address=".concat(aux, "&key=", GOOGLE_KEY)
            const response = await axios.get(url);
            const data = response.data
            switch (data.status) {
                case "OK":
                    console.log(data.results[0].geometry.location)
                    var ed = { ...enderecoTemp }
                    ed.latitude = data.results[0].geometry.location.lat
                    ed.longitude = data.results[0].geometry.location.lng
                    setCenter({
                        lat: data.results[0].geometry.location.lat,
                        lng: data.results[0].geometry.location.lng
                    })
                    setEnderecoTemp(ed)
                    setModoEndereco(2)
                    break;
                case "ZERO_RESULTS": message.warning("Não foram localizados endereços para os dados informados!"); break;
                case "OVER_QUERY_LIMIT": message.warning("Desculpe, o serviço não está conseguindo localizar os dados!"); break;
                case "REQUEST_DENIED": message.warning("A solicitação foi negada pelo serviço do GOOGLE, verifique se os dados estão corretos e tente novamente!"); break;
                case "INVALID_REQUEST": message.warning("Erro ao efetuar solicitação, verifique se os dados do endereço estão corretos!"); break;
                case "UNKNOWN_ERROR": message.warning("Solicitação não foi processada devido a um erro de servidor. Tente novamente"); break;
                case "ERROR": message.error("Solicitação expirou ou houve um problema de contato com os servidores do Google, tente novamente em instantes!"); break;
                default: message.error("Erro desconhecido, tente novamente em instantes! ");
            }



        } catch (error) {
            // console.log()
            message.error(error.message)
        }
    }

}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(Config)

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './estatistica.css'
import { Card, DatePicker, Space, Progress, Row, Col, ConfigProvider, Table, Avatar, Tag, Tooltip, Drawer } from 'antd'
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { AndroidOutlined, GlobalOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons'
import { Chart } from "react-google-charts";
import TROFEU_PNG from '../../img/award.png'
import ptBR from "antd/es/locale/pt_BR";
import { api } from '../../services/api.service'
import { setNotification } from '../../services/dal.service'
import Cliente from '../../components/cliente';


const dateFormat = 'DD/MM/YYYY';


export const Estatistica = (props) => {

    const { token } = props
    const [card1, setCard1] = useState({
        total: 0,
        valor: 0,
        android: {
            valor: 0,
            total: 0,
            porcent: 0
        },
        web: {
            valor: 0,
            total: 0,
            porcent: 0
        }, ios: {
            valor: 0,
            total: 0,
            porcent: 0
        }
    })
    const [card2, setCard2] = useState([
        ["Task", "Pedidos por tipo"],
        ["Delivery", 0],
        ["Retirada", 0],
    ]);
    const [card3, setCard3] = useState([["Dia", "Total"]])
    const [loading, setLoading] = useState(false)
    const [range, setRange] = useState([dayjs().subtract(7, 'days'), dayjs()])
    const [clientes, setClientes] = useState([])
    const [produtos, setProdutos] = useState([])


    const [cliente, setCliente] = useState(undefined)
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        loadDados()
    }, [range])



    async function loadDados() {
        try {
            setLoading(true)
            //CARD 1
            var response = await api.get('/api/v2.0/pedidos/platform', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    data1: range[0].format('YYYY-MM-DD').concat(' 00:00:00'),
                    data2: range[1].format('YYYY-MM-DD').concat(' 23:59:59'),
                }
            })

            let total = 0
            let valor = 0
            let valorAndroid = 0
            let totalAndroid = 0
            let valorWeb = 0
            let totalWeb = 0
            let valorIos = 0
            let totalIos = 0
            response.data.map((item, index) => {
                total = total + parseInt(item.total)
                valor = valor + parseFloat(item.valor)
                if (item.platform.toLowerCase() === 'web') {
                    totalWeb = totalWeb + parseInt(item.total)
                    valorWeb = valorWeb + parseFloat(item.valor)
                } else if (item.platform.toLowerCase() === 'android') {
                    totalAndroid = totalAndroid + parseInt(item.total)
                    valorAndroid = valorAndroid + parseFloat(item.valor)
                } else {
                    totalIos = totalIos + parseInt(item.total)
                    valorIos = valorIos + parseFloat(item.valor)
                }
            })

            var auxCard1 = { ...card1 }
            auxCard1.valor = valor
            auxCard1.total = total
            auxCard1.android.valor = valorAndroid;
            auxCard1.android.total = totalAndroid
            auxCard1.android.porcent = (100 * totalAndroid) / (total > 0 ? total : 1)
            auxCard1.web.total = totalWeb
            auxCard1.web.valor = valorWeb
            auxCard1.ios.total = totalIos
            auxCard1.ios.valor = valorIos
            setCard1(auxCard1)

            //CARD 2
            var responseType = await api.get('/api/v2.0/pedidos/types', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    data1: range[0].format('YYYY-MM-DD').concat(' 00:00:00'),
                    data2: range[1].format('YYYY-MM-DD').concat(' 23:59:59'),
                }
            })
            let auxCard2 = [
                ["Task", "Pedidos por tipo"]
            ]
            responseType.data.map((item, index) => {
                auxCard2.push([item.type === 'PICKUP' ? 'RETIRADA' : item.type, item.valor])
            })
            setCard2(auxCard2)

            //CARD3
            var responseCard3 = await api.get('/api/v2.0/pedidos/evolucao', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    data1: range[0].format('YYYY-MM-DD').concat(' 00:00:00'),
                    data2: range[1].format('YYYY-MM-DD').concat(' 23:59:59'),
                }
            })
            //  console.log(range[0].format('YYYY-MM-DD').concat(' 00:00:00'), range[1].format('YYYY-MM-DD').concat(' 23:59:59'))

            let auxCard3 = [["Dia", "Total", { role: 'style' }, { role: 'annotation' }]]
            responseCard3.data.map(item => {
                auxCard3.push([
                    dayjs(item.dia).format('D/MM'),
                    item.valor,
                    "color: #1E90FF",
                    item.valor.toFixed(2)]
                )
            })
            //   console.log(auxCard3)
            setCard3(auxCard3)


            //Melhores clientes
            var responseCard4 = await api.get('/api/v2.0/pedidos/melhores-clientes', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    data1: range[0].format('YYYY-MM-DD').concat(' 00:00:00'),
                    data2: range[1].format('YYYY-MM-DD').concat(' 23:59:59'),
                }
            })
            setClientes(responseCard4.data)

            //Melhores clientes
            var responseCard5 = await api.get('/api/v2.0/pedidos/melhores-produtos', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    data1: range[0].format('YYYY-MM-DD').concat(' 00:00:00'),
                    data2: range[1].format('YYYY-MM-DD').concat(' 23:59:59'),
                }
            })
            //   console.log(responseCard5.data)
            setProdutos(responseCard5.data)


        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            //  navigate('/login')
        }
        setLoading(false)
    }





    return <div style={{ margin: 10 }}>
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            <ConfigProvider locale={ptBR}>
                <DatePicker.RangePicker
                style={{width: isMobile ? 250 : 'auto'}}
                    locale={ptBR}
                    size='small'
                    allowClear={false}
                    defaultValue={range}
                    format={dateFormat}
                    onChange={(value) => setRange(value)} />
            </ConfigProvider>
            <span style={{ marginLeft: 10 }}> <span style={{ color: 'royalblue' }}> Ajuste o período de pesquisa </span>  para analisar bem esses indicadores</span>

        </div>
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            <div style={{ margin: 5, flex: 1 }}>
                <Card style={{ height: '100%' }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div style={{ fontWeight: 600, color: 'royalblue', fontSize: '1.2em' }}>Pedidos</div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',

                        }}>
                            <div style={{ fontSize: '2.8em' }}>{card1.total}</div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                                <div style={{ fontWeight: 500, fontSize: '1.1em' }}>R$ {card1.valor.toFixed(2)} </div>
                                <div style={{ fontSize: '0.8em' }}>
                                    Ticket médio R$ {(card1.valor / (card1.total > 0 ? card1.total : 1)).toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Progress
                                style={{ width: '50%' }}
                                showInfo={false}
                                percent={100}
                                success={{
                                    percent: card1.android.porcent.toFixed(0),
                                }}
                            />

                            <Space direction='horizontal'>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <AndroidOutlined style={{ color: 'green' }} />
                                    <span style={{ fontSize: '0.8em' }}> ANDROID {card1.android.porcent.toFixed(0)}%</span>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
                                    <GlobalOutlined style={{ color: 'royalblue' }} />
                                    <span style={{ fontSize: '0.8em', marginLeft: 1 }}> WEB {100 - card1.android.porcent.toFixed(0)}%</span>
                                </div>
                            </Space>
                        </div>
                    </div>
                </Card>
            </div>
            <div style={{ margin: 5, flex: 1 }}>
                <Card style={{ height: '100%' }}>
                    <Row justify="space-between" align="bottom" style={{ height: '100%' }}>
                        <Col style={{ fontWeight: 600, color: 'royalblue', fontSize: '1.2em' }} span={24}>Tipos de serviço mais usados</Col>
                        <Col span={24}>
                            <Chart
                                chartType="PieChart"
                                data={card2}
                                width={"100%"}
                                height={"auto"}
                            />
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>
        <div style={{ margin: 5 }}>
            <Card>
                <div style={{ fontWeight: 600, color: 'royalblue', fontSize: '1.2em', marginBottom: 20 }}>Evolução dos pedidos</div>
                {card3.length > 1 && <Chart
                    chartType="ColumnChart"
                    width="auto"
                    height="auto"
                    data={card3}
                />
                }
            </Card>
        </div>

        <div style={{ margin: 5, marginTop: 10 }}>
            <Row gutter={[10, 10]}>
                <Col span={isMobile ? 24 : 12}>
                    <Card style={{ height: '100%' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 600, color: 'royalblue', fontSize: '1.2em' }}>
                                    Melhores clientes
                                    <Tooltip placement="topLeft"
                                        title={'Clientes que efetuaram cadastro na plataforma.'}
                                        arrow={true}>
                                        <InfoCircleOutlined style={{ color: 'black', fontSize: 16, marginLeft: 5 }} />
                                    </Tooltip>
                                </div>
                                <img alt='trof' src={TROFEU_PNG} height={30} />
                            </div>

                            <Table
                            bordered
                                dataSource={clientes}
                                size='small'
                                pagination={false}
                                rowKey={"uid"}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            // console.log('record', record)
                                            setCliente(record)
                                            setIsModalVisible(true)
                                        },
                                    };
                                }}>
                                <Table.Column title="Cliente" dataIndex={"uid"} key={"uid"}
                                    render={(text, record) => {
                                        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Avatar src={record.photoURL} size={'small'} style={{ marginRight: 2 }} />
                                            {record.displayName}
                                        </div>
                                    }} />
                                <Table.Column title="Telefone" dataIndex={"uid"} key={"uid"}
                                    render={(text, record) => {
                                        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {record.phoneNumber}
                                        </div>
                                    }} />

                                <Table.Column title="Pedidos" dataIndex={"uid"} key={"uid"}
                                    render={(text, record) => {
                                        return <div style={{
                                            display: 'flex', flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <Tag color='royalblue'>{record.total}</Tag>
                                            <RightOutlined style={{ cursor: 'pointer' }} />
                                        </div>
                                    }} />


                            </Table>

                        </div>
                    </Card>
                </Col>
                <Col span={isMobile ? 24 : 12}>
                    <Card style={{ height: '100%' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 600, color: 'royalblue', fontSize: '1.2em' }}>Melhores produtos</div>
                                <img alt='trof' src={TROFEU_PNG} height={30} />
                            </div>

                            <Table 
                            dataSource={produtos} 
                            size='small' 
                            bordered
                            rowKey={"idpedidoitens"}
                            pagination={false}>
                                <Table.Column title="Produto" dataIndex={"idpedidoitens"} key={"idpedidoitens"} width={'80%'}
                                    render={(text, record) => {
                                        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {record.NomeReduzido}
                                        </div>
                                    }} />

                                <Table.Column title="Vendidos" dataIndex={"idpedidoitens"} key={"idpedidoitens"}
                                    render={(text, record) => {
                                        return <Tag color='royalblue'>{record.quant}</Tag>
                                    }} />
                            </Table>

                        </div>
                    </Card>
                </Col>
            </Row>
        </div>


        <Drawer title={<div style={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar src={cliente?.photoURL} style={{ marginRight: 4 }} />
            {" "} {cliente?.displayName}
        </div>}
            destroyOnClose={true}
            placement="right" onClose={() => { setIsModalVisible(false) }}
            width={600}
            open={isModalVisible}>
            <Cliente
                uid={cliente?.uid}
                setIsModalVisible={setIsModalVisible} />
        </Drawer>


    </div>

}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Estatistica)
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../admin.css'
import { api } from '../../services/api.service'
import { setNotification, getPermissoes, GERENTE } from '../../services/dal.service'
import {
    Table, Select, Button, Image, Card, Input, Space, Modal, Row, Col,
    message, Upload, App
} from 'antd'
import moment from 'moment';
import ilustracion from '../../img/ilustracoes/undraw_New_notifications_re_xpcv.png'
import { BASEURL } from '../../services/api.service'
import {
    LoadingOutlined, PlusOutlined
} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { isBrowser } from "react-device-detect";

const { Column } = Table
const { TextArea } = Input;



export const Notification = (props) => {

    const { token, tokenFull } = props
    const [envios, setEnvios] = useState([])
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [campanha, setCampanha] = useState({
        titulo: '',
        msg: '',
        assunto: ''
    })
    const permissions = getPermissoes(tokenFull)
    const { modal } = App.useApp()

    useEffect(() => {
        load()
    }, [])



    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            console.log('Você só pode fazer upload de imagem JPG/PNG You can only upload JPG/PNG file!')
            message.error('Você só pode fazer upload de imagem JPG/PNG You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 / 1024 < 3;
        if (!isLt2M) {
            console.log('A imagem deve ser menor que 3MB!')
            message.error('A imagem deve ser menor que 3MB!');
        }
        console.log('beforeUpload', isJpgOrPng, isLt2M)
        return isJpgOrPng && isLt2M;
    }

    const uploadButton = (
        <div style={{
            alignSelf: 'center', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex',
            flexDirection: 'column', height: 140, width: 210
        }}>
            {loading ? <LoadingOutlined /> : <Space size={"large"} style={{ cursor: 'pointer' }}> <PlusOutlined /> </Space>}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleChangeUpload = info => {
        // console.log('log ', info.file.status)
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            console.log('info', BASEURL.concat(info.fileList[0].response.filename))
            setLoading(false)
            setCampanha({ ...campanha, url: BASEURL.concat('campanhas/', info.fileList[0].response.filename) })
        }
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    async function load() {
        try {
            setLoading(true)
            var response = await api.get('/api/v2.0/notification', {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                //  console.log(response.data)
                setEnvios(response.data)
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            props.history.push('/login')
        }
        setLoading(false)
    }


    async function salvar() {
        try {
            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }
            setLoading(true)
            campanha.data = moment().format('DD/MM/YYYY HH:mm:ss')
            var response = await api.post('/api/v2.0/notification', campanha, {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                setIsModalVisible(false)
                setNotification('Campanha criada com sucesso!', 'success')
                load()
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }

    return isBrowser ? renderDesktop() : renderMobile()

    function renderMobile() {
        return <div style={{ backgroundColor: 'white', flex: 1 }}>
            {
                renderContextArea()
            }
        </div>
    }

    function renderDesktop() {
        return <div className="app-window">
            {renderContextArea()}

        </div>
    }


    function renderContextArea() {
        return <React.Fragment>
            <div className="contentarea">
                <Card title={null} style={{ marginBottom: 15 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img style={{ marginRight: 40 }} src={ilustracion} alt="img" height={70} width={90} />
                        <div>
                            <h3>Campanhas.</h3>
                            <p>Envie e-mail e notificações de promoções ou comunicados aos seus clientes.</p>
                        </div>
                    </div>
                </Card>

                <Table dataSource={envios}
                pagination={false}
                    rowKey={(value) => "not-key-".concat(value?.id)}
                    size="small"
                    loading={loading}
                    footer={() => <div
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        </div>
                        <div>
                            <Button type="primary" 
                            disabled={!permissions?.includes(GERENTE)}
                            onClick={() => {
                                 if (!permissions?.includes(GERENTE)) {
                                    modal.warning({
                                        title: "Acesso negado!",
                                        content: "Você não possui permissão para executar essa operação! "
                                    })
                                    return
                                }
                                setCampanha({
                                    titulo: '',
                                    msg: '',
                                    assunto: ''
                                })
                                setIsModalVisible(true)
                            }}>Nova Campanha
                            </Button>
                        </div>
                    </div>}>
                    <Column title="Data" dataIndex="data" key="data"
                        width={150}
                        render={(text, record, index) => {
                            return text
                        }} />

                    <Column title="Assunto" dataIndex="assunto" key="assunto"
                        render={(text, record, index) => {
                            return text
                        }}
                    />

                    {isBrowser && <Column title="Descrição" dataIndex="msg" key="msg"
                        render={(text, record, index) => {
                            return text
                        }}
                    />
                    }
                    {permissions?.includes(GERENTE) && <Column title="" width={40}
                        render={(text, record, index2) => {
                            return <Space size="middle" >
                                <Button type="link"

                                    onClick={() => {

                                        setCampanha(record)
                                        salvar()
                                    }}>Reenviar</Button>
                            </Space>
                        }}
                    />
                    }

                </Table>

            </div>


            <Modal title="Campanha"
                open={isModalVisible}
                onOk={() => { salvar() }}
                okText=" Salvar "
                okButtonProps={{
                    disabled: (campanha.assunto.trim() === '' || campanha.msg.trim() === '' || campanha.titulo.trim() === '')
                }}
                onCancel={() => setIsModalVisible(false)}>

                <Row gutter={[8, 8]}>
                    <Col span={6}>
                        Assunto
                    </Col>
                    <Col span={18}>
                        <Input maxLength={50} value={campanha.assunto} onChange={(e) => setCampanha({ ...campanha, assunto: e.target.value })} />
                    </Col>
                    <Col span={6}>
                        Titulo
                    </Col>
                    <Col span={18}>
                        <Input maxLength={50} value={campanha.titulo} onChange={(e) => setCampanha({ ...campanha, titulo: e.target.value })} />
                    </Col>
                    <Col span={6}>
                        Descrição
                    </Col>
                    <Col span={18}>
                        <TextArea rows={4} maxLength={250} value={campanha.msg} onChange={(e) => setCampanha({ ...campanha, msg: e.target.value })} />
                    </Col>
                    <Col span={8}>
                        <Col> <b>Formatos:</b></Col>
                        <Col> JPEG, JPG e PNG </Col>
                        <Col><b>Peso máximo:</b></Col>
                        <Col>2 MB</Col>
                        <Col><b>Resolução ideal:</b></Col>
                        <Col> 240X180</Col>
                    </Col>
                    <Col span={16}>
                        <ImgCrop style={{ height: 140 }} className="crop" aspect={210 / 140}>
                            <Upload
                                className={"produto2-item-imagem"}
                                multiple={false}
                                headers={
                                    {
                                        'x-access-token': token,
                                    }
                                }
                                name="file"
                                maxCount={1}
                                showUploadList={false}
                                action={BASEURL.concat('api/v2.0/uploads/uploadImageCampanha')}
                                beforeUpload={beforeUpload}
                                onChange={handleChangeUpload}
                                onPreview={onPreview}>
                                <div>
                                    {campanha.url ? <img src={campanha.url}
                                        alt="campanha" style={{ height: 140, width: 210, margin: 1 }} /> : uploadButton}
                                </div>
                            </Upload>
                        </ImgCrop>
                    </Col>
                </Row>
            </Modal>

        </React.Fragment>
    }


}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)

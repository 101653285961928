import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './detalhes.css'
import * as AdminAction from '../../../store/actions/admin.action'
//import 'antd/dist/antd.css';
import { BASEURL } from '../../../services/api.service'
import {
    Upload, message, Select, Row, Col,
    Input, InputNumber, Checkbox, Divider, Space
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { moneyMask } from '../../../services/util'
import { isBrowser } from "react-device-detect";
import { Select as SelectMui, TextField } from '@mui/material';
import { valorNumericoOnChange, valorMonetarioOnChange } from '../../../services/util'


const { Option } = Select
const { TextArea } = Input

/*
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
*/

function beforeUpload(file) {

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        console.log('Você só pode fazer upload de imagem JPG/PNG You can only upload JPG/PNG file!')
        message.error('Você só pode fazer upload de imagem JPG/PNG You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 / 1024 < 3;
    if (!isLt2M) {
        console.log('A imagem deve ser menor que 3MB!')
        message.error('A imagem deve ser menor que 3MB!');
    }
    console.log('beforeUpload', isJpgOrPng, isLt2M)
    return isJpgOrPng && isLt2M;
}





export const Detalhes = (props) => {

    const { produto, setProduto, token } = props;

    //const [value, setvalue] = useState(0)
    const [categorias, setCategorias] = useState([])
    // const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const locale = "pt-br";

    const uploadButton = (
        <div style={{
            alignSelf: 'center', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex',
            flexDirection: 'column', height: 180, width: 240
        }}>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    useEffect(() => {
        var cats = props.categorias.filter(item => item.ispattern === produto.ispattern)
        //  console.log('produto', produto)
        setCategorias(cats)
    }, [produto])


    const handleChangeUpload = info => {
        // console.log('log ', info.file.status)
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            console.log('info', BASEURL.concat(info.fileList[0].response.filename))
            setLoading(false)
            setProduto({ ...produto, Url: BASEURL.concat('products/', info.fileList[0].response.filename) })
            // Get this url from response in real world.
            /*
            getBase64(info.file.originFileObj, imageUrl => {
                setLoading(false)
                setProduto({ ...produto, Url: imageUrl })
            }
            );
            */
        }
        /*
        if (info.file.status === 'error') {
            // Get this url from response in real world.
            setLoading(false)
            return;
        }
        */
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    return (<div className="main-detalhe">
        <div className={isBrowser ? "main-content" : "main-content-mobile"}>
            <div className="main-content-1">
                <Row>
                    <Col span={24}>
                        Categoria
                    </Col>
                    <Col span={24}>
                        {

                            <Select style={{ width: '100%' }}
                                disabled={produto.ispattern === 0}
                                value={produto.appcategoriaid}
                                onChange={(e) => { setProduto({ ...produto, appcategoriaid: e }) }}>
                                {
                                    categorias.map((item, index) => (
                                        <Option key={index} value={item.idcategorias}>{item.catDescricao}</Option>
                                    ))
                                }
                            </Select>

                        }

                    </Col>
                </Row>

                <Row gutter={[16,16]} style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <TextField
                            size='small'
                            variant='outlined'
                            label="Nome do produto"
                            value={produto.NomeReduzido}
                            fullWidth
                            inputProps={{
                                maxlength: 255
                            }}
                            onChange={(e) => {
                                setProduto({ ...produto, NomeReduzido: e.target.value })
                            }} />
                    </Col>                  
                    <Col span={24}>
                        <TextField
                            fullWidth
                            variant='outlined'
                            size='small'
                            multiline
                            rows={2}
                            label="Informações"
                            placeholder="Exemplo: Carne bem passada com molho de ervas finas..."
                            inputProps={{
                                maxlength: 255
                            }}
                            value={produto?.Info}
                            onChange={(e) => {
                                setProduto({ ...produto, Info: e.target.value })
                            }}
                        />
                    </Col>

                    <Col span={24}>
                        <Space direction='horizontal' size={'small'}>
                            <TextField
                                size='small'
                                label="Cód. Engfood"
                                value={produto.ProdutoID}
                                style={{ width: 100 }}
                                inputProps={{
                                    maxlength: 8
                                }}
                                onChange={(e) => {
                                    var value = valorNumericoOnChange(e)
                                    setProduto({ ...produto, ProdutoID: value })
                                }} />

                            {produto.ispattern === 1 && <TextField
                                value={produto.Venda}
                                label=" Preço R$"
                                variant='outlined'
                                size='small'
                                style={{
                                    width: 100
                                }}
                                onChange={(event) => {
                                    var value = valorMonetarioOnChange(event)
                                    setProduto({ ...produto, Venda: moneyMask(value) })
                                }}
                            />
                            }
                        </Space>
                    </Col>

                </Row>



               


            </div>
            <div className="main-content-2" style={{ fontSize: 12 }}>
                <b> Imagem do item:</b>
                <ImgCrop className="crop" aspect={240 / 180}>
                    <Upload
                        className={"produto2-item-imagem"}
                        multiple={false}
                        headers={
                            {
                                'x-access-token': token,
                            }
                        }
                        name="file"
                        maxCount={1}
                        showUploadList={false}
                        action={BASEURL.concat('api/v2.0/uploads/uploadImage')}
                        beforeUpload={beforeUpload}
                        onChange={handleChangeUpload}
                        onPreview={onPreview}>
                        <div>
                            {produto.Url ? <img src={produto.Url}
                                alt="product" style={{ height: 180, width: 240, margin: 1 }} /> : uploadButton}
                        </div>


                    </Upload>
                </ImgCrop>
                <div className="product-format-line-1">
                    <div>
                        <strong> Formatos: </strong> JPEG, JPG e PNG
                    </div>
                    <div>
                        {
                            /*
                            produto.Url && <DeleteOutlineIcon fontSize={"small"} style={{ cursor: 'pointer' }}
                            />
                            */
                        }
                    </div>
                </div>
                <div>
                    <strong> Peso máximo: </strong> 3 MB
                </div>
                <div>
                    <strong> Resolução mínima: </strong> 240X180
                </div>
                <br />
            </div>
        </div>
        {
            produto.ispattern === 0 && <Row style={{ marginTop: 0 }}>
                <Col span={24}> <strong> PREÇOS </strong> </Col>
                <Col span={24}><Divider style={{ marginTop: 5, marginBottom: 10 }} /> </Col>
                {
                    produto.tamanhos.map((tam, indextam) => {
                        return <Col span={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Row style={{ display: 'flex' }}>
                                <Checkbox
                                    disabled={tam.disponivelTam === 0}
                                    checked={tam.disponivel === 1 ? true : false}
                                    onChange={(e) => {
                                        var aux = Object.assign({}, produto)
                                        tam.disponivel = e.target.checked ? 1 : 0
                                        if (e.target.checked === true && tam.valor === undefined)
                                            tam.valor = 0
                                        aux.tamanhos[indextam] = tam
                                        setProduto(aux)
                                    }}>
                                    <b> {tam.NomeReduzido} </b>
                                </Checkbox>
                            </Row>
                            <Row style={{ display: 'flex' }}>
                                <InputNumber
                                    disabled={tam.disponivel === 1 ? false : true}
                                    step='0.01'
                                    decimalSeparator={','}
                                    min={0}
                                    stringMode={true}
                                    value={moneyMask(tam.valor)}
                                    onChange={(value) => {
                                        var aux = Object.assign({}, produto)
                                        tam.valor = value
                                        aux.tamanhos[indextam] = tam
                                        setProduto(aux)
                                    }} />
                            </Row>
                            <Row>
                                <span style={{ fontSize: 12, marginLeft: 5 }}>até {tam.sabores} {tam.sabores > 1 ? ' sabores' : 'sabor'}</span>
                            </Row>
                        </Col>
                    })
                }

            </Row>
        }
    </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    produto: state.adminReducer.produto
})

const mapDispatchToProps = dispatch => ({
    setProduto: (produto) => dispatch(AdminAction.save_produto(produto))
})

export default connect(mapStateToProps, mapDispatchToProps)(Detalhes)

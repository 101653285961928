import React, { useState } from 'react'
import { connect } from 'react-redux'
import { App, Button, Modal } from 'antd'
import Award from '../../img/award.png'
import './modalAtivar.css'
import { useNavigate } from 'react-router-dom'

export const ModalAtivar = (props) => {

    const navigate = useNavigate()

    return <div className='modal-aviso-content'>
        <div className='background-modal-ativar'>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div style={{ fontSize: '1.85em', letterSpacing: .2, color: 'white' }}>
                    Ative os pagamentos online para usar este recurso!
                </div>
                <div>
                    <img src={Award} alt='trofeu' style={{ height: 64, width: 64 }} />
                </div>
            </div>
        </div>
        <div style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'gray',
            fontSize: '1.1em',
            padding:30,
            flexDirection:'column'
        
        }}>
            Atualize seus meios de pagamento agora para usá-lo!

            <Button style={{marginTop:15, width:100}} type='primary' size='large' 
            onClick={() => navigate('/formas-de-pagamento')}>Alterar</Button>

        </div>
    </div>
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAtivar)
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core';
import { Switch } from 'antd';
//import 'antd/dist/antd.css';
import { api } from '../../../services/api.service'
import {DeleteOutlined, DeleteOutlinedIcon, QuestionCircleOutlined} from '@ant-design/icons';
import { InputNumber, Input, Modal } from 'antd';
import { setNotification } from '../../../services/dal.service'
import { Table, Space } from 'antd';

import * as AdminAction from '../../../store/actions/admin.action'

export const Massas = (props) => {

    const { token, categoria, setCategoria } = props
    const [erro, setErro] = useState(false)

    useEffect(() => {
        console.log('massas')
    }, [categoria])

    const columns = [
        {
            title: 'Massa',
            dataIndex: 'nome',
            key: 'idapppizzasmassas',
            render: (text, record) => (
                <Input value={text} maxLength={50} onChange={(e) => {
                    record.nome = e.target.value
                    var massas = Object.values(Object.assign({}, categoria.massas))
                    var index = massas.findIndex(a => a.idapppizzasmassas === record.idapppizzasmassas)
                    if (index >= 0) {
                        massas[index] = record
                        setCategoria({ ...categoria, massas: massas })
                    }
                }} />
            ),
        },
        {
            title: 'Preço',
            dataIndex: 'valor',
            key: 'valor',
            render: (text, record) => (
                <InputNumber min={0} defaultValue={0} value={text}
                    decimalSeparator=","
                    precision={2}
                    onChange={(e) => {
                        record.valor = e
                        var massas = Object.values(Object.assign({}, categoria.massas))
                        var index = massas.findIndex(a => a.idapppizzasmassas === record.idapppizzasmassas)
                        console.log('index', index)
                        if (index >= 0) {
                            massas[index] = record
                            setCategoria({ ...categoria, massas: massas })
                        }
                    }} />

            ),
        },
        {
            title: 'Disponibilidade',
            dataIndex: 'disponivel',
            key: 'disponivel',
            render: (text, record) => (
                <Switch
                    style={{ marginLeft: '20px' }}
                    checkedChildren={'Ativado'}
                    unCheckedChildren={'Pausado'}
                    checked={text}
                    onChange={(e) => {
                        record.disponivel = e === true ? 1 : 0
                        var massas = Object.values(Object.assign({}, categoria.massas))
                        var index = massas.findIndex(a => a.idapppizzasmassas === record.idapppizzasmassas)
                        if (index >= 0) {
                            massas[index] = record
                            setCategoria({ ...categoria, massas: massas })
                        }
                    }} />
            ),
        },
        {
            title: 'Cód. PDV',
            dataIndex: 'produtoid',
            key: 'produtoid',
            render: (text, record) => (
                <InputNumber min={0} defaultValue={0} value={text} onChange={(e) => {
                    record.produtoid = e
                    var massas = Object.values(Object.assign({}, categoria.massas))
                    var index = massas.findIndex(a => a.idapppizzasmassas === record.idapppizzasmassas)
                    if (index >= 0) {
                        massas[index] = record
                        setCategoria({ ...categoria, massas: massas })
                    }
                }} />
            ),
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle" onClick={() => {
                    Modal.confirm({
                        zIndex: 99999999,
                        title: 'Atenção!',
                        icon: <QuestionCircleOutlined size='small' />,
                        content: 'Deseja realmente excluir o tamanho?',
                        okText: 'Confirmar',
                        cancelText: 'Cancelar',
                        onOk: () => {
                            return new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    if (record.idapppizzasmassas > 0) {
                                        try {
                                            const response = await api.delete('/api/v2.0/category/massa?id='.concat(record.idapppizzasmassas), {
                                                headers: {
                                                    'x-access-token': token,
                                                }
                                            })
                                            if (response.status === 200) {
                                                var massas = Object.values(Object.assign({}, categoria.massas))
                                                var massas2 = massas.filter(el => el.idapppizzasmassas !== record.idapppizzasmassas)
                                                setCategoria({ ...categoria, massas: massas2 })
                                                setNotification("Massa excluida com sucesso", 'success')
                                            }
                                        } catch (error2) {
                                            setNotification('Oops errors!', 'error')
                                            reject()
                                        }

                                    } else {
                                        var massas = Object.values(Object.assign({}, categoria.massas))
                                        var massas2 = massas.filter(el => el.idapppizzasmassas !== record.idapppizzasmassas)
                                        setCategoria({ ...categoria, massas: massas2 })
                                    }
                                    console.log(record.idapppizzasmassas)
                                    resolve()
                                }, 1000);
                            })
                                .catch(() => {
                                    setNotification('Desculpe aconteceu um erro inesperado!', 'error')
                                    console.log('Oops errors!')
                                });
                        },
                        onCancel: () => {
                            console.log('cancel')
                        }
                    });
                }}>
                    <DeleteOutlined fontSize="small" style={{ cursor: "pointer", color: 'gray' }} />
                </Space>
            ),
        },
    ];


    return (
        <div>
            Indique aqui quais os tipos de massas que sua pizza possui:
            <div>
                <Table columns={columns} dataSource={categoria.massas}
                    size="small"
                    pagination={false} />
            </div>
            <div style={{ marginTop: 10 }}>
                <Button variant="outlined" size="small"
                    onClick={() => {
                        try {
                            var index = categoria.massas.length === 0 ? 0 :
                                Math.min(...categoria.massas.map(item => item.idapppizzasmassas));
                            index = (index - 1) < 0 ? (index - 1) : -1 * (index - 1);
                            const newprod = {
                                disponivel: 1,
                                nome: '',
                                sabores: 1,
                                Tipo: 1,
                                ordem:0,
                                produtoid:0,
                                valor:0,
                                appcategoriaid: categoria.idcategorias,
                                idapppizzasmassas: index
                            }

                            var massas = Object.values(Object.assign({}, categoria.massas))
                            massas.push(newprod)
                            setCategoria({ ...categoria, massas: massas })
                            // console.log(categoria)
                        } catch (eroror) {
                            console.log('ERROR> ', eroror.message)
                        }

                    }}>
                    + Adicionar massa
                </Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    categoria: state.adminReducer.categoria
})

const mapDispatchToProps = dispatch => ({
    setCategoria: (categoria) => dispatch(AdminAction.save_categoria(categoria))
})

export default connect(mapStateToProps, mapDispatchToProps)(Massas)

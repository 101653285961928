import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Row, Col, QRCode, Button } from 'antd'
import moment from 'moment'
import { removerAcento } from '../../services/util'
import ReactToPrint from 'react-to-print';
import './reciboCozinha.css'

export const ReciboCozinha = (props) => {

    const componentRef = useRef();
    const { selectedCozinha, tokenFull, setSelectedCozinha, setOpenAtivar } = props

    return !selectedCozinha ?
        <div />
        : <>
            <div ref={componentRef} style={{ margin: -14 }}>
                <Row
                    className={'reciboCozinha-fontSize'}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0,
                        margin: 30
                    }}>
                    <Col span={24} className={'reciboCozinha-fontSize-title'}>
                        <strong>  BR - {selectedCozinha.pedid} </strong>
                    </Col>
                    <Col span={24} className={'reciboCozinha-fontSize-subtitle'}>
                        <span>{moment(selectedCozinha.dataserver).format('DD/MM/YYYY')}</span>
                        <span style={{ marginLeft: 5 }}> às {moment(selectedCozinha.dataserver).format('HH:mm:ss')} </span>
                        <hr style={{ marginTop: 2, marginBottom: 2 }} />
                    </Col>


                    <Col span={24} className={'reciboCozinha-fontSize'}>
                        <strong> {selectedCozinha.type === "DELIVERY" ? 'Pedido para Delivery' : 'Pedido para Retirada'} </strong>
                    </Col>
                    <Col span={24} className={'reciboCozinha-fontSize'}>
                        {selectedCozinha?.user.nome}
                    </Col>
                    <Col span={24} className={'reciboCozinha-fontSize'}>
                        <hr style={{ marginTop: 2, marginBottom: 2 }} />
                    </Col>
                    <Col span={24} className={'reciboCozinha-fontSize'}>
                        {selectedCozinha?.itens?.map((item, index) => {
                            return <Row key={'ped-it-'.concat(index)} style={{ fontWeight: 700 }}>
                                <Col span={4} className={'reciboCozinha-fontSize-item'}>
                                    {item.Quant} X

                                </Col>
                                <Col span={20} className={'reciboCozinha-fontSize-item'}>
                                    {item.NomeReduzido}
                                </Col>

                                {
                                    item?.complementos.length > 0 && <Col span={24}>
                                        {item?.complementos.map((c, indexc) => {
                                            return <Row style={{
                                                color: 'gray',
                                                marginLeft: 10,
                                                fontWeight: 'normal',

                                            }} key={"comp-it-".concat(indexc)}>
                                                <Col className='reciboCozinha-fontSize-subitem' span={4}>{c.Quant} x</Col>
                                                <Col className='reciboCozinha-fontSize-subitem' span={20}>{c.NomeReduzido}</Col>
                                            </Row>
                                        })
                                        }
                                    </Col>
                                }

                                {
                                    item?.obs && <Col span={24} className='recibo-fontSize-obs'>
                                        {"Obs: ".concat(item.obs)}
                                    </Col>
                                }

                            </Row>
                        })}
                        <Col span={24} className={'recibo-fontSize'}>
                            <hr style={{ marginTop: 2, marginBottom: 2 }} />
                        </Col>
                    </Col>
                    <Col span={24} className={'reciboCozinha-fontSize-rodape'}>
                        impresso às {moment(selectedCozinha.dataserver).format('DD/MM/YYYY HH:mm:ss')}
                    </Col>

                </Row>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Button onClick={() => setSelectedCozinha(undefined)}>Fechar</Button>

                {
                    tokenFull?.license !== 'free' ?
                        <ReactToPrint
                            trigger={() => <Button type='primary'
                                onClick={() => setSelectedCozinha(undefined)}>
                                Imprimir
                            </Button>}
                            content={() => componentRef.current}
                        />
                        :
                        <Button type='primary'
                            onClick={() => {
                                setOpenAtivar(true)
                            }}>
                            Imprimir
                        </Button>
                }


               
            </div>

        </>
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ReciboCozinha)
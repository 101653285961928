import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './product.css'
import * as AdminAction from '../../store/actions/admin.action'
//import 'antd/dist/antd.css';
import { setNotification } from '../../services/dal.service'
import { api } from '../../services/api.service'
import { Tabs, Button as ButtonAntd } from 'antd';
import { isBrowser } from "react-device-detect";

import Detalhes from './detalhes'
import Disponibilidade from './disponibilidade';
import Promotion from './promotion';
import Grupos from './grupos'

//const { Option } = Select;
const { TabPane } = Tabs;
function callback(key) {
    // console.log(key);
}


export const Product = (props) => {

    const { produto, token } = props
    const [erro, setErro] = useState(false)


    useEffect(() => {
        validar()
        //  console.log(produto)
    }, [produto])

    function validar() {
        var aux = false
        if (produto.NomeReduzido.trim() === '') {
            aux = true;
        }
        if (produto.AtivarPromocao === 1) {
            if (parseFloat(produto.ValorPromocao) >= parseFloat(produto.Venda))
                aux = true
            if (parseFloat(produto.ValorPromocao) === 0)
                aux = true
        }
        setErro(aux)
    }

    async function salvar() {
        // console.log('salvar', produto)
        try {
            const result = await api.post('/api/v2.0/product', produto, {
                headers: {
                    'x-access-token': token,
                }
            })
            if (result.status === 200) {
                props.setDrawerProd(false)
                //  props.setDrawerCat(false)
                props.getCategorias()
                setNotification('Produto salvo com sucesso!', 'success')

            } else {
                setNotification('Erro ao processar solicitação junto ao servidor', 'error')
            }

        } catch (error) {
            setNotification(error.message, 'error')
        }
    }

    return <div className="main">
        <div className={isBrowser ? "product-main" : "product-main-mobile"}>
            <div>
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="Detalhes" key="1">
                        <Detalhes categorias={props.categorias} />
                    </TabPane>
                    {
                        produto.ispattern === 1 && <TabPane tab="Grupos" key="2">
                            <Grupos categorias={props.categorias} />
                        </TabPane>
                    }
                    {
                        produto.ispattern === 1 &&
                        <TabPane tab="Disponibilidade" key="3">
                            <Disponibilidade />
                        </TabPane>
                    }
                    {
                        produto.ispattern === 1 && <TabPane tab="Promoção" key="4">
                            <Promotion />
                        </TabPane>
                    }
                </Tabs>
            </div>
        </div>
        <div className="product-footer">
            <ButtonAntd danger
                onClick={() => props.setDrawerProd(false)}>
                Cancelar
            </ButtonAntd>
            <ButtonAntd style={{ marginLeft: 15, width: 100 }} type="primary"
                disabled={erro}
                onClick={() => salvar()}>
                Salvar
            </ButtonAntd>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    produto: state.adminReducer.produto
})

const mapDispatchToProps = dispatch => ({
    setProduto: (produto) => dispatch(AdminAction.save_produto(produto))
})

export default connect(mapStateToProps, mapDispatchToProps)(Product)

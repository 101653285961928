import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './cardapio.css'
import '../admin.css'
import { api } from '../../services/api.service'
import { setNotification, getPermissoes, GERENTE } from '../../services/dal.service'
//import Switch from '@material-ui/core/Switch';
import {
    OrderedListOutlined, PlusOutlined, CheckOutlined,
    StopOutlined, QuestionCircleOutlined, DeleteOutlined,
} from '@ant-design/icons'
import {
    Switch, Modal, Button as ButtonAntd, Table, InputNumber,
    Drawer, Affix, Row, Col, Space, Skeleton, Avatar, List, App, Input
} from 'antd';
import noimagem from '../../img/noimagem.png'
import { MenuItem, DialogTitle, Dialog, Button, Divider, Menu } from '@material-ui/core';
import Category from '../../components/category'
import Product from '../../components/product'
import * as AdminAction from '../../store/actions/admin.action'
//import 'antd/dist/antd.css';
import { ReorderGeneric } from '../../components/reorderGeneric'
import { moneyMask, valorMonetarioOnChange } from '../../services/util'
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import { useNavigate } from 'react-router-dom'



const { Column } = Table;

export const Cardapio = (props) => {

    const navigate = useNavigate()
    const { categoria, setCategoria, produto, setProduto, token, tokenFull } = props;
    const [categorias, setCategorias] = useState([])
    const [openCategoryModel, setOpenCategoryModel] = useState(false)
    const [drawerProd, setDrawerProd] = React.useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false); //Ordenação
    const [changeProd, setChangeProd] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [drawerCat, setDrawerCat] = React.useState(false);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [loading, setLoading] = useState(true)
    const permissions = getPermissoes(tokenFull)
    const { modal } = App.useApp()

    const showModalOrdenar = (value) => {
        setIsModalVisible(value)
    }

    const handleCloseCategoria = () => {
        setAnchorEl(null);
    };

    const handleMenuCloseProduto = () => {
        setAnchorEl2(null);
    }

    const toggleDrawerCat = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerCat(open);
    };

    const toggleDrawerProd = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerProd(open);
    };

    useEffect(() => {
        getCategorias()
    }, [])

    async function salveCategoryOrder(cats) {
        try {

            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }

            var response = await api.put('/api/v2.0/category/order', cats, {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                setIsModalVisible(false)
                getCategorias()
                setNotification('Categorias ordenadas com sucesso!', 'success')
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }
    }


    async function getCategorias() {
        try {
            setLoading(true)
            var response = await api.get('/api/v2.0/category', {
                headers: {
                    'x-access-token': token,
                }
            })
            if (response.status === 200) {
                setCategorias(response.data)
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            navigate('/login')
            setNotification('Erro na autenticação junto ao servidor!', 'error')

        }
        setLoading(false)
    }


    async function getProdutos() {
        try {
            setLoading(true)
            var response = await api.get('/api/v2.0/produto', {
                headers: {
                    'x-access-token': token,
                }
            })
            if (response.status === 200) {
                console.log(response.data)
                //   setCategorias(response.data)
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            //  props.history.push('/admin/login')
            setNotification('Erro na autenticação junto ao servidor!', 'error')
            //  console.log(error.message)
        }
        setLoading(false)
    }



    async function salvarCategoria(item) {
        try {
            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }
            const result = item.idcategorias ? await api.put('/api/v2.0/category', item, {
                headers: {
                    'x-access-token': token,
                }
            }) : await api.post('/api/v2.0/category', item, {
                headers: {
                    'x-access-token': token,
                }
            })
            if (result.status === 200) {
                setNotification('Categoria atualizada com sucesso!', 'success')

            } else {
                setNotification(result.message, 'error')
            }

        } catch (error) {
            setNotification(error.message, 'error')
        }
    }


    async function excluirCategoria(cat) {
        try {
            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }
            var response = await api.delete('/api/v2.0/category?id='.concat(cat.idcategorias), {
                headers: {
                    'x-access-token': token,
                }
            })
            if (response.status === 200) {
                setNotification('Categoria excluida com sucesso!!', 'success')
                getCategorias()
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }


        } catch (error) {
            console.log(error.message)
            navigate('/login')
            setNotification('Erro na autenticação junto ao servidor!', 'error')
            //  console.log(error.message)
        }
    }



    async function excluirProduto(prod) {
        try {
            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }
            var response = await api.delete('/api/v2.0/product?id='.concat(prod.idappprodutos, '&id2=',
                prod.idapppizzassabores), {
                headers: {
                    'x-access-token': token,
                }
            })
            if (response.status === 200) {
                setNotification('Produto excluido com sucesso!!', 'success')
                getCategorias()
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            navigate('/login')
            setNotification('Erro na autenticação junto ao servidor!', 'error')
            //  console.log(error.message)
        }
    }




    async function setStatusProduto(prod, index) {
        try {
            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }
            const status = prod.Disponivel === 1 ? 'disponível' : 'em falta'
            var response = await api.put('/api/v2.0/product', prod, {
                headers: {
                    'x-access-token': token,
                }
            })
            if (response.status === 200) {
                setNotification('O item ' + prod.NomeReduzido + ' foi colocado como ' + status + '!', 'success')
                getCategorias()
            } else {
                setNotification('Não foi possível efetuar alteração do item!', 'error')
            }


        } catch (error) {
            setNotification(error.message, 'error')
        }
    }


    async function setValuesProduto() {
        try {
            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }
            var response = await api.put('/api/v2.0/product/value', changeProd, {
                headers: {
                    'x-access-token': token,
                }
            })
            if (response.status === 200) {
                setNotification('Alterações processadas com sucesso! ', 'success')
                setChangeProd([])
                getCategorias()
            } else {
                setNotification('Não foi possível efetuar alteração dos itens!', 'error')
            }

        } catch (error) {
            //  props.history.push('/login')
            setNotification(error.message, 'error')
            // console.log(error.message)
        }
    }





    function renderTableProdutos(item, index) {
        try {
            return <Table
                size={'small'}
                pagination={false}
                dataSource={item.produtos}
                rowKey={(record) => "prod-key-".concat(record.NomeReduzido, record.idappprodutos)}>
                <Column title={item.ispattern === 1 ? "Nome" : 'Sabores'}
                    dataIndex="NomeReduzido" key="NomeReduzido"
                    render={(text, prod, index2) => {
                        return <div className="produto-item-col1"
                            onClick={() => {
                                if (!permissions?.includes(GERENTE)) {
                                    modal.warning({
                                        title: "Acesso negado!",
                                        content: "Você não possui permissão para executar essa operação! "
                                    })
                                    return
                                }
                                setProduto(prod)
                                setDrawerProd(true)
                            }}>
                            <img src={prod.Url ? prod.Url : noimagem} className="produto-item-imagem" />
                            <div className="produto-item-text">
                                <div className="produto-item-name">
                                    {prod.NomeReduzido}
                                </div>
                                <div className="produto-item-info" >
                                    <p>  {isDesktop && prod.Info} </p>
                                </div>

                                {
                                    !isDesktop && <div
                                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                        <Switch

                                            checkedChildren={'Ativado'}
                                            unCheckedChildren={'Pausado'}
                                            checked={prod.Disponivel === 1}
                                            onChange={(e) => {
                                                if (!permissions?.includes(GERENTE)) {
                                                    modal.warning({
                                                        title: "Acesso negado!",
                                                        content: "Você não possui permissão para executar essa operação! "
                                                    })
                                                    return
                                                }
                                            }} />


                                        {
                                            item.ispattern === 1 ? <Input
                                                style={{ textAlign: 'right', width: 80 }}
                                                value={prod.Venda}
                                                onChange={(value) => {

                                                    if (!permissions?.includes(GERENTE)) {
                                                        modal.warning({
                                                            title: "Acesso negado!",
                                                            content: "Você não possui permissão para executar essa operação! "
                                                        })
                                                        return
                                                    }

                                                    var newcategorias = Object.assign({}, categorias)
                                                    var newprodutos = Object.assign({}, item.produtos)
                                                    var value2 = valorMonetarioOnChange(value)
                                                    newprodutos[index2].Venda = value2 // Number(value).toFixed(2);
                                                    newcategorias[index].produtos = Object.values(newprodutos)
                                                    setCategorias(Object.values(newcategorias))
                                                    try {
                                                        var prodaux = newprodutos[index2]
                                                        // console.log(parseFloat(prodaux.defaultvalue), prodaux.Venda)
                                                        if (parseFloat(prodaux.defaultvalue) !== parseFloat(prodaux.Venda)) {
                                                            var mudancas = Object.values(Object.assign({}, changeProd))
                                                            let index = mudancas.findIndex(val => val.idappprodutos == prodaux.idappprodutos);
                                                            if (index < 0) {
                                                                mudancas.push(prodaux);
                                                                setChangeProd(mudancas)
                                                            }

                                                        } else {
                                                            var mudancas = Object.values(Object.assign({}, changeProd))
                                                            let index = mudancas.findIndex(val => val.idappprodutos == prodaux.idappprodutos);
                                                            //  console.log('onBlur igual: ', index, prodaux.Venda, prodaux.defaultvalue)
                                                            if (index >= 0) {
                                                                mudancas.splice(index, 1);
                                                                setChangeProd(mudancas)
                                                            }
                                                        }
                                                    } catch (erorr) {

                                                    }
                                                }}
                                            />
                                                :
                                                <Row >

                                                </Row>

                                        }

                                        <Space size="middle" onClick={() => {

                                            if (!permissions?.includes(GERENTE)) {
                                                modal.warning({
                                                    title: "Acesso negado!",
                                                    content: "Você não possui permissão para executar essa operação! "
                                                })
                                                return
                                            }

                                            Modal.confirm({
                                                zIndex: 99999999,
                                                title: 'Atenção!',
                                                icon: <QuestionCircleOutlined size='small' />,
                                                content: 'Deseja realmente excluir o produto '.concat(prod.NomeReduzido, '?'),
                                                okText: 'Confirmar',
                                                cancelText: 'Cancelar',
                                                onOk: () => {
                                                    excluirProduto(prod)
                                                },
                                                onCancel: () => { }
                                            });
                                        }}>
                                            <DeleteOutlined
                                                className="category-content-button-icon"
                                                style={{ cursor: "pointer", marginLeft: 0, color: 'gray', fontSize: 20 }} />
                                        </Space>

                                    </div>
                                }



                            </div>
                        </div>
                    }} />


                {
                    item.ispattern === 0 && <Column title="Tamanhos" dataIndex="idapppizzassabores" key="idapppizzassabores" width={100}
                        render={(text, prod) => {
                            return <Row >
                                <Col style={{ color: 'gray', fontSize: 12 }} span={24}>Disponível em </Col>
                                <Col span={24}><strong>{prod.distam}
                                    {prod.distam === 1 ? ' tamanho ' : ' tamanhos'}</strong></Col>

                                {isMobile && <Col style={{ color: 'gray', fontSize: 12 }} span={24}>
                                    À partir de</Col>}
                                {isMobile && <Col span={24}><strong>
                                    R$ {prod.valor}</strong></Col>
                                }

                            </Row>
                        }}
                    />
                }
                {isDesktop && <Column title="Preço (R$)" dataIndex="Venda" key="Venda" width={90}
                    render={(text, prod, index2) => {

                        if (item.ispattern === 1) {
                            return <Input
                                style={{ textAlign: 'right' }}
                                value={moneyMask(prod.Venda)}
                                onChange={(value) => {
                                    if (!permissions?.includes(GERENTE)) {
                                        modal.warning({
                                            title: "Acesso negado!",
                                            content: "Você não possui permissão para executar essa operação! "
                                        })
                                        return
                                    }

                                    var newcategorias = Object.assign({}, categorias)
                                    var newprodutos = Object.assign({}, item.produtos)
                                    newprodutos[index2].Venda = valorMonetarioOnChange(value) // Number(value).toFixed(2);
                                    newcategorias[index].produtos = Object.values(newprodutos)
                                    setCategorias(Object.values(newcategorias))
                                    try {
                                        var prodaux = newprodutos[index2]
                                        console.log(parseFloat(prodaux.defaultvalue), prodaux.Venda)
                                        if (parseFloat(prodaux.defaultvalue) !== parseFloat(prodaux.Venda)) {
                                            var mudancas = Object.values(Object.assign({}, changeProd))
                                            let index = mudancas.findIndex(val => val.idappprodutos == prodaux.idappprodutos);
                                            if (index < 0) {
                                                mudancas.push(prodaux);
                                                setChangeProd(mudancas)
                                            }

                                        } else {
                                            var mudancas = Object.values(Object.assign({}, changeProd))
                                            let index = mudancas.findIndex(val => val.idappprodutos == prodaux.idappprodutos);
                                            console.log('onBlur igual: ', index, prodaux.Venda, prodaux.defaultvalue)
                                            if (index >= 0) {
                                                mudancas.splice(index, 1);
                                                setChangeProd(mudancas)
                                            }
                                        }
                                    } catch (erorr) {

                                    }
                                }}
                            />
                        } else {
                            return <Row >
                                <Col style={{ color: 'gray', fontSize: 12 }} span={24}>
                                    À partir de</Col>
                                <Col span={24}><strong>
                                    R$ {prod.valor}</strong></Col>
                            </Row>
                        }


                    }} />
                }


                {isDesktop && <Column title="Status" dataIndex="Disponivel" key="Disponivel" width={95}
                    render={(text, prod, index2) => {
                        return <Switch
                            checkedChildren={'Ativado'}
                            unCheckedChildren={'Pausado'}
                            checked={prod.Disponivel === 1}
                            onChange={(e) => {
                                if (!permissions?.includes(GERENTE)) {
                                    modal.warning({
                                        title: "Acesso negado!",
                                        content: "Você não possui permissão para executar essa operação! "
                                    })
                                    return
                                }
                                var newcategorias = Object.assign({}, categorias)
                                var newprodutos = Object.assign({}, item.produtos)
                                newprodutos[index2].Disponivel = prod.Disponivel === 1 ? 0 : 1
                                newcategorias[index].produtos = Object.values(newprodutos)
                                setCategorias(Object.values(newcategorias))
                                setStatusProduto(prod, index2)
                            }} />
                    }}
                />
                }
                {isDesktop && <Column title="" dataIndex="edit" key="edit" width={40}
                    render={(text, prod, index2) => {
                        return <Space size="middle" onClick={() => {
                            if (!permissions?.includes(GERENTE)) {
                                modal.warning({
                                    title: "Acesso negado!",
                                    content: "Você não possui permissão para executar essa operação! "
                                })
                                return
                            }
                            Modal.confirm({
                                zIndex: 99999999,
                                title: 'Atenção!',
                                icon: <QuestionCircleOutlined size='small' />,
                                content: 'Deseja realmente excluir o produto '.concat(prod.NomeReduzido, '?'),
                                okText: 'Confirmar',
                                cancelText: 'Cancelar',
                                onOk: () => {
                                    excluirProduto(prod)
                                },
                                onCancel: () => { }
                            });
                        }}>
                            <DeleteOutlined
                                className="category-content-button-icon"
                                style={{ cursor: "pointer", marginLeft: 0, color: 'gray', fontSize: 20 }} />
                        </Space>
                    }}
                />
                }

            </Table>



        } catch (error) {
            return <div></div>
        }
    }

    return isBrowser ? renderBrowser() : renderMobile()

    function renderBrowser() {
        return <div className="app-window">
            {
                renderArea()
            }
        </div>
    }

    function renderArea() {
        return <React.Fragment>
            <div className="contentarea">
                <div className="contentarea-option">

                    <ButtonAntd icon={<PlusOutlined />}
                        type='primary' size='small'
                        onClick={() => {

                            if (!permissions?.includes(GERENTE)) {
                                modal.warning({
                                    title: "Acesso negado!",
                                    content: "Você não possui permissão para executar essa operação! "
                                })
                                return
                            }

                            Modal.confirm({
                                zIndex: 99999999,
                                title: 'Qual modelo de categoria?',
                                icon: <QuestionCircleOutlined size='small' />,
                                content: '',
                                okText: 'Por tamnaho',
                                cancelText: 'Padrão',
                                onOk: () => {
                                    setCategoria({
                                        catDescricao: '',
                                        limitarHora: 0,
                                        tipo: 100,
                                        Ativa: 1,
                                        hora1: '10:00',
                                        hora2: '14:00',
                                        ispattern: 0,
                                        tamanhos: [
                                            {
                                                Disponivel: 1,
                                                NomeReduzido: 'Grande',
                                                sabores: 2,
                                                Tipo: 1,
                                                idapptamanhos: -1
                                            }, {
                                                Disponivel: 1,
                                                NomeReduzido: 'Media',
                                                sabores: 1,
                                                Tipo: 1,
                                                idapptamanhos: -2
                                            }, {
                                                Disponivel: 1,
                                                NomeReduzido: 'Pequena',
                                                sabores: 1,
                                                Tipo: 1,
                                                idapptamanhos: -3
                                            }],
                                        massas: [],
                                        bordas: []

                                    })
                                    setDrawerCat(true)
                                },
                                onCancel: () => {
                                    setCategoria({
                                        catDescricao: '',
                                        limitarHora: 0,
                                        tipo: 0,
                                        Ativa: 1,
                                        hora1: '10:00',
                                        hora2: '14:00',
                                        produtos: [],
                                        ispattern: 1

                                    })
                                    //  setOpenCategoryModel(false)
                                    setDrawerCat(true)

                                }
                            });
                        }}>
                        Adicionar Categoria
                    </ButtonAntd>


                    <ButtonAntd type="link" icon={<OrderedListOutlined />}
                        className="categoria-plus-button"
                        onClick={() => {
                            if (!permissions?.includes(GERENTE)) {
                                modal.warning({
                                    title: "Acesso negado!",
                                    content: "Você não possui permissão para executar essa operação! "
                                })
                                return
                            }
                            setIsModalVisible(true)
                        }}>
                        Reordenar
                    </ButtonAntd>
                </div>

                {
                    (loading === true && categorias?.length === 0) && <div>
                        {
                            ["skelot-1", "skelot-2", "skelot-3", "skelot-4"].map((item) => {
                                return <Skeleton loading active avatar key={item}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.avatar} />}
                                        title={<a href={item.href}>{item.title}</a>}
                                    />
                                </Skeleton>
                            })
                        }
                    </div>
                }

                <div className="category">
                    {
                        categorias.map((item, index) => (
                            <div className="category-content" key={"catitem".concat(item.idcategorias)}>
                                <div className="category-content-header">
                                    <div className="category-content-title"
                                        onClick={() => {

                                            if (!permissions?.includes(GERENTE)) {
                                                modal.warning({
                                                    title: "Acesso negado!",
                                                    content: "Você não possui permissão para executar essa operação! "
                                                })
                                                return
                                            }

                                            setCategoria(item)
                                            setDrawerCat(true)
                                        }}>
                                        {item.catDescricao}
                                    </div>
                                    <div className="category-content-buttons">
                                        <Switch
                                            style={{ marginRight: 10, marginLeft: 10, width: 80 }}
                                            checkedChildren={'Ativada'}
                                            unCheckedChildren={'Pausada'}
                                            checked={item.Ativa === 1}
                                            onChange={(e) => {
                                                if (!permissions?.includes(GERENTE)) {
                                                    modal.warning({
                                                        title: "Acesso negado!",
                                                        content: "Você não possui permissão para executar essa operação! "
                                                    })
                                                    return
                                                }

                                                var newcategorias = Object.assign({}, categorias)
                                                newcategorias[index].Ativa = item.Ativa === 1 ? 0 : 1
                                                setCategorias(Object.values(newcategorias))
                                                salvarCategoria(newcategorias[index])
                                            }} />

                                        <Space size="middle" onClick={() => {
                                            if (!permissions?.includes(GERENTE)) {
                                                modal.warning({
                                                    title: "Acesso negado!",
                                                    content: "Você não possui permissão para executar essa operação! "
                                                })
                                                return
                                            }

                                            Modal.confirm({
                                                zIndex: 99999999,
                                                title: 'Atenção!',
                                                icon: <QuestionCircleOutlined size='small' />,
                                                content: 'Deseja realmente excluir a categoria '.concat(item.catDescricao, '?'),
                                                okText: 'Confirmar',
                                                cancelText: 'Cancelar',
                                                onOk: () => {
                                                    excluirCategoria(item)
                                                },
                                                onCancel: () => {

                                                }
                                            });
                                        }}>
                                            <DeleteOutlined
                                                className="category-content-button-icon"
                                                style={{ fontSize: 20, cursor: "pointer", marginLeft: 10, color: 'gray' }} />
                                        </Space>
                                    </div>
                                </div>
                                <Divider />

                                {//redenriza os produtos
                                    renderTableProdutos(item, index)
                                }

                                <div className="cardapio-product-new">
                                    <ButtonAntd icon={<PlusOutlined />}
                                        type='primary' size='small'
                                        onClick={() => {
                                            if (!permissions?.includes(GERENTE)) {
                                                modal.warning({
                                                    title: "Acesso negado!",
                                                    content: "Você não possui permissão para executar essa operação! "
                                                })
                                                return
                                            }
                                            setProduto({
                                                AtivarPromocao: 0,
                                                CalcularComplenetos: 2,
                                                Categoria: item.catChave,
                                                Combo: 0,
                                                Disponivel: 1,
                                                Info: "",
                                                NomeReduzido: "",
                                                ispattern: item.ispattern,
                                                tamanhos: item.tamanhos,
                                                ProdutoID: 0,
                                                Quant: 1,
                                                Tipo: 0,
                                                Total: 0,
                                                Unidade: "UN",
                                                Url: "",
                                                ValorPromocao: 0,
                                                Venda: 0,
                                                appcategoriaid: item.idcategorias,
                                                grupos: [],
                                                indispdom: 0,
                                                indispqua: 0,
                                                indispqui: 0,
                                                indispsab: 0,
                                                indispseg: 0,
                                                indispsex: 0,
                                                indispter: 0,
                                                ordem: 0,
                                                path: item.path,
                                                sabores: 0,
                                                tamanho: 0,
                                                tamanhoid: 0,
                                                valorUnitarioFinal: 0
                                            })
                                            setDrawerProd(true)
                                        }} danger>
                                        Adicionar Item
                                    </ButtonAntd>

                                </div>
                            </div>
                        ))
                    }
                </div>

                {
                    changeProd.length > 0 && <Affix offsetBottom={5} offsetTop={undefined}>
                        <div style={{
                            padding: 10, backgroundColor: '#f5f5f5', marginBottom: 0,
                            borderWidth: 1, borderColor: '#DCDCDC', borderTopStyle: 'solid'
                        }}>

                            <div className="cardapio-options-buttons">
                                <div style={{ color: 'royalblue', fontSize: 16, fontWeight: '500' }}>
                                    {
                                        changeProd.length > 1 ? "Você alterou o preço de ".concat(changeProd.length, ' produtos.') :
                                            "Você alterou o preço de 1 produto."
                                    }
                                </div>
                                <div>
                                    <ButtonAntd size="large" danger type="default" style={{ marginRight: 20, width: 130 }}
                                        icon={<StopOutlined />}
                                        onClick={() => {
                                            setChangeProd([])
                                            getCategorias()
                                        }}>
                                        Cancelar
                                    </ButtonAntd>

                                    <ButtonAntd size="large" type="primary" icon={<CheckOutlined />}
                                        onClick={() => setValuesProduto()}>
                                        Salvar alterações
                                    </ButtonAntd>
                                </div>
                            </div>
                        </div>
                    </Affix>
                }



            </div>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseCategoria}>
                <MenuItem
                    onClick={() => {
                        setDrawerCat(true)
                        handleCloseCategoria()
                    }}>Editar</MenuItem>
                <MenuItem onClick={() => {
                    /*
                    setExcluir({
                        tipo: 'categoria'
                    })
                    */
                    handleCloseCategoria()
                    //  setModalExcluir(true)
                }}>Excluir</MenuItem>
            </Menu>

            <Drawer
                bodyStyle={{ padding: 0 }}
                placement={'right'}
                closeIcon={null}
                closable={false}
                width={isBrowser ? 700 : '100%'}
                onClose={toggleDrawerCat(false)}
                open={drawerCat}
                key={'right111'}>
                <Category categoria={categoria}
                    setDrawerCat={setDrawerCat}
                    getCategorias={getCategorias} />
            </Drawer>

            <Modal
                closable={false}
                title="Qual modelo de categoria?"
                open={openCategoryModel}
                centered
                onCancel={() => {
                    setCategoria({
                        catDescricao: '',
                        limitarHora: 0,
                        tipo: 0,
                        Ativa: 1,
                        hora1: '10:00',
                        hora2: '14:00',
                        produtos: [],
                        ispattern: 1

                    })
                    setOpenCategoryModel(false)
                    setDrawerCat(true)
                }}
                cancelText={"Padrão"}
                okText={"Por tamanho"}
                onOk={() => {
                    setCategoria({
                        catDescricao: '',
                        limitarHora: 0,
                        tipo: 100,
                        Ativa: 1,
                        hora1: '10:00',
                        hora2: '14:00',
                        ispattern: 0,
                        tamanhos: [
                            {
                                Disponivel: 1,
                                NomeReduzido: 'Grande',
                                sabores: 2,
                                Tipo: 1,
                                idapptamanhos: -1
                            }, {
                                Disponivel: 1,
                                NomeReduzido: 'Media',
                                sabores: 1,
                                Tipo: 1,
                                idapptamanhos: -2
                            }, {
                                Disponivel: 1,
                                NomeReduzido: 'Pequena',
                                sabores: 1,
                                Tipo: 1,
                                idapptamanhos: -3
                            }],
                        massas: [],
                        bordas: []

                    })
                    setOpenCategoryModel(false)
                    setDrawerCat(true)

                }}>

            </Modal>



            <Dialog onClose={() => setOpenCategoryModel(false)}
                aria-labelledby="simple-dialog-title"
                open={false}>
                <DialogTitle id="simple-dialog-title">Qual modelo de categoria?</DialogTitle>
                <div className="cardapio-category-models">

                    <Button
                        className="cardapio-category-model"
                        size="small"
                        variant="outlined" onClick={() => {
                            setCategoria({
                                catDescricao: '',
                                limitarHora: 0,
                                tipo: 0,
                                Ativa: 1,
                                hora1: '10:00',
                                hora2: '14:00',
                                produtos: [],
                                ispattern: 1

                            })
                            setOpenCategoryModel(false)
                            setDrawerCat(true)
                        }}>
                        PADRÃO
                    </Button>

                    <Button
                        className="cardapio-category-model"
                        size="small"
                        variant="outlined" onClick={() => {
                            setCategoria({
                                catDescricao: '',
                                limitarHora: 0,
                                tipo: 100,
                                Ativa: 1,
                                hora1: '10:00',
                                hora2: '14:00',
                                ispattern: 0,
                                tamanhos: [
                                    {
                                        Disponivel: 1,
                                        NomeReduzido: 'Grande',
                                        sabores: 2,
                                        Tipo: 1,
                                        idapptamanhos: -1
                                    }, {
                                        Disponivel: 1,
                                        NomeReduzido: 'Media',
                                        sabores: 1,
                                        Tipo: 1,
                                        idapptamanhos: -2
                                    }, {
                                        Disponivel: 1,
                                        NomeReduzido: 'Pequena',
                                        sabores: 1,
                                        Tipo: 1,
                                        idapptamanhos: -3
                                    }],
                                massas: [],
                                bordas: []

                            })
                            setOpenCategoryModel(false)
                            setDrawerCat(true)
                        }}>
                        POR TAMANHO
                    </Button>
                </div>
            </Dialog>



            {/* PRODUTO */}
            <Drawer
                bodyStyle={{ padding: 0 }}
                destroyOnClose={true}
                placement={'right'}
                closable={false}
                closeIcon={null}
                width={isBrowser ? 680 : '100%'}
                onClose={toggleDrawerProd(false)}
                open={drawerProd}
                key={'right22'}
                style={{ height: '100vh', maxHeight: '100%' }}
            >
                <Product produto={produto}
                    setDrawerProd={setDrawerProd}
                    getProdutos={getProdutos}
                    getCategorias={getCategorias}
                    categorias={categorias} />
            </Drawer>



            <Menu
                id="simple-menu-2"
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                onClose={handleMenuCloseProduto}>
                <MenuItem onClick={() => {
                    setDrawerProd(true)
                    handleMenuCloseProduto()
                }}>Editar</MenuItem>
                <MenuItem onClick={() => {
                    /*
                    setExcluir({
                        tipo: 'produto'return
                    })
                    */
                    handleMenuCloseProduto()
                    // setModalExcluir(true)
                }}>Excluir</MenuItem>
            </Menu>



            {/* MODAL REORDENAR*/}
            <Modal title="Reordenar cardápio"
                centered
                width={800}
                open={isModalVisible}
                footer={null}
                onCancel={() => { setIsModalVisible(false) }}>
                <ReorderGeneric lista={categorias} salveCategoryOrder={salveCategoryOrder}
                    showModalOrdenar={showModalOrdenar} />
            </Modal>
        </React.Fragment>
    }

    function renderMobile() {
        return <div style={{ backgroundColor: 'white' }}>
            {
                renderArea()
            }
        </div>
    }



}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    categoria: state.adminReducer.categoria,
    produto: state.adminReducer.produto,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = dispatch => ({
    setCategoria: (categoria) => dispatch(AdminAction.save_categoria(categoria)),
    setProduto: (produto) => dispatch(AdminAction.save_produto(produto))
})

export default connect(mapStateToProps, mapDispatchToProps)(Cardapio)

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './promotion.css'
import * as AdminAction from '../../../store/actions/admin.action'
//import 'antd/dist/antd.css';
import { Switch,  Row, Col, List, Alert, Space } from 'antd';
import { moneyMask, valorMonetarioOnChange } from '../../../services/util'
import { TextField } from '@mui/material';

export const Promotion = (props) => {

  const { produto, setProduto } = props

  return <div>
    <div>
      <Alert message={"Produtos em promoção vendem mais!"}
        showIcon
        description={"Ao habilitar uma promoção seu produto será sinalizado que está com preço promocional atraindo mais atenção de seus clientes."}>

      </Alert>

    </div>
    <br />


    <List bordered size='small' style={{ marginTop: 5 }}>
      <List.Item
        actions={[<Switch style={{ marginLeft: 8 }}
          checkedChildren="Sim" unCheckedChildren="Não"
          checked={produto.AtivarPromocao === 1 ? true : false}
          onChange={(value) => {
            console.log(value)
            setProduto({ ...produto, AtivarPromocao: value === true ? 1 : 0 })
          }} />]}>
        <List.Item.Meta
          title={"Ativar Promoção"}
          description={
            <Space direction='vertical'>
              <div>
                Seu produto será exibido com destaque de preço promocional.
              </div>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Space direction='horizontal' size={'small'}>
                    <TextField
                      size='small'
                      label="Valor normal"
                      disabled
                      style={{
                        width:130
                      }}
                      variant='outlined'
                      value={produto.Venda} />

                    <TextField
                      size='small'
                      variant='outlined'
                      style={{
                        width:130
                      }}
                      label="Valor Promocional"
                      disabled={produto.AtivarPromocao === 1 ? false : true}
                      value={moneyMask(produto.ValorPromocao)}
                      onChange={(event) => {
                        var value = valorMonetarioOnChange(event)
                     //   if (parseFloat(produto.Venda) < parseFloat(value))
                     //     setProduto({ ...produto, ValorPromocao: moneyMask(produto.Venda) })
                     //   else
                          setProduto({ ...produto, ValorPromocao: moneyMask(value) })
                      }}
                    />
                  </Space>
                </Col>
              </Row>
            </Space>
          }
        />
      </List.Item>
    </List>
  </div>
}


const mapStateToProps = (state) => ({
  token: state.tokenReducer.token,
  produto: state.adminReducer.produto
})

const mapDispatchToProps = dispatch => ({
  setProduto: (produto) => dispatch(AdminAction.save_produto(produto))
})


export default connect(mapStateToProps, mapDispatchToProps)(Promotion)

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './myapp.css'
import './devices.min.css'
import { Card, Button, Space, Alert, QRCode, Tooltip } from 'antd'
import { LinkOutlined, LoadingOutlined, DownloadOutlined, CopyOutlined } from '@ant-design/icons'
import { removerAcento } from '../../services/util'
import * as ActionToken from '../../store/actions/token.action'
import { api } from '../../services/api.service'
import { setNotification } from '../../services/dal.service'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import useClipboard from '../../hooks/useClipboard';


export const MyApp = (props) => {

    const copyToClipboard = useClipboard();
    const { token } = props
    const [config, setConfig] = useState({})
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const [url, setUrl] = useState('https://menuengfood.com.br')

    useEffect(() => {
        load()
    }, [])


    useEffect(() => {
        if (config?.path)
            setUrl('https://menuengfood.com.br/app/'.concat(config?.path))
        /*  
        setUrl(removerAcento("https://menuengfood.com.br/"
              .concat(config?.Estado, '/', config?.Cidade?.toLowerCase(), "/", config?.path)).toLowerCase())
              */
    }, [config])



    const downloadQRCode = () => {
        const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = 'QRCode.png';
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };


    async function load() {
        try {

            var response = await api.get('/api/v2.0/config', {
                headers: {
                    'x-access-token': token,
                },
            })
            setConfig(response.data)
            setLoading(false)
        } catch (error) {
            // console.log('error', error.message)
            setNotification(error.message, 'error')
            navigate('/login')
        }
    }



    return <div style={{ padding: 20 }}>

        <Card >
            <Space direction={isMobile ? 'vertical' : 'horizontal'}>
                {config?.path && <div data-v-27e14838=""
                    className="d-none d-sm-block pr-3 "
                    style={{ width: 200, margin: 20 }}>
                    <div data-v-76531ec2="" className='dev-tamanho' data-v-27e14838="" style={{ width: '100%', height: '360.169px' }}>
                        <div data-v-76531ec2=""
                            className="marvel-device iphone-x">
                            <div data-v-76531ec2="" className="top-bar"></div>
                            <div data-v-76531ec2="" className="sleep"></div>
                            <div data-v-76531ec2="" className="bottom-bar"></div>
                            <div data-v-76531ec2="" className="volume"></div>
                            <div data-v-76531ec2="" className="overflow">
                                <div data-v-76531ec2="" className="shadow shadow--tr"></div>
                                <div data-v-76531ec2="" className="shadow shadow--tl"></div>
                                <div data-v-76531ec2="" className="shadow shadow--br"></div>
                                <div data-v-76531ec2="" className="shadow shadow--bl"></div>
                            </div> <div data-v-76531ec2="" className="inner-shadow"></div>
                            <div data-v-76531ec2="" className="screen">
                                <iframe data-v-76531ec2="" src={url}
                                    frameborder="0px" loading="lazy" allowfullscreen="allowfullscreen">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
                }

                <Space direction='vertical'>
                    <Alert
                        message={<strong>Meu Cardápio Digital</strong>}
                        description={
                            <Space direction='vertical'>
                                <Space direction={'horizontal'} size={'small'}>
                                    <LinkOutlined color='blue' />
                                    <a target='_blank' href={url}
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: '0.75em',
                                            textTransform: 'lowercase'
                                        }}>
                                        {loading ? <Space direction='horizontal' size={'small'}> <LoadingOutlined /> carregando... </Space> :
                                            url
                                        }


                                    </a>
                                </Space>
                                {
                                    !loading && <Tooltip title={url} >
                                        <Button type='primary' icon={<CopyOutlined />}
                                        onClick={()=>{
                                            copyToClipboard(url)
                                        }}>Copiar link</Button>
                                    </Tooltip>
                                }

                                <Space direction={isMobile ? 'vertical' : 'horizontal'}>
                                    <div id="myqrcode">
                                        <QRCode size={180}
                                            status={loading ? 'loading' : 'active'}
                                            value={url} />
                                    </div>
                                    {
                                        !loading && <Button type='dashed' icon={<DownloadOutlined />}
                                            onClick={downloadQRCode}
                                            style={{ marginLeft: 15 }}>Baixar</Button>
                                    }

                                </Space>

                            </Space>
                        }
                        type="info"
                    />
                </Space>

            </Space>
        </Card>
    </div>

}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(MyApp)
import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { api } from '../../services/api.service'
import { Box, Grid, Paper, Link, TextField, CssBaseline, Snackbar, Typography } from '@material-ui/core';
import { setNotification } from '../../services/dal.service'
import * as ActionToken from '../../store/actions/token.action'
import { Alert, Button, Button as ButtonAntd, Input, Space, App } from 'antd'
import './login.css'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import logo from '../../img/logomenu.png'
import { useNavigate } from 'react-router-dom'
import { Copyright } from '../../components/copyright';




export const LoginPage = (props) => {

  const navigate = useNavigate()
  const { notification } = App.useApp()
  const { token, saveToken, saveTokenFull } = props

  const [email, setEmail] = React.useState('')
  const [senha, setSenha] = React.useState('')
  const [loading, setLoading] = React.useState(false)


  useEffect(() => {
    if (token) {
      // props.history.push('/admin')
    }
  }, [])


  async function login() {
    try {
      //  console.log('aqui')
      setLoading(true)
      const response = await api.post('api/v2.0/usuarios/login', { token: email, pwd: senha });
      saveTokenFull(response.data)
      saveToken(response.data.token)
      navigate('/')
      setLoading(false)
      // console.log('login success')
    } catch (error) {
      console.log("error", error.message)
      setLoading(false)
      if (error.message === 'Request failed with status code 401') {
        notification.error({
          message: "Atenção",
          description: "Token e/ou senha inválido!"
        })
      } else {
        notification.error({
          message: "Atenção",
          description: error.message
        })
      }
    }
  }


  return <div className='plano-de-fundo'>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      justifyContent: 'center',
      width: 433,
      alignItems: 'center'
    }}>
      <strong style={{ fontSize: '1.7em', color: 'white' }}>Portal Menuengfood</strong>
      <div style={{ fontSize: '0.75em', color: 'whitesmoke' }}>Por favor entre com seu token e senha</div>
      <Input style={{ marginTop: 35 }} size='large' placeholder='Informe seu Token(e-mail)'
        prefix={<UserOutlined />}
        value={email}
        onChange={(e) => setEmail(e.target.value)} />
      <Input.Password style={{ marginTop: 10 }} size='large' placeholder='Informe a senha'
        prefix={<LockOutlined />}
        value={senha}
        onChange={(e) => setSenha(e.target.value)} />

      <Button
        style={{ marginTop: 35 }}
        block
        size='large'
        loading={loading}
        type="primary"
        onClick={() => {
          login()
        }}>Entrar</Button>

      <Space direction='horizontal'>
        {/*
        <Button type='link'
          style={{ marginTop: 20, color: 'whitesmoke' }}
          variant="body2"
          onClick={() => {
            navigate("/register")
          }}>
          Cadastrar Restaurante
        </Button>
      */}
        <Button type='link'
          style={{ marginTop: 20, color: 'whitesmoke' }}
          variant="body2"
          onClick={() => {
            navigate("/forgot");
          }}>
          Esqueceu a senha?
        </Button>
      </Space>
      <Copyright />
    </div>
  </div>


}


const mapStateToProps = (state) => ({
  token: state.tokenReducer.token
})

const mapDispatchToProps = dispatch => ({
  saveToken: (token) => dispatch(ActionToken.save_token(token)),
  saveTokenFull: (token) => dispatch(ActionToken.save_token_full(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
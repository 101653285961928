import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Row, Col, QRCode, Button } from 'antd'
import moment from 'moment'
import { removerAcento } from '../../services/util'
import ReactToPrint from 'react-to-print';
import './recibo.css'

export const Recibo = (props) => {

    const componentRef = useRef();
    const { selected, tokenFull, setSelected, setOpenAtivar } = props

   // console.log(tokenFull)

    function renderPagamento() {


        let ispago = "Total a pagar"
        if (selected?.payment?.paid === 1) {
            ispago = "Total pago"
        } else if (selected?.payment?.paid === 2) {
            ispago = "Total pago online"
        }

        return <Col span={24} className={'recibo-fontSize'}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <strong>{ispago}</strong>
                <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                    {selected?.valor.toFixed(2)}
                </strong>
            </div>
        </Col>
    }

    return !selected ?
        <div />
        : <>
            <div ref={componentRef} style={{ margin: -14 }}>
                <Row
                    className={'recibo-fontSize'}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0,
                        margin: 30
                    }}>
                    <Col span={24} className={'recibo-fontSize'}>
                        <strong>{tokenFull.fantasia} </strong>
                    </Col>
                    <Col span={24} className={'recibo-fontSize'}>
                        {tokenFull?.Endereco?.concat(", ",
                            tokenFull?.numero ? tokenFull?.numero : '',
                            " ",
                            tokenFull?.complemnto ? tokenFull.complemnto : '',
                            " - ",
                            tokenFull?.Bairro ? tokenFull.Bairro : '',
                            ", ",
                            tokenFull.Cidade ? tokenFull.Cidade : '')}
                    </Col>
                    <Col span={24} className={'recibo-fontSize'}>
                        <strong> BR-{selected.pedid} </strong>
                    </Col>
                    <Col span={24} className={'recibo-fontSize'}>
                        <hr style={{ marginTop: 2, marginBottom: 2 }} />
                    </Col>
                    <Col span={24} className={'recibo-fontSize'}>
                        <span>Data: {moment(selected.dataserver).format('DD/MM/YYYY')}</span>
                        <span style={{ marginLeft: 5 }}> Hora: {moment(selected.dataserver).format('HH:mm:ss')} </span>
                        <hr style={{ marginTop: 2, marginBottom: 2 }} />
                    </Col>

                    <Col span={24} className={'recibo-fontSize'}>
                        <strong> {selected.type === "DELIVERY" ? 'Pedido para Delivery' : 'Pedido para Retirada'} </strong>
                        <hr style={{ marginTop: 2, marginBottom: 2 }} />
                    </Col>

                    <Col span={24} className={'recibo-fontSize'}>
                        <div>Nome: {selected?.user.nome} </div>
                        <div>Telefone: {selected?.user.telefone} </div>
                        {selected.type === "DELIVERY" && <>
                            <div>Endereço: {selected.address?.logradouro?.concat(' ', selected.address?.numero, 
                            ', ', selected.address?.complemento)}
                            </div>
                            <div>Bairro: {selected.address?.bairro} - {selected.address?.localidade}</div>
                            <div>Referência: {selected.address?.referencia}</div>
                        </>}
                        <hr style={{ marginTop: 2, marginBottom: 2 }} />
                    </Col>
                    <Col span={24} className={'recibo-fontSize'}>
                        {selected?.itens?.map((item, index) => {
                            return <Row key={'ped-it-'.concat(index)} style={{ fontWeight: 700 }}>
                                <Col span={4} className={'recibo-fontSize-item'}>
                                    {item.Quant} X

                                </Col>
                                <Col span={12} className={'recibo-fontSize-item'}>
                                    {item.NomeReduzido}
                                </Col>
                                <Col span={4} className={'recibo-fontSize-item'}>
                                    {(parseFloat(item.valorUnitarioFinal)).toFixed(2)}
                                </Col>
                                <Col span={4} style={{ textAlign: 'right', paddingRight: 8 }} className={'recibo-fontSize-item'}>
                                    {(parseFloat(item.valorUnitarioFinal) * parseFloat(item.Quant)).toFixed(2)}
                                </Col>
                                {
                                    item?.complementos.length > 0 && <Col span={24}>
                                        {item?.complementos.map((c, indexc) => {
                                            return <Row style={{
                                                color: 'gray',
                                                marginLeft: 10,
                                                fontWeight: 'normal',

                                            }} key={"comp-it-".concat(indexc)}>
                                                <Col className='recibo-fontSize-subitem' span={4}>{c.Quant} x</Col>
                                                <Col className='recibo-fontSize-subitem' span={20}>{c.NomeReduzido}</Col>
                                            </Row>
                                        })
                                        }
                                    </Col>
                                }

                                {
                                    item?.obs && <Col span={24} className='recibo-fontSize-obs'>
                                        {"Obs: ".concat(item.obs)}
                                    </Col>
                                }

                            </Row>
                        })}
                        <Col span={24} className={'recibo-fontSize'}>
                            <hr style={{ marginTop: 2, marginBottom: 2 }} />
                        </Col>
                        <Col span={24} className={'recibo-fontSize'}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <strong>Subtotal</strong>
                                <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                                    {(selected.valor - selected.taxa + (selected.cupom !== undefined ? selected.cupom.valor : 0)
                                        + (selected.fidelidade !== undefined ? selected.fidelidade.valor : 0)).toFixed(2).toString()}
                                </strong>
                            </div>
                        </Col>
                        {
                            selected.cupom && <Col span={24} className={'recibo-fontSize'}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <strong> Cupom </strong>
                                    <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                                        - {selected.cupom.valor.toFixed(2)}
                                    </strong>
                                </div>
                            </Col>
                        }
                        {
                            selected.fidelidade && <Col span={24} className={'recibo-fontSize'}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <strong> {selected.fidelidade.tag} </strong>
                                    <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                                        - {selected.fidelidade.valor.toFixed(2)}
                                    </strong>
                                </div>
                            </Col>
                        }
                        <Col span={24} className={'recibo-fontSize'}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <strong>Taxa delivery</strong>
                                <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                                    {selected?.taxa.toFixed(2)}
                                </strong>
                            </div>
                        </Col>
                        <Col span={24} className={'recibo-fontSize'}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <strong>Total</strong>
                                <strong style={{ textAlign: 'right', paddingRight: 5 }}>
                                    {selected?.valor.toFixed(2)}
                                </strong>
                            </div>
                            <hr style={{ marginTop: 2, marginBottom: 2 }} />
                        </Col>
                        {
                            renderPagamento()
                        }

                        <Col span={24} className={'recibo-fontSize'}>
                            <hr style={{ marginTop: 2, marginBottom: 2 }} />
                        </Col>
                        {
                            selected?.obs && <Col span={24} className='recibo-fontSize-obs'>
                                {"Obs: ".concat(selected.obs)}
                            </Col>
                        }
                        <Col span={24} style={{ textAlign: 'center' }} className={'recibo-fontSize'}>
                            Escaneie o QR Code abaixo e peça quando quiser!
                        </Col>

                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }}
                            className={'recibo-fontSize'}>
                            <QRCode value={removerAcento("https://menuengfood.com.br/"
                                .concat(tokenFull.Estado, '/', tokenFull.Cidade?.toLowerCase(), "/", tokenFull.path)).toLowerCase()} />
                        </Col>
                    </Col>
                </Row>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Button onClick={() => setSelected(undefined)}>Fechar</Button>
                {
                    tokenFull?.license !== 'free' ?
                        <ReactToPrint
                            trigger={() => <Button type='primary'
                                onClick={() => setSelected(undefined)}>
                                Imprimir
                            </Button>}
                            content={() => componentRef.current}
                        />
                        :
                        <Button type='primary'
                            onClick={() => {
                                setOpenAtivar(true)
                            }}>
                            Imprimir
                        </Button>
                }
            </div>

        </>
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Recibo)
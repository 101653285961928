import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment'

function reciboPDF(selected, tokenFull) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;


    const dados = selected?.itens?.map((item) => {
        return [{
            columns: [
                {
                    text: item.Quant,
                    width: 20,
                    fontSize: 7
                },
                {
                    text: 'x',
                    width: 10,
                    fontSize: 7,
                    alignment:'left'
                },
                {
                    text: item.NomeReduzido,
                    width: '*',
                    fontSize: 7
                }
                ,
                {
                    text: (parseFloat(item.valorUnitarioFinal)).toFixed(2),
                    width: 20,
                    fontSize: 7
                },
                
                {
                    text: (parseFloat(item.valorUnitarioFinal) * parseFloat(item.Quant)).toFixed(2),
                    width: 20,
                    fontSize: 7
                }
            ]
        }]
    });



    const details = [
        {
            text: tokenFull?.Endereco?.concat(", ",
                tokenFull?.numero ? tokenFull?.numero : '',
                " ",
                tokenFull?.complemnto ? tokenFull.complemnto : '',
                " - ",
                tokenFull?.Bairro ? tokenFull.Bairro : '',
                ", ",
                tokenFull.Cidade ? tokenFull.Cidade : ''),
            bold: true,
            fontSize: 7
        },
        {
            text: 'BR-'.concat(selected.pedid),
            fontSize: 7
        },
        {
            text: 'Data:'.concat(moment(selected.dataserver).format('DD/MM/YYYY'), '  hora: ',
                moment(selected.dataserver).format('HH:mm:ss')),
            fontSize: 7
        },
        {
            text: selected.type === "DELIVERY" ? 'Pedido para Delivery' : 'Pedido para Retirada',
            fontSize: 7
        },
        {
            text: 'Nome: '.concat(selected?.user.nome),
            fontSize: 7
        },
        {
            text: 'Telefone: '.concat(selected?.user.telefone),
            fontSize: 7
        },
        {
            text: selected.type === "DELIVERY" ? 'Endereço: '.concat(selected.address?.logradouro?.concat(' ', selected.address?.numero,
                ', ', selected.address?.complemento,
                '\nBairro: ', selected.address?.bairro, ' - ', selected.address?.localidade,
                '\nReferencia: ', selected.address?.referencia)) : '',
            fontSize: 7
        },
        ...dados


    ];



    const docDefinitios = {
        pageSize: 'A7',
        pageMargins: [5, 10, 5, 5],
        fontSize: 7,
        content: [details],
    }



    pdfMake.createPdf(docDefinitios).download();
}

export default reciboPDF;
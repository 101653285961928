import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
    Button, Space, Divider, Tag, Table, Avatar, Dropdown, Modal,
    Drawer, Row, Col, Select, Input
} from 'antd'
import {
    ClockCircleOutlined, CalendarOutlined, EnvironmentOutlined, MoreOutlined, GlobalOutlined, AndroidOutlined,
    CheckOutlined, PlusOutlined, ThunderboltOutlined, DollarOutlined, PrinterOutlined, CloseCircleOutlined, RightOutlined,
    InfoCircleOutlined, CloseOutlined
} from '@ant-design/icons'
import moment from 'dayjs'
import { useRecoilState } from 'recoil'
import { pedidosState } from '../../store/recoils/data'
import CAPACETE_PNG from '../../img/capacete.png'
import ModalAtivar from '../../components/modalAtivar'
import { useNavigate } from 'react-router-dom'
import { api } from '../../services/api.service'
import {
    setNotification, getOrders, cancelarPedido,
    confirmarPedido, finalizado, emEntrega, updatePaid
} from '../../services/dal.service'
import { clearCaracteresFone } from '../../services/util'
import { Dropdown as DropdownBS } from 'react-bootstrap';
import './orders.css'
import Recibo from '../../components/recibo'
import ReciboCozinha from '../../components/reciboCozinha'
import { isMobile } from 'react-device-detect'
import ReciboPDF from '../../reports/recibo'
import Pedido from '../../components/pedido'








export const Orders = (props) => {



    const navigate = useNavigate()
    const { token, tokenFull } = props
    const [data, setData] = useRecoilState(pedidosState)
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [openAtivar, setOpenAtivar] = useState(false)
    const [items, setItens] = useState([])
    const [selected, setSelected] = useState(undefined)
    const [selectedCozinha, setSelectedCozinha] = useState(undefined)
    const [selectedCancel, setSelectedCancel] = useState(undefined)
    const [optionCancel, setOptionCancel] = useState(6)
    const [motivocancelamento, setMotivocancelamento] = useState('')
    const [pedido, setPedido] = useState(undefined)


    useEffect(() => {
        loadEntregadores()
        // console.log(tokenFull)
    }, [])

    useEffect(() => {
        let t = 0
        data?.map(item => {
            t = t + parseFloat(item.valor)
        })
        setTotal(t)
    }, [data])

    const handleButtonClick = (e) => {
        // console.log('click left button', e);
        e.stopPropagation()
    };




    async function downloadPDF(record) {
        ReciboPDF(record, tokenFull)
        //   return PDFViewer(<MyDocument />)
        //  await usePDF(<MyDocument />, `${__dirname}/example.pdf`);
    }


    async function setEntregador(item) {
        try {
            await api.put('/api/v2.0/pedido/entregador', item, {
                headers: {
                    'x-access-token': token,
                },
            })
            setNotification("Dados salvo com successo!", 'success')
            const ret = await getOrders(token)
            if (ret)
                setData(ret.orders)
        } catch (error) {
            console.log(error.message)
            setNotification("Erro ao carregar entregadores!", 'error')

        }
    }


    async function loadEntregadores() {
        try {
            // console.log('load entregadores')
            var response = await api.get('/api/v2.0/usuarios/equipe/entregadores', {
                headers: {
                    'x-access-token': token,
                },
            })
            var _ent = []
            // console.log(response.data)
            response.data.forEach(element => {
                _ent.push(
                    {
                        label: element.username,
                        key: 'CE'.concat(element.idappuser),
                        fone: element.fone,
                        cargosid: element.cargosid,
                        appuserid: element.idappuser
                    })
            });

            _ent.push({
                label: <div style={{ color: 'royalblue', fontWeight: 600 }}>Criar novo entregador</div>,
                key: 'CE0',
                icon: <PlusOutlined style={{ color: 'royalblue' }} />,
                idappuser: 0
            })
            setItens(_ent)
        } catch (error) {
            console.log(error.message)
            setNotification("Erro ao carregar entregadores!", 'error')

        }
        //setLoading(false)
    }



    function getStatus(record) {
        switch (record.status) {
            case 0:
                return <Tag size='small' color='purple'>Pendente</Tag>
            case 1:
                return <Tag size='small' color='green'>Aceito</Tag>
            case 2:
                return <Tag size='small' color='#cd201f' style={{ fontSize: '0.7em' }}>Cancelado</Tag>
            case 3:
                return <Tag size='small' color='#cd201f'>Cancelado</Tag>
            case 4:
                return <Tag size='small' color='orange'>Em entrega</Tag>
            case 5:
                return <Tag size='small' color='orange'>Pronto para retirada</Tag>
            case 6:
                return <Tag size='small' color='#cd201f'>Cancelado</Tag>
            case 9:
                return <Tag size='small' color='purple'>Pendente pagamento PIX</Tag>
            case 10:
                return <Tag size='small' color='blue'>Entregue</Tag>
            default:
                return <Tag size='small' color='gray'>Desconhecido</Tag>
        }

    }

    function renderButtons(record, noBtnRight) {

        const isCancelado = !(record.status !== 2 && record.status !== 3 && record.status !== 6)

        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: noBtnRight ? 'center' : 'right'
            }}>
            {
                record.status === 0 ?
                    <Space direction='horizontal'>
                        <Button type='primary'
                            danger
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 4,
                                paddingLeft: 12,
                                paddingRight: 12
                            }}
                            onClick={(event) => {
                                event.stopPropagation()
                                setSelectedCancel(record)
                            }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <CloseCircleOutlined />
                                <MoreOutlined style={{ position: 'absolute', top: 2, right: 2 }} />
                            </div>
                            <div style={{ fontSize: '0.8em' }}>
                                Rejeitar
                            </div>
                        </Button>
                        <Button
                            type='primary'
                            disabled={loading}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 4,
                                paddingLeft: 16,
                                paddingRight: 16
                            }} onClick={(event) => {
                                event.stopPropagation()
                                aceitarPedido({ ...record })
                            }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <CheckOutlined />
                                <MoreOutlined style={{ position: 'absolute', top: 2, right: 2 }} />
                            </div>
                            <div style={{ fontSize: '0.8em' }}>
                                Aceitar
                            </div>
                        </Button>
                    </Space>
                    :
                    <Space direction='horizontal'>
                        {isCancelado === false && <> <DropdownBS size="sm" onClick={(event) => event.stopPropagation()}>
                            <DropdownBS.Toggle
                                disabled={(record?.status === 2 || record?.status === 3 || record?.status === 6)}
                                size="sm"
                                style={{
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    alignItems: 'center',
                                    display: 'flex',
                                    color: 'white',
                                    backgroundColor: 'royalblue',
                                    borderColor: 'royalblue'
                                }}
                                variant="secondary"
                                id="dropdown-basic">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <ThunderboltOutlined />
                                    <span style={{ marginRight: 8, marginLeft: 5, fontSize: '0.8em' }}> {"status"} </span>
                                </div>

                            </DropdownBS.Toggle>
                            <DropdownBS.Menu style={{ fontSize: '0.95em' }}>
                                {renderStatusOption(record)}
                            </DropdownBS.Menu>
                        </DropdownBS>


                            <DropdownBS size="sm" onClick={(event) => event.stopPropagation()}>
                                <DropdownBS.Toggle
                                    disabled={(record?.status === 2 || record?.status === 3 || record?.status === 6)}
                                    size="sm"
                                    style={{
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        paddingLeft: 0,
                                        paddingRight: 3,
                                        alignItems: 'center',
                                        display: 'flex',
                                        color: 'white',
                                        backgroundColor: 'orange',
                                        borderColor: 'orange'
                                    }}
                                    variant="secondary"
                                    id="dropdown-basic">
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <DollarOutlined />
                                        <span style={{ marginRight: 8, marginLeft: 5, fontSize: '0.8em' }}> {"Pagamento"} </span>
                                    </div>

                                </DropdownBS.Toggle>
                                <DropdownBS.Menu style={{ fontSize: '0.95em' }}>
                                    {
                                        record?.payment?.paid === 1 && <DropdownBS.Item href="#"
                                            onClick={() => {
                                                setPaid({ ...record }, 0)
                                            }}>Não pago</DropdownBS.Item>
                                    }
                                    {
                                        record?.payment?.paid === 0 && <DropdownBS.Item href="#"
                                            onClick={() => {
                                                setPaid({ ...record }, 1)
                                            }}>Pago</DropdownBS.Item>
                                    }

                                </DropdownBS.Menu>
                            </DropdownBS>


                            <DropdownBS size="sm" onClick={(event) => event.stopPropagation()}>
                                <DropdownBS.Toggle
                                    disabled={(record?.status === 2 || record?.status === 3 || record?.status === 6)}
                                    size="sm"
                                    style={{
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        paddingLeft: 0,
                                        paddingRight: 3,
                                        alignItems: 'center',
                                        display: 'flex',
                                        color: 'white',
                                        backgroundColor: 'green',
                                        borderColor: 'green'
                                    }}
                                    variant="secondary"
                                    id="dropdown-basic">
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <PrinterOutlined />
                                        <span style={{ marginRight: 8, marginLeft: 5, fontSize: '0.8em' }}> {"Imprimir"} </span>
                                    </div>
                                </DropdownBS.Toggle>
                                <DropdownBS.Menu style={{ fontSize: '0.95em' }}>
                                    <DropdownBS.Item href="#"
                                        onClick={() => {
                                            setSelectedCozinha(record)
                                        }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <PrinterOutlined style={{ marginRight: 10 }} /> Cozinha
                                        </div>
                                    </DropdownBS.Item>
                                    <DropdownBS.Item href="#"
                                        onClick={() => {
                                            setSelected(record)
                                        }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <PrinterOutlined style={{ marginRight: 10 }} /> Recibo
                                        </div>
                                    </DropdownBS.Item>
                                </DropdownBS.Menu>
                            </DropdownBS>
                        </>}

                    </Space>
            }

            {
                !noBtnRight && <Button type='link'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 4,
                        paddingLeft: 12,
                        paddingRight: 12
                    }}>
                    <RightOutlined />
                </Button>
            }

        </div>
    }



    function renderResumo(record) {
        let ispago = "Não pago"
        if (record?.payment?.paid === 1)
            ispago = "Pago"
        else if (record?.payment?.paid === 2)
            ispago = "Pago online"

        return window.encodeURIComponent("*Pedido: ".concat("BR-", record.pedid, "*\n",
            "🗓️ ", moment(record.dataserver).format('DD/MM/YYY'), "  ⏰ ", moment(record.dataserver).format('HH:mm'), '\n',
            "*Tipo de serviço*: ", record.type === 'PICKUP' ? 'Retirar na loja' : 'Delivery', "\n",
            "Nome: ", record?.user?.nome, "\n",
            "Telefone: ", clearCaracteresFone(record?.user?.telefone), "\n",
            "*Status do pagamento:* ", ispago, '\n',
            "Método de pagamento: ", record?.payment?.name, "\n",
            "*Total: R$ ", record?.valor.toFixed(2), "*\n"))

    }

    async function setEmEntrega(record) {
        try {
            setLoading(true)
            var tem = { ...record }
            var result = await emEntrega(tem, token)
            if (result) {
                const dp = await getOrders(token)
                setData(dp.orders)
                setPedido(undefined)
            }
            else
                setNotification("Erro ao processar solicitação!", 'error')
        } catch (error) {
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }

    async function aceitarPedido(record) {
        try {
            setLoading(true)
            var tem = record
            var result = await confirmarPedido(tem, token)
            if (result) {
                //  setNotification("Pedido confirmado!", 'success')
                const dp = await getOrders(token)
                setData(dp.orders)
                setPedido(undefined)
            }
            else
                setNotification("Erro ao processar solicitação!", 'error')
        } catch (error) {
            console.log(error.message)
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }


    async function rejeitarPedido(record) {
        try {
            setLoading(true)
            var tem = { ...record }
            tem.motivoCancelamento = motivocancelamento
            tem.motivocancelamento = motivocancelamento
            var result = await cancelarPedido(tem, token)

            if (result) {

                const dp = await getOrders(token)
                setData(dp.orders)
                setNotification("Pedido cancelado com sucesso!", 'success')
                setSelectedCancel(undefined)
                setPedido(undefined)
            }
            else
                setNotification("Erro ao processar solicitação!", 'error')
        } catch (error) {
            console.log(error.message)
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }



    async function setProntoParaEntrega(record) {
        try {
            setLoading(true)
            var tem = { ...record }
            const result = await finalizado(tem, token)
            if (result) {
                const dp = await getOrders(token)
                setData(dp.orders)
            }
            else
                setNotification("Erro ao processar solicitação!", 'error')
        } catch (error) {
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }

    async function setPaid(record, paid) {
        try {
            setLoading(true)
            var tem = record //Object.create({ ...record })
            tem.payment = { ...tem.payment }
            tem.payment.paid = paid
            const result = await updatePaid(tem, token)
            if (result) {
                const dp = await getOrders(token)
                setData(dp.orders)
                setPedido(undefined)
            }
            else
                setNotification("Erro ao processar solicitação!", 'error')
        } catch (error) {
            console.log(error.message)
            setNotification(error.message, 'error')
        }
        setLoading(false)
    }


    function renderStatusOption(record) {
        switch (record.status) {
            case 0:
                return <></>
            case 1:
            case 4:
            case 5:
                return <>
                    <DropdownBS.Item href="#" onClick={() => {
                        setEmEntrega(record)
                    }}>{record.type === 'DELIVERY' ? 'Em entrega' : 'Pronto para retirada'}</DropdownBS.Item>
                    <DropdownBS.Item href="#" onClick={() => {
                        setProntoParaEntrega(record)
                    }}>Entrege</DropdownBS.Item>
                </>
            case 2:
            case 3:
            case 6:
                return <></>
            default:
                return <></>

        }
    }


    function renderDropdownConversar(record) {

        return <>
            <DropdownBS.Item href="#" onClick={() => {
                window.open('https://api.whatsapp.com/send/?phone=55'
                    .concat(clearCaracteresFone(record?.user?.telefone), '&text=', renderResumo(record).toString()), '_blank', 'noreferrer');
            }}>Resumo do pedido</DropdownBS.Item>
            <DropdownBS.Item href="#" onClick={() => {
                window.open('https://api.whatsapp.com/send/?phone=55'
                    .concat(clearCaracteresFone(record?.user?.telefone), '&text=', "🙋‍♂️ Para ajudá-lo, confirme se ainda deseja o pedido."), '_blank', 'noreferrer');
            }}>Pedir confirmação</DropdownBS.Item>
            <DropdownBS.Item href="#" onClick={() => {
                window.open('https://api.whatsapp.com/send/?phone=55'
                    .concat(clearCaracteresFone(record?.user?.telefone), '&text=', "🛵 Entrega a caminho."), '_blank', 'noreferrer');
            }}>Entrega a caminho</DropdownBS.Item>
            <DropdownBS.Item href="#" onClick={() => {
                window.open('https://api.whatsapp.com/send/?phone=55'
                    .concat(clearCaracteresFone(record?.user?.telefone), '&text=', "🛵 O pedido chegou."), '_blank', 'noreferrer');
            }}>O pedido chegou</DropdownBS.Item>
            <DropdownBS.Item href="#" style={{ color: 'green', fontWeight: 'bold' }} onClick={() => {
                window.open('https://api.whatsapp.com/send/?phone=55'
                    .concat(clearCaracteresFone(record?.user?.telefone), '&text=', ""), '_blank', 'noreferrer');
            }}>Falar com o cliente</DropdownBS.Item>
        </>
    }



    return (
        <div style={{ margin: 10, display: 'flex', flexDirection: 'column' }}>
            <Space style={{ marginBottom: 5 }} direction='horizontal'>
                <Tag color='white'
                    style={{ minWidth: 200, display: 'flex', width: 'auto', justifyContent: 'left' }}>
                    <Space direction='horizontal' size={'small'} >
                        <div style={{ minWidth: 70 }}>
                            <div style={{ fontSize: '0.85em', color: 'gray' }}>Pedidos:</div>
                            <div style={{ color: 'blue', fontWeight: 600, fontSize: '1.07em' }}>{data?.length}</div>
                        </div>
                        <Divider type='vertical' />
                        <div style={{ minWidth: 70 }}>
                            <div style={{ fontSize: '0.85em', color: 'gray' }}>Ticket Médio:</div>
                            <div style={{ color: 'blue', fontWeight: 600, fontSize: '1.07em' }}>
                                R$ {(total / (data?.length > 0 ? data?.length : 1)).toFixed(2)}
                            </div>
                        </div>
                        <Divider type='vertical' />
                        <div style={{ minWidth: 70 }}>
                            <div style={{ fontSize: '0.85em', color: 'gray' }}>Total:</div>
                            <div style={{ color: 'blue', fontWeight: 600, fontSize: '1.07em' }}>R$ {total.toFixed(2)}</div>
                        </div>
                    </Space>
                </Tag>



            </Space>



            <Table dataSource={data ? data : []}
                loading={loading}
                size="small"
                pagination={false}
                footer={() => null}
                rowKey={'pedid'}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            console.log(record)
                            setPedido(record)
                        },
                    };
                }}>
                <Table.Column title={<span style={{ fontSize: '0.8em' }}>DATA</span>}
                    dataIndex="dataserver"
                    key="dataserver"
                    render={(text, record) => {
                        var tempo = parseFloat(moment(new Date()).diff(moment(record?.dataserver), 'minutes'))
                        if (tempo > 60)
                            tempo = '+ 60 min'
                        else
                            tempo = tempo + " min"

                        return <div style={{ display: 'flex', fontSize: '0.7em', flexDirection: 'column' }}>
                            <Space size={'small'} direction='horizontal'>
                                <span>BR-{record?.pedid} </span>
                                {record.platform === "web" ?
                                    <GlobalOutlined style={{ color: 'blue', fontSize: 16 }} /> :
                                    <AndroidOutlined style={{ color: 'green', fontSize: 16 }} />}
                            </Space>
                            <Space size={'small'} direction='horizontal'>
                                <Avatar size={20} style={{ backgroundColor: 'royalblue', fontSize: '0.9em' }}>
                                    M
                                </Avatar>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <ClockCircleOutlined />
                                    <span style={{ marginLeft: 3 }}> {tempo} </span>
                                </div>
                            </Space>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <CalendarOutlined style={{ marginRight: 3 }} />
                                {moment(record?.dataserver).format('DD/MM HH:mm')}
                            </div>
                        </div>
                    }} />
                <Table.Column
                    title={<span style={{ fontSize: '0.8em' }}>PEDIDOS</span>}
                    dataIndex="dataserver"
                    key="dataserver"
                    render={(text, record) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div style={{ color: 'royalblue', fontWeight: '600' }}>
                                    {record.type === 'PICKUP' ? 'Retirar na loja'
                                        :
                                        <div style={{ display: 'flex', flexDirection: 'row' }}
                                            onClick={(event) => event.stopPropagation()}>
                                            {"Delivery "} {record.status !== 2 && record.status !== 3 && record.status !== 6 &&
                                                <Dropdown.Button size='small'
                                                    icon={<Avatar size={16}
                                                        onClick={(event) => event.stopPropagation()}
                                                        src={CAPACETE_PNG}
                                                        style={{ marginRight: 3 }}
                                                        alt="helmet" />}
                                                    style={{ marginLeft: 8, color: 'royalblue' }}
                                                    menu={{
                                                        items,
                                                        onClick: (ek, i) => {
                                                            // ek.stopPropagation()
                                                            switch (ek.key) {
                                                                case 'CE0':
                                                                    navigate('/minha-equipe')
                                                                    break;

                                                                default:
                                                                    const it = items.find(v => v.key === ek.key)
                                                                    if (it) {
                                                                        // console.log(it)
                                                                        var pd = {
                                                                            pedid: record.pedid,
                                                                            appuserid: it.appuserid,
                                                                            entregador: it.label
                                                                        }
                                                                        setEntregador(pd)
                                                                    }
                                                                    break;

                                                            }
                                                        }
                                                    }} onClick={handleButtonClick}>
                                                    <span style={{ fontSize: '0.75em', color: 'royalblue', minWidth: 80 }}>
                                                        {record?.entregador ? record?.entregador : 'Entregador'}
                                                    </span>
                                                </Dropdown.Button>
                                            }
                                        </div>}
                                </div>
                                {
                                    record.type === 'DELIVERY' && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <EnvironmentOutlined style={{ color: 'gray', fontSize: 12, marginRight: 5 }} />
                                        <span style={{ fontSize: '0.7em', color: 'gray' }}>{record?.address?.logradouro?.concat(' ', record?.address?.numero, ', ',
                                            record?.address?.complemento, ' - ', record?.address?.localidade)}</span>
                                    </div>
                                }
                            </div>
                            <div style={{ marginTop: 3 }}>
                                {getStatus(record)}
                            </div>
                        </div>
                    }} />


                <Table.Column responsive={['lg']} title={<span style={{ fontSize: '0.8em' }}>TOTAL</span>} dataIndex="dataserver" key="dataserver"
                    render={(text, record) => {
                        let cor = 'red'
                        let ispago = "Não pago"
                        if (record?.payment?.paid === 1) {
                            ispago = "Pago"
                            cor = "royalblue"
                        } else if (record?.payment?.paid === 2) {
                            ispago = "Pago online"
                            cor = 'blue'
                        }

                        return <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontWeight: 600, fontSize: '0.9em' }}>R$ {record?.valor.toFixed(2)}</span>
                            <span style={{ color: 'gray', fontSize: '0.75em' }}>
                                {record?.payment?.name}
                            </span>
                            <span style={{ fontWeight: 600, fontSize: '0.9em' }}>{
                                <Tag color={cor}>
                                    {ispago}
                                </Tag>}
                            </span>
                        </div>
                    }} />


                <Table.Column responsive={['lg']} title={<span style={{ fontSize: '0.8em' }}>CLIENTE</span>} dataIndex="dataserver" key="dataserver"
                    render={(text, record) => {
                        return <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontWeight: 600, fontSize: '0.8em' }}>{record?.user?.nome}</span>
                            <span style={{ color: 'gray', fontSize: '0.75em' }}>
                                {record?.user?.telefone}
                            </span>
                            <div style={{ fontWeight: 600, fontSize: '0.9em' }}>{
                                (record?.status !== 2 && record?.status !== 3 && record?.status !== 6) &&
                                <DropdownBS size="sm" onClick={(event) => event.stopPropagation()}>
                                    <DropdownBS.Toggle
                                        size="sm"
                                        style={{
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            alignItems: 'center',
                                            display: 'flex',
                                            color: 'green',
                                            backgroundColor: 'white',
                                            borderColor: 'green'
                                        }}
                                        variant="secondary"
                                        id="dropdown-basic">
                                        <img src={require('../../img/whatshap.png')} alt='whats' style={{ height: 14 }} />
                                        <span style={{ marginRight: 8, marginLeft: 5, fontSize: '0.8em' }}> {"Conversar"} </span>
                                    </DropdownBS.Toggle>
                                    <DropdownBS.Menu style={{ fontSize: '0.95em' }}>
                                        {renderDropdownConversar(record)}
                                    </DropdownBS.Menu>
                                </DropdownBS>
                            }
                            </div>
                        </div>
                    }} />
                <Table.Column responsive={['lg']}
                    title=""
                    dataIndex="dataserver"
                    key="dataserver"
                    width={'auto'}
                    render={(text, record) => {
                        return renderButtons(record)
                    }} />
            </Table>



            <Modal
                footer={null}
                open={openAtivar}
                closable={false}
                onCancel={() => setOpenAtivar(false)}>
                <ModalAtivar />
            </Modal>

            <Drawer
                closable={false}
                width={isMobile ? '100%' : 400}
                onClose={() => setSelected(undefined)}
                open={selected}
                title={null}
                footer={null}>
                <Recibo
                    selected={selected}
                    setSelected={setSelected}
                    setOpenAtivar={setOpenAtivar} />
            </Drawer>


            <Drawer
                closable={false}
                width={isMobile ? '100%' : 400}
                onClose={() => setSelectedCozinha(undefined)}
                open={selectedCozinha}
                title={null}
                footer={null}>
                <ReciboCozinha
                    selectedCozinha={selectedCozinha}
                    setSelectedCozinha={setSelectedCozinha}
                    setOpenAtivar={setOpenAtivar} />
            </Drawer>



            <Modal
                footer={null}
                open={selectedCancel}
                closable={false}
                afterClose={() => {
                    setMotivocancelamento('')
                    setOptionCancel(0)
                }}
                onCancel={() => setSelectedCancel(false)}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 300 }}>
                    <Row >
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                            <InfoCircleOutlined style={{ fontSize: 36, color: 'orange' }} />
                        </Col>

                        <Col span={24}
                            style={{
                                color: 'darkgray',
                                fontWeight: 700,
                                fontSize: 'large',
                                textAlign: 'center'
                            }}>Por que você deseja cancelar este pedido?</Col>

                        <Col span={24} style={{ marginTop: 20, justifyContent: 'center', display: 'flex' }}>
                            <Select value={optionCancel} style={{ width: 300 }}
                                onChange={(value) => {
                                    setOptionCancel(value)
                                    switch (value) {
                                        case 1: setMotivocancelamento("Pedido de teste")
                                            break;
                                        case 2: setMotivocancelamento("Pedido com erro")
                                            break;
                                        case 3: setMotivocancelamento("Pedido impossível")
                                            break;
                                        case 4: setMotivocancelamento("Pedido cortesia")
                                            break;
                                        case 5: setMotivocancelamento("Pedido duplicado")
                                            break;
                                        default: setMotivocancelamento("")
                                            break;
                                    }
                                }}>
                                <Select.Option key={1} value={1}>Pedido de teste</Select.Option>
                                <Select.Option key={2} value={2}>Pedido com erro</Select.Option>
                                <Select.Option key={3} value={3}>Pedido impossível</Select.Option>
                                <Select.Option key={4} value={4}>Pedido cortesia</Select.Option>
                                <Select.Option key={5} value={5}>Pedido duplicado</Select.Option>
                                <Select.Option key={6} value={6}>Outro motivo</Select.Option>
                            </Select>
                        </Col>
                        {optionCancel > 5 && <Col span={24} style={{ marginTop: 5, justifyContent: 'center', display: 'flex' }}>
                            <Input.TextArea
                                maxLength={100}
                                style={{ width: 300 }}
                                value={motivocancelamento}
                                placeholder='Descreva aqui o motivo...'
                                onChange={value => setMotivocancelamento(value.target.value)} />
                        </Col>
                        }
                    </Row>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Button type='default' size='large' onClick={() => setSelectedCancel(undefined)}>Voltar</Button>
                        <Button danger size='large' type='primary'
                            loading={loading}
                            disabled={motivocancelamento?.trim() === ''}
                            onClick={() => rejeitarPedido(selectedCancel)}>Cancelar</Button>
                    </div>

                </div>
            </Modal>


            <Drawer
                closable={false}
                width={isMobile ? '100%' : 400}
                onClose={() => setPedido(undefined)}
                open={pedido}
                title={<div className='pedido-header'>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Avatar size={'small'} style={{ backgroundColor: 'royalblue', fontSize: '0.9em', marginRight: 5 }}>
                            M
                        </Avatar>
                        BR-{pedido?.pedid}

                    </div>
                    <CloseOutlined
                        onClick={() => setPedido(undefined)}
                        style={{ fontSize: '1.2em', cursor: 'pointer' }} />
                </div>}
                footer={pedido ? renderButtons(pedido, true) : null}>
                <Pedido pedido={pedido}
                    setPedido={setPedido}
                    getStatus={getStatus}
                    renderDropdownConversar={renderDropdownConversar}
                    setSelectedCancel={setSelectedCancel}
                    aceitarPedido={aceitarPedido}
                    renderStatusOption={renderStatusOption}
                    setPaid={setPaid}
                    setSelectedCozinha={setSelectedCozinha}
                    setSelected={setSelected}
                    loading={loading} />
            </Drawer>

        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
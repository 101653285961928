
import { Space } from 'antd'





export const toFloat = (num) => {
  const cleanStr = String(num).replace(/[^0-9.,]/g, '');
  let dotPos = cleanStr.indexOf('.');
  let commaPos = cleanStr.indexOf(',');

  if (dotPos < 0) dotPos = 0;

  if (commaPos < 0) commaPos = 0;

  const dotSplit = cleanStr.split('.');
  const commaSplit = cleanStr.split(',');

  const isDecimalDot = dotPos
    && (
      (commaPos && dotPos > commaPos)
      || (!commaPos && dotSplit[dotSplit.length - 1].length === 2)
    );

  const isDecimalComma = commaPos
    && (
      (dotPos && dotPos < commaPos)
      || (!dotPos && commaSplit[commaSplit.length - 1].length === 2)
    );

  let integerPart = cleanStr;
  let decimalPart = '0';
  if (isDecimalComma) {
    integerPart = commaSplit[0];
    decimalPart = commaSplit[1];
  }
  if (isDecimalDot) {
    integerPart = dotSplit[0];
    decimalPart = dotSplit[1];
  }


  return parseFloat(
    `${integerPart.replace(/[^0-9]/g, '')}.${decimalPart.replace(/[^0-9]/g, '')}`,
  );
}


export const moneyMask = (value) => {
  try {

    return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  } catch (error) {
    return value
  }
}


// deve ser chamado no evnto onChange
export const valorMonetarioOnChange = (event) => {
  var valor = event.target.value.trim()
  if (valor.length > 0) {
    valor = valor.replace('.', '')
    if (valor.length === 2)
      valor = "0".concat(valor)
    if (valor === 1)
      valor = "00".concat(valor)
    valor = valor.slice(0, valor.length - 2) + "." + valor.slice(valor.length - 2);
  } else {
    if (valor.trim() === "")
      valor = "0.00"
  }
  if (valor === NaN)
    valor = 0;
  //   console.log("onChange", parseFloat(valor).toFixed(2))
  return parseFloat(valor).toFixed(2);

}

export const valorMonetarioOnPaste = (e) => {
  e.preventDefault();
  return false;
}

// chamar no evento onkeyPress
export const valorMonetarioOnKeyPress = (event) => {
  var cVal = event.target.value;
  var dec = cVal.indexOf('.');
  //allow only numbers and decimal point
  if (event.which < 48 || event.which > 57) {
    if (event.which != 46)
      event.preventDefault();
  }
  //read max decimal places 
  var maxDecimalPlaces = 1;
  //no decimal places
  if (maxDecimalPlaces == 0) {
    if (event.which == 46) {
      //not allowing decimal places
      event.preventDefault();
    }
  } else {
    //allowing only one time
    if (event.which == 46 && dec != -1) {
      event.preventDefault(); // prevent if there is already a dot
    }
  }
  //prevent exceeding number of decimal places      
  if (dec != -1) {
    //reading the decimal places  count
    var decPlaces = cVal.substring(dec + 1, cVal.length).length;
    //compare it with props value
    if (decPlaces == 2) {
      event.preventDefault();
    }
  }
}



export const valorNumericoOnChange = (event) => {
  var valor = event.target.value.trim()
  if (valor.length > 0) {
    valor = valor.replace('.', '')
    // if (valor.length === 2)
    //   valor = "0".concat(valor)
    //  if (valor === 1)
    //    valor = "00".concat(valor)
    //  valor = valor.slice(0, valor.length - 2) + "." + valor.slice(valor.length - 2);
  } else {
    if (valor.trim() === "")
      valor = "0"
  }
  if (valor === NaN)
    valor = 0;
  //   console.log("onChange", parseFloat(valor).toFixed(2))
  return parseInt(valor);

}



export const removerAcento = (texto) => {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}




export const renderButtonWhatshap = (number, pedido, transaction_id, userName, titulo) => {
  //   console.log(titulo)
  if (number) {
    if (pedido) {
      return <a href={'https://wa.me/'.concat(number, '?text=Olá, tudo bem? ',
        userName ? "Sou " + userName : " ",
        ", nº do pedido  ".concat(pedido),
        transaction_id > 0 ? "  pagamento nº ".concat(transaction_id) : " "
      )}
        target='_blank'>

        <Space direction='horizontal'>
          <img src={require('../img/whatshap.png')} width={30} alt='fale conosco' title='fale conosco' />
          {titulo ? titulo : ""}
        </Space>
      </a>
    } else {
      return <a href={'https://wa.me/'.concat(number, '?text=Olá, tudo bem? ',
        userName ? "Sou " + userName : " ", " gostaria de informações"
      )}
        target='_blank'>
        <Space direction='horizontal'>
          <img src={require('../img/whatshap.png')} width={30} alt='fale conosco' title='fale conosco' />
          {titulo ? titulo : ""}
        </Space>
      </a>
    }
  } else {
    return <span />
  }
}


export const clearCaracteresFone = (fone) => {

  var x = fone.replace('(', '')
  x = x.replace(')', '')
  x = x.replace(' ', '')
  x = x.replace('-', '')
  x = x.replace('-', '')
  console.log(x)
  return x
   
}
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
//import { CloseCircleOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import './category.css'
import { TextField } from '@material-ui/core';
//import Switch from '@material-ui/core/Switch';
//import 'antd/dist/antd.css';
import { setNotification } from '../../services/dal.service'
import { api } from '../../services/api.service'

import { Input, Row, Col, Tabs, Switch, Button, List } from 'antd';
import Tamanhos from './tamanhos'
import Massas from './massas'
import Bordas from './bordas'

import * as AdminAction from '../../store/actions/admin.action'
import { isBrowser } from "react-device-detect";


const { TabPane } = Tabs;

function callback(key) {
    // console.log(key);
}




export const Category = (props) => {

    const { token, categoria, setCategoria } = props
    const [erro, setErro] = useState(false)
    const [salvando, setSalvando] = useState(false)

    useEffect(() => {
        setCategoria(props.categoria)//  console.log('validação')
        validacao()
    }, [categoria])




    function validacao() {
        var auxerror = false;
        try {
            if (categoria.catDescricao.length < 3) {
                auxerror = true;
            }
            if (categoria.tamanhos) {
                categoria.tamanhos.map((item, index) => {
                    if (item.NomeReduzido.trim() === '')
                        auxerror = true;
                })
            }
            if (categoria.massas) {
                categoria.massas.map((item, index) => {
                    if (item.nome.trim() === '')
                        auxerror = true;
                })
            }
            if (categoria.bordas) {
                categoria.bordas.map((item, index) => {
                    if (item.nome.trim() === '')
                        auxerror = true;
                })
            }

        } catch (er) { }
        setErro(auxerror)
    }




    async function salvar() {
        try {
            setSalvando(true)
            const result = categoria.idcategorias ? await api.put('/api/v2.0/category', categoria, {
                headers: {
                    'x-access-token': token,
                }
            }) : await api.post('/api/v2.0/category', categoria, {
                headers: {
                    'x-access-token': token,
                }
            })
            if (result.status === 200) {
                props.setDrawerCat(false)
                props.getCategorias()
                setNotification('Categoria atualizada com sucesso!', 'success')

            } else {
                setNotification(result.message, 'error')
            }

        } catch (error) {
            setNotification(error.message, 'error')
        }
        setSalvando(false)
    }





    function renderFragment() {
        return <React.Fragment>
            <div>
                <div>
                    <Row>
                        <Col span={24}>
                            Tipo de Categoria
                        </Col>
                        <Col span={24}>
                            <Input value={categoria.tipo === 0 ? 'Padrão' : 'Por tamanho'}
                                disabled fullWidth>
                            </Input>
                        </Col>
                    </Row>
                </div>

                <div className="category-line">
                    <Row>
                        <Col span={24}>
                            Nome da Categoria
                        </Col>
                        <Col span={24}>
                            <Input value={categoria.catDescricao} fullWidth
                                maxLength={50}
                                onChange={(e) => {
                                    setCategoria({ ...categoria, catDescricao: e.target.value })
                                }}>
                            </Input>
                        </Col>
                        <Col span={24} style={{ fontSize: 11, color: 'gray' }}>
                            {`${categoria.catDescricao.length}/${50} caracteres`}
                        </Col>
                    </Row>
                </div>




                <div className={["category-line", "category-line-time"]}>


                    <List bordered size='small' style={{ marginTop: 15 }}>
                        <List.Item
                            actions={[<Switch
                                style={{ marginLeft: '20px', width: 70 }}
                                checkedChildren="Sim"
                                unCheckedChildren="Não"
                                checked={categoria.Ativa === 1}
                                onChange={(e) => {
                                    setCategoria({ ...categoria, Ativa: categoria.Ativa === 0 ? 1 : 0 })
                                }} />]}>
                            <List.Item.Meta
                                title={"Disponibilidade"}
                                description="Informe se a categoria deve ser exibida na tela de produtos"
                            />
                            <div>

                            </div>

                        </List.Item>
                        <List.Item
                            actions={[
                                <Switch
                                    checkedChildren="Sim"
                                    unCheckedChildren="Não"
                                    disabled={categoria.Ativa === 0}
                                    style={{ marginLeft: '20px', width: 70 }}
                                    checked={categoria.limitarHora === 0 ? false : true}
                                    onChange={(e) => {
                                        setCategoria({ ...categoria, limitarHora: categoria.limitarHora === 0 ? 1 : 0 })
                                    }}
                                    name="checkedA"
                                />
                            ]}>
                            <List.Item.Meta
                                title={"Limitar por horário"}
                                description="A categoria deve ser exibida apenas no horário esecificado"
                            />
                            <div>
                                <TextField
                                    disabled={categoria.limitarHora === 0}
                                    style={{ marginLeft: 10 }}
                                    id="time"
                                    label="Inicia"
                                    size={'small'}
                                    type="time"
                                    variant="outlined"
                                    value={categoria.hora1}
                                    className="categoria-time"
                                    defaultValue="10:00:00"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setCategoria({ ...categoria, hora1: e.target.value })
                                    }} /> ,

                                <TextField
                                    disabled={categoria.limitarHora === 0}
                                    style={{ marginLeft: 20 }}
                                    id="time2"
                                    label="Termina"
                                    type="time"
                                    size={'small'}
                                    variant="outlined"
                                    value={categoria.hora2}
                                    className="categoria-time"
                                    defaultValue="10:00:00"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setCategoria({ ...categoria, hora2: e.target.value })
                                    }}
                                />
                            </div>

                        </List.Item>
                    </List>







                </div>

            </div>
        </React.Fragment>
    }




    return <div className="main" >
        <div className={isBrowser ? "category-main" : "category-main-mobile"}>
            {
                <div>
                    <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="Detalhes" key="1">
                            {renderFragment()}
                        </TabPane>
                        {categoria.ispattern !== 1 && <TabPane tab="Tamanhos" key="2">
                            <Tamanhos />
                        </TabPane>}
                        {categoria.ispattern !== 1 && <TabPane tab="Massas" key="3">
                            <Massas />
                        </TabPane>}
                        {categoria.ispattern !== 1 && <TabPane tab="Bordas" key="4">
                            <Bordas />
                        </TabPane>}

                    </Tabs>
                </div>
            }
        </div>
        <div className="categoria-footer">
            <Button danger
                onClick={() => props.setDrawerCat(false)}>
                Cancelar
            </Button>
            <Button style={{ marginLeft: 15, width: 100 }} type="primary"
                disabled={erro}
                loading={salvando}
                onClick={() => salvar()}>
                Salvar
            </Button>
        </div>

    </div>
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    categoria: state.adminReducer.categoria
})

const mapDispatchToProps = dispatch => ({
    setCategoria: (categoria) => dispatch(AdminAction.save_categoria(categoria))
})

export default connect(mapStateToProps, mapDispatchToProps)(Category)
